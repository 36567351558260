import { Card, CardBody, CardHeader, Tag } from '@chakra-ui/react';
import { templateTitles } from '../../../../@xmcloud/core/messages/en';
const { cm: cmtxt, eh: ehtxt } = templateTitles;

export const DualCardItem: React.FC<{
	colorScheme: 'teal' | 'purple';
	children: React.ReactNode;
}> = ({ colorScheme, children }) => (
	<Card variant="outline" minW={['full', 'md']}>
		<CardHeader pb="0">
			<Tag colorScheme={colorScheme}>
				{colorScheme === 'purple' ? cmtxt : ehtxt}
			</Tag>
		</CardHeader>
		<CardBody>{children}</CardBody>
	</Card>
);
