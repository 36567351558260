import { RepositoryNameInputField, SelectRepositories } from '..';
import { useCreateProject } from '../../../../../@xmcloud/hooks';
import { useMemo } from 'react';
import {
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	REPOSITORY,
	REPOSITORY_ID,
	REPO_BRANCH,
	TADOOrganization,
	TADOProjectName,
	TOptions,
	TRepository,
	TRepositoryBranch,
	TRepositoryId,
} from '../../helpers';
import { SelectFormControl } from '../../../../shared-components';
import { Stack } from '@chakra-ui/react';
import { FormikValues } from '../../../../../@xmcloud/hooks/context/CreateProjectContext';
import { secondStep } from '../../../../../@xmcloud/core/messages/en';
import {
	useGetADOOrganizations,
	useGetADOProfile,
	useGetADOProjects,
	useGetADORepositories,
} from '../../../../services/adoApi';

const { chooseOrg: chooseOrgtxt, chooseProject: chooseProjecttxt } = secondStep;

export const AdoRepository: React.FC<{
	isIntegrationIdValidationValid: boolean;
	isSourceControlIntegrationLoading: boolean;
	repositoryNamespace: TRepository;
	repositoryIdNamespace?: TRepositoryId;
	adoProjectNamespace: TADOProjectName;
	adoOrganizationNamespace: TADOOrganization;
	repositoryBranchNamespace?: TRepositoryBranch;
	accessToken: string;
}> = ({
	isIntegrationIdValidationValid,
	isSourceControlIntegrationLoading,
	repositoryNamespace = REPOSITORY,
	repositoryIdNamespace = REPOSITORY_ID,
	adoProjectNamespace = ADO_PROJECT_NAME,
	adoOrganizationNamespace = ADO_ORGANIZATION,
	repositoryBranchNamespace = REPO_BRANCH,
	accessToken,
}) => {
	const { values, errors, touched, setValues, setFieldTouched, isOwnCode } =
		useCreateProject();
	const sourceControlIntegrationId = values.sourceControlIntegrationId || '';

	const { data: profileData, isLoading: isLoadingProfile } = useGetADOProfile(
		accessToken,
		sourceControlIntegrationId,
	);
	const publicAlias = profileData?.data.publicAlias || '';

	const { data: organizationsData, isLoading: isAdoOrganizationLoading } =
		useGetADOOrganizations({
			token: accessToken,
			publicAlias,
			_enabled: isIntegrationIdValidationValid && !!accessToken,
		});

	const { data: projectsData, isLoading: isLoadingProjects } =
		useGetADOProjects({
			token: accessToken,
			organization: values[adoOrganizationNamespace],
			_enabled: isIntegrationIdValidationValid && !!accessToken,
		});

	const { data: repositoriesData, isLoading: isLoadingRepositories } =
		useGetADORepositories({
			token: accessToken,
			organization: values[adoOrganizationNamespace],
			project: values[adoProjectNamespace],
			_enabled: isIntegrationIdValidationValid && !!accessToken,
		});

	const adoOrganizations = useMemo(
		() =>
			organizationsData?.data.value.map((r: any) => ({
				label: r.accountName,
				value: r.accountName,
			})) || [],
		[organizationsData?.data.value],
	);

	const projects = useMemo(
		() =>
			projectsData?.data.value.map((r: any) => ({
				label: r.name,
				value: r.name,
			})) || [],
		[projectsData?.data.value],
	);

	const repositoryOptions: TOptions = useMemo(
		() =>
			repositoriesData?.data.value.map((r: any) => ({
				label: r.name,
				value: r.name,
				id: r.id,
			})) || [],
		[repositoriesData?.data.value],
	);

	return (
		<Stack gap="7">
			<SelectFormControl
				{...{
					isInvalid: Boolean(
						errors[adoOrganizationNamespace] &&
							touched[adoOrganizationNamespace],
					),
					isLoading: isAdoOrganizationLoading || isLoadingProfile,
					options: adoOrganizations,
					onChange: (e: any) => {
						setValues((prev: FormikValues) => ({
							...prev,
							[adoOrganizationNamespace]: e.value || '',
							[adoProjectNamespace]: '',
							[repositoryNamespace]: '',
							[repositoryIdNamespace]: '',
							[repositoryBranchNamespace]: '',
						}));
					},
					label: chooseOrgtxt,
					name: adoOrganizationNamespace,
					currentValue: values[adoOrganizationNamespace],
					maxW: 'sm',
					onFocus: () =>
						setFieldTouched(adoOrganizationNamespace, true),
					error: errors[adoOrganizationNamespace],
					isRequired: true,
					pt: 0,
				}}
			/>
			<SelectFormControl
				{...{
					isInvalid: Boolean(
						errors[adoProjectNamespace] &&
							touched[adoProjectNamespace],
					),
					isLoading: isLoadingProjects,
					options: projects,
					onChange: (e: any) => {
						setValues((prev: FormikValues) => ({
							...prev,
							[adoProjectNamespace]: e.value || '',
							[repositoryNamespace]: '',
							[repositoryIdNamespace]: '',
							[repositoryBranchNamespace]: '',
						}));
					},
					label: chooseProjecttxt,
					name: adoProjectNamespace,
					currentValue: values[adoProjectNamespace],
					maxW: 'sm',
					onFocus: () => setFieldTouched(adoProjectNamespace, true),
					error: errors[adoProjectNamespace],
					isRequired: true,
					pt: 0,
				}}
			/>
			{isOwnCode ? (
				<SelectRepositories
					{...{
						repositoryOptions,
						isLoading: isLoadingRepositories,
						accessToken,
						adoOrganizationNamespace,
						adoProjectNamespace,
						repositoryNamespace,
						repositoryBranchNamespace,
					}}
				/>
			) : (
				<RepositoryNameInputField {...{ repositoryNamespace }} />
			)}
		</Stack>
	);
};
