import { Stack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { environment } from '../../../../@xmcloud/core/messages/en';
import { TextBox } from '../../../shared-components';

const { contextIdLive, contextIdPreview } = environment;

export const ContextID: React.FC<{
	previewContextId: string;
	liveContextId: string;
}> = ({ previewContextId, liveContextId }) => {
	const items = useMemo(
		() => [
			{ title: contextIdPreview, subTitle: previewContextId },
			{ title: contextIdLive, subTitle: liveContextId },
		],
		[liveContextId, previewContextId],
	);
	if (!previewContextId || !liveContextId) return <></>;

	return (
		<Stack gap={[4, 10]} direction={['column', 'row']}>
			{items.map(({ title, subTitle }) => (
				<TextBox {...{ title, subTitle }} key={title} />
			))}
		</Stack>
	);
};
