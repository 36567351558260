import { Stack } from '@chakra-ui/react';
import {
	firstStep,
	projects,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { HeadingLg, InputFormControl } from '../../../shared-components';
import {
	useCreateProject,
	useExperimentalFeatures,
} from '../../../../@xmcloud/hooks';
import { useGetValidateProjectName } from '../../../services/projects';
import { ExtractGoTo, goToCreateProjectPaths, PROJECT_NAME } from '../helpers';
import { FooterBtnGroups } from '.';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import { goTo } from '../../../../@xmcloud/core/routes/paths';

const { title: titletxt } = firstStep;
const { nameTitle } = projects;
const { choosePath } = goTo;
const { errMsg2 } = systemHealthStatus;

export const SingleProjectName: React.FC<{
	isContinueBtnDisabled: boolean;
	isContinueBtnLoading: boolean;
}> = ({ isContinueBtnDisabled, isContinueBtnLoading }) => {
	const {
		values,
		errors,
		handleChange,
		next,
		setFieldError,
		setFieldTouched,
		touched,
	} = useCreateProject();

	const { refetch, isFetching } = useGetValidateProjectName(values.name);

	async function handleNext() {
		if (!values.name || errors.name) {
			setFieldTouched(PROJECT_NAME, true);
			return;
		}

		const { data } = await refetch();
		const isValid = data?.data.isValid;
		const message = data?.data.message as string;
		if (!isValid) {
			setFieldError(PROJECT_NAME, message);
			return;
		}
		next(goToCreateProjectPaths[values.journey] as ExtractGoTo);
	}
	function handlePrev() {
		handlePush(choosePath);
	}
	const { createEhAndCmProjectExperimentalFeature } =
		useExperimentalFeatures();

	const showPreviousBtn = createEhAndCmProjectExperimentalFeature;

	const isLoading = isFetching || isContinueBtnLoading;

	return (
		<Stack gap={7}>
			<HeadingLg text={titletxt} />
			<InputFormControl
				{...{
					isInvalid: Boolean(errors.name && touched.name),
					label: nameTitle,
					name: PROJECT_NAME,
					value: values.name,
					onChange: handleChange,
					error: errors.name,
					onFocus: () => setFieldTouched(PROJECT_NAME, true),
					maxW: 'sm',
					isRequired: true,
				}}
			/>
			<FooterBtnGroups
				{...{
					onNextClick: handleNext,
					onPreviousClick: handlePrev,
					isLoading,
					isDisabled: isContinueBtnDisabled,
					tooltiptxt: isLoading ? '' : errMsg2,
					py: 0,
					showPreviousBtn,
				}}
			/>
		</Stack>
	);
};
