import {
	CONTENT_TRANSFER,
	useSharedState,
} from '../../../@xmcloud/hooks/context/useSharedState';
import { Box, Switch } from '@chakra-ui/react';
import { useExperimentalFeatures } from '../../../@xmcloud/hooks';
import { useFeature } from '../../feature-flag/features';
import { CardBox, Text } from '../../shared-components';

export const ExperimentalFeatures: React.FC = () => {
	const CreateProjectV2 = useFeature('CreateProjectV2');
	const contentTransfer = useFeature('ContentTransfer');

	const {
		createEhAndCmProjectExperimentalFeature,
		setCreateEhAndCmProjectExperimentalFeature,
		contentTransferExpFeat,
		setContentTransferExpFeature,
	} = useExperimentalFeatures();
	const { setState: setContentTransferEnabled } = useSharedState(
		CONTENT_TRANSFER,
		contentTransferExpFeat,
	);
	const noExperimentalFeatures = !CreateProjectV2 && !contentTransfer;

	if (noExperimentalFeatures)
		return (
			<Box
				width="full"
				alignItems="center"
				display="flex"
				justifyContent="center"
				pt="5%"
			>
				<Text
					fontWeight="semibold"
					text="No experimental features found"
				/>
			</Box>
		);

	return (
		<>
			{CreateProjectV2 && (
				<CardBox
					title="Create CM and EH projects"
					description="By creating separate projects for the CMS and frontend, we can develop, build, and deploy easier, faster, and more efficiently. A frontend project must be associated with a CMS project"
					mt={5}
				>
					<Switch
						isChecked={createEhAndCmProjectExperimentalFeature}
						onChange={(e) => {
							setCreateEhAndCmProjectExperimentalFeature(
								e.target.checked,
							);
						}}
					/>
				</CardBox>
			)}
			{contentTransfer && (
				<CardBox
					title="Content Transfer"
					description="Transfer content between instances"
					mt={5}
				>
					<Switch
						isChecked={contentTransferExpFeat}
						onChange={(e) => {
							setContentTransferExpFeature(e.target.checked);
							setContentTransferEnabled(e.target.checked);
						}}
					/>
				</CardBox>
			)}
		</>
	);
};
