import { memo } from 'react';
import PN from './ProjectName';
import SR from './SetupRepository';
import AE from './AuthoringEnvironment';
import RF from './ReviewFinishMain';
import SC from './SourceControls';

export const ProjectName = memo(PN);
export const SetupRepository = memo(SR);
export const AuthoringEnvironment = memo(AE);
export const ReviewFinishMain = memo(RF);
export const SourceControls = memo(SC);
