import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useComponentHealthy,
	useEnvironmentDetails,
	useProjectDetails,
	useSharedState,
} from '../../../../@xmcloud/hooks/';
import {
	AlertStatus,
	DownloadButton,
	LoadingSkeleton,
	RowStack,
	XMCSBreadCrumb,
	XMCSButton,
	XMCSH3,
} from '../../../shared-components';
import LogAlert from './LogAlert';
import { deploymentLogsItems } from '../../../shared-components/breadcrumb/items';
import LogsAccordion from './LogsAccordion';
import { useGetDeployment } from '../../../services/deployments';
import { useGetDeploymentLogs } from '../../../services/monitoring';
import {
	downloadLogs,
	handleHTMLTagLink,
} from '../../../../@xmcloud/utils/helpers';
import { Link, Tooltip, Text, Stack } from '@chakra-ui/react';
import { t } from '@transifex/native';
import {
	deploymentLogs,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import DeployBuildOtherEnv from '../components/DeployBuildOtherEnv';
import { IS_SM_SCREEN } from '../../../../@xmcloud/hooks/context/useSharedState';
import { ECalculatedStatus, TCalculatedStatus } from './handlers';

const { NOT_STARTED, IN_PROGRESS } = ECalculatedStatus;

const { warn1, warn2 } = systemHealthStatus;
const {
	deploymentLog: deploymentLogtxt,
	promote: promotetxt,
	noLogsFound: noLogsFoundtxt,
} = deploymentLogs;

export const DeploymentLogs: FC = () => {
	const { environmentId, projectId, deploymentId } = useParams<{
		environmentId: string;
		projectId: string;
		deploymentId: string;
	}>();
	const [showPromoteModal, setShowPromoteModal] = useState(false);
	const [disableDownloadBtn, setDisableDownloadBtn] = useState(false);

	const { environmentName, environment } = useEnvironmentDetails(
		environmentId!,
	);

	const { projectName } = useProjectDetails(projectId!);
	const { data, isLoading } = useGetDeployment({ id: deploymentId! });
	const deployment = data?.data;
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);

	const {
		isWarningsHealthy,
		getUnhealthyWarningList,
		isLoading: isComponentHealthLoading,
	} = useComponentHealthy({});

	const unhealthyWarningList = isWarningsHealthy
		? []
		: getUnhealthyWarningList();
	const hasPluralWarnings =
		!isWarningsHealthy && unhealthyWarningList.length > 1;

	if (!deployment || isLoading)
		return (
			<>
				{nuOfSkeleton.map((s, i) => (
					<LoadingSkeleton key={i} {...s} />
				))}
			</>
		);

	const createdAt = deployment.createdAt;
	const title = `${deploymentLogtxt} ${getReadableDate(createdAt)}`;
	const shouldShowPromoteBtn = deployment.calculatedStatus === 2;
	const isHTMLTag =
		deployment.triggerMessage && deployment.triggerMessage.startsWith('<a');
	const aTag =
		deployment.triggerMessage &&
		handleHTMLTagLink(deployment.triggerMessage);
	const calculatedStatus = deployment.calculatedStatus as TCalculatedStatus;
	const showAlertStatus =
		calculatedStatus === NOT_STARTED || calculatedStatus === IN_PROGRESS;

	const showErrorAlert =
		!isWarningsHealthy && !isComponentHealthLoading && showAlertStatus;

	return (
		<>
			<RowStack my={0} mt="-1.5">
				<XMCSBreadCrumb
					items={deploymentLogsItems(
						projectName,
						projectId!,
						environmentId!,
						environmentName,
					)}
				/>
				{!isSmScreen && (
					<DownloadLogButton
						{...{
							disableDownloadBtn,
							deploymentId: deploymentId!,
						}}
					/>
				)}
			</RowStack>
			<RowStack mt="4" mb="4">
				<XMCSH3 title={title} />
				{shouldShowPromoteBtn && !isSmScreen && (
					<XMCSButton
						text={promotetxt}
						onClick={() => setShowPromoteModal(true)}
						variant="ghost"
						colorScheme="primary"
					/>
				)}
			</RowStack>
			<Text variant="subtle">
				{isHTMLTag ? (
					<Link
						isExternal
						href={(aTag && aTag.link) ?? ''}
						boxShadow="none !important"
					>
						{aTag && aTag.text}
					</Link>
				) : (
					deployment.triggerMessage
				)}
			</Text>
			{Boolean(isSmScreen) && (
				<Stack direction="row" justify="flex-end" gap={2} mt={4}>
					{shouldShowPromoteBtn && (
						<XMCSButton
							text={promotetxt}
							onClick={() => setShowPromoteModal(true)}
							variant="ghost"
							colorScheme="primary"
						/>
					)}
					<DownloadLogButton
						{...{
							disableDownloadBtn,
							deploymentId: deploymentId!,
						}}
					/>
				</Stack>
			)}
			<LogAlert
				deployment={deployment}
				host={environment?.host}
				isSmScreen={Boolean(isSmScreen)}
			/>
			<LogsAccordion {...{ deployment, setDisableDownloadBtn }} />
			{!!showErrorAlert && (
				<AlertStatus
					description={hasPluralWarnings ? warn2 : warn1}
					status="warning"
					data-testid="warning-error-alert"
					variableTxt={unhealthyWarningList.join(', ')}
				/>
			)}
			{showPromoteModal && (
				<DeployBuildOtherEnv
					openModal={showPromoteModal}
					setOpenModal={setShowPromoteModal}
					deploymentId={deploymentId!}
					environmentId={environmentId!}
					projectId={projectId!}
				/>
			)}
		</>
	);
};

interface DownloadBtnProps {
	disableDownloadBtn: boolean;
	deploymentId: string;
}
const DownloadLogButton: FC<DownloadBtnProps> = ({
	disableDownloadBtn,
	deploymentId,
}) => {
	const { refetch, isLoading: deploymentLogsIsLoading } =
		useGetDeploymentLogs(deploymentId);
	return (
		<Tooltip
			label={t(`${noLogsFoundtxt}${deploymentId}`)}
			isDisabled={!disableDownloadBtn}
			shouldWrapChildren
		>
			<DownloadButton
				onClick={async () => {
					const { data } = await refetch();
					if (data?.data) {
						const text = data.data as string;
						downloadLogs(text, `${deploymentId}.txt`);
					}
				}}
				isLoading={deploymentLogsIsLoading}
				disabled={disableDownloadBtn}
			/>
		</Tooltip>
	);
};

const nuOfSkeleton = [
	{ height: 8, mt: 2, w: 52, amount: 1 },
	{ height: 8, mt: 3, w: 72, amount: 1 },
	{ height: 8, mt: 5, w: 96, amount: 1 },
	{ height: 14, mt: 6, amount: 4 },
];
