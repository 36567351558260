import { useParams } from 'react-router-dom';
import { environments as envTexts } from '../../../../@xmcloud/core/messages/en';
import { IGetEnvironmentResponse } from '../../../models/environmentModel';
import { useGetProjectEnvironmentsList } from '../../../services/projects';
import { useEnvironmentsTableColumns } from '../columns/environments';
import { ColumnDef } from '@tanstack/react-table';
import {
	LoadingSkeleton,
	RenderPlaceholder,
	Table,
} from '../../../shared-components';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import { useFeature } from '../../../feature-flag/features';

const headers = ['name', 'ID', 'Date created', ''];

const ProjectEnvironmentsTable: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const createProjectV2 = useFeature('CreateProjectV2');

	const { data: environmentsData, isLoading: isEnvironmentsLoading } =
		useGetProjectEnvironmentsList({
			projectId: projectId!,
		});

	const environments = environmentsData?.data || [];

	const columns: ColumnDef<IGetEnvironmentResponse, any>[] =
		useEnvironmentsTableColumns();

	const isLoading = isEnvironmentsLoading;

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	if (environments.length === 0)
		return (
			<RenderPlaceholder
				item={envTexts.environments.toLocaleLowerCase()}
			/>
		);

	return (
		<Table
			{...{
				columns: columns,
				tableData: environments,
				dataTestId: 'environments-table',
				showPagination: environments.length > pageSize,
				headers: createProjectV2
					? headers
					: [
							'name',
							'ID',
							'Context ID(Preview)',
							'Context ID(Live)',
							'Date created',
							'',
						],
			}}
		/>
	);
};

export default ProjectEnvironmentsTable;
