import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Stack,
	Radio,
	RadioGroup,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { Microcopy } from '../../pages/create-project/components';

interface Props {
	error?: string | undefined;
	maxW?: string;
	pt?: number;
	label: string;
	isInvalid?: boolean;
	isRequired?: boolean;
	defaultValue?: string;
	name: string;
	radioBtns?: {
		label: string;
		value: string;
	}[];
	description?: string;
	onChange: (value: any) => void;
	isDisabled?: boolean;
	value?: string;
	pb?: number;
}

export const RadioButtonGroup: React.FC<Props> = ({
	error,
	isInvalid,
	isRequired = false,
	label,
	defaultValue = 'false',
	pt = 0,
	pb = 0,
	maxW = 'md',
	name,
	radioBtns = [
		{ label: 'Yes', value: 'true' },
		{ label: 'No', value: 'false' },
	],
	description = '',
	onChange,
	isDisabled = false,
	value,
}) => (
	<FormControl {...{ isInvalid, isRequired, pt, pb, maxW }}>
		<FormLabel>
			<T _str={label} />
		</FormLabel>
		{Boolean(description) && <Microcopy text={description} maxW="md" />}
		<RadioGroup {...{ name, defaultValue, onChange, isDisabled, value }}>
			<Stack direction="row" spacing={5} pt={2}>
				{radioBtns.map((radioBtn) => (
					<Radio key={radioBtn.label} value={radioBtn.value}>
						<T _str={radioBtn.label} />
					</Radio>
				))}
			</Stack>
		</RadioGroup>
		<FormErrorMessage>{error}</FormErrorMessage>
	</FormControl>
);
