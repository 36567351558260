import { FC } from 'react';
import { Text, useToast } from '@chakra-ui/react';
import { app, credentials } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { AlertDialogConfirmationModal } from '../../../shared-components';
import { useDeleteClientMutation } from '../../../services/clients';
import {
	AbstractGetClientResponse,
	AuthenticationClientType,
} from '../../../models/clientModel';
import config from '../../../config/config';

interface Props {
	client: AbstractGetClientResponse;
	setOpenModal: (show: boolean) => void;
	openModal: boolean;
}

const RevokeClientConfirmation: FC<Props> = ({
	openModal,
	setOpenModal,
	client,
}) => {
	const toast = useToast();
	const { mutate: revokeClient, isLoading } = useDeleteClientMutation(
		client.clientType ===
			AuthenticationClientType.OrganizationAutomationClient
			? config.clients.get_organization_list.queryKey
			: config.clients.get_environment_list.queryKey,
	);

	function handleSubmit() {
		revokeClient({
			id: client.id,
			onSuccess: () => {
				toast({
					status: 'success',
					description: t(credentials.revokeSuccess),
				});

				setOpenModal(false);
			},
		});
	}

	return (
		<AlertDialogConfirmationModal
			isOpen={openModal}
			title={credentials.revoke}
			onClose={() => setOpenModal(false)}
			dataTestId="client-revoke-confirmation-modal"
			isLoading={isLoading}
			onConfirm={handleSubmit}
			leftButtonText={app.revoke}
		>
			<Text>{t(credentials.revokeConfirmation)}</Text>
		</AlertDialogConfirmationModal>
	);
};

export default RevokeClientConfirmation;
