import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	Spacer,
	AlertStatus,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { app, deploymentLogs } from '../../../../@xmcloud/core/messages/en';
import { IGetDeploymentResponse } from '../../../models/deploymentModel';
import {
	alertStatusTitle,
	calculatedAlertStatus,
	handleElapsedTime,
	TCalculatedStatus,
} from './handlers';
import { t } from '@transifex/native';
import CancelDeployment from './CancelDeployment';
import DnsResolution from './DnsResolution';

type Props = {
	deployment: IGetDeploymentResponse;
	host: string;
	isSmScreen: boolean;
};

const LogAlert: FC<Props> = ({ deployment, host, isSmScreen }) => {
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const calculatedStatus: TCalculatedStatus =
		deployment?.calculatedStatus ?? 4;
	const status = calculatedAlertStatus[calculatedStatus] as AlertStatus;
	const alertTitle = alertStatusTitle[calculatedStatus];
	const showCancelButton = calculatedStatus === 0 || calculatedStatus === 1;
	const duration = handleElapsedTime(
		deployment?.deploymentCompletedAt,
		deployment?.startedAt,
	);
	return (
		<>
			<Alert
				status={status}
				my="4"
				minH="14"
				display="flex"
				flexWrap="wrap"
				alignItems="center"
			>
				<AlertIcon />
				<AlertTitle>{t(alertTitle)}</AlertTitle>
				{calculatedStatus === 2 && !isSmScreen && (
					<AlertDescription>{duration}</AlertDescription>
				)}
				{calculatedStatus === 1 && !isSmScreen && (
					<AlertDescription>
						{deploymentLogs.detailsBelow}
					</AlertDescription>
				)}
				<Spacer />
				{showCancelButton && (
					<Button
						size="sm"
						variant="outline"
						onClick={() => setOpenConfirmationModal(true)}
					>
						{app.cancel}
					</Button>
				)}
				{calculatedStatus === 2 && (
					<DnsResolution
						host={host}
						projectId={deployment?.projectId}
					/>
				)}
			</Alert>
			{openConfirmationModal && (
				<CancelDeployment
					openModal={openConfirmationModal}
					setOpenModal={setOpenConfirmationModal}
					environmentId={deployment?.environmentId}
					deploymentId={deployment?.id}
				/>
			)}
		</>
	);
};

export default LogAlert;
