import { Navigate, useParams } from 'react-router-dom';
import { ReactNode } from 'react';

import { useProjectDetails } from '../../hooks';
import { ProjectTypesEnum } from '../../../app/pages/create-project/helpers';

/**
 * This component is used to protect the sites route for non EH projects only and redirect to 404 for EH project type
 * @param children
 * @return {JSX.Element}
 */
function ProtectedSitesRoute({ children }: { children: ReactNode }) {
	const { projectId } = useParams<{
		projectId: string;
	}>();

	const { projectType } = useProjectDetails(projectId!);

	// Redirect to 404 if projectType is undefined or EH
	if (!projectType || projectType === ProjectTypesEnum.EH) {
		return <Navigate to="/404" />;
	}

	return <>{children}</>;
}

export { ProtectedSitesRoute };
