import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Stack,
	ModalHeader,
} from '@chakra-ui/react';
import {
	app,
	commonCw,
	templateTitles,
	createProject,
} from '../../../../@xmcloud/core/messages/en';
import { HeadingMd, XMCSButton, Text } from '../../../shared-components';
import { useLocation } from 'react-router-dom';
import { createProjectPaths } from '../../../../@xmcloud/core/routes/paths';
import styles from '../../../../styles/scrollbar.module.css';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { CwJourneyType } from '../helpers';

const { modalSubTitle2, modalSubTitle3 } = commonCw;
const {
	combined: combinedPath,
	eh: ehPath,
	cm: cmPath,
	dual: dualPath,
} = createProjectPaths;
const { mSubTitle, mTitle } = createProject;
const { cancel: canceltxt } = app;

interface Props {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

const { cm, combined, eh, dual } = CwJourneyType;

const modalTitle = {
	[combined]: mTitle,
	[eh]: templateTitles[eh],
	[cm]: templateTitles[cm],
	[dual]: templateTitles[dual],
};

const modalSubTitle = {
	[combined]: mSubTitle,
	[eh]: modalSubTitle2,
	[cm]: modalSubTitle3,
	[dual]: templateTitles[combined],
};

export const MainModal: React.FC<Props> = ({ isOpen, onClose, children }) => {
	const { pathname } = useLocation();
	const {
		values: { journey },
	} = useCreateProject();
	const isCombinePath = pathname.includes(combinedPath);
	const isFrontendPath = pathname.includes(ehPath);
	const isStandalonePath = pathname.includes(cmPath);
	const isDualPath = pathname.includes(dualPath);
	const showTitle =
		isCombinePath || isFrontendPath || isStandalonePath || isDualPath;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="full"
			scrollBehavior="inside"
		>
			<ModalOverlay>
				<ModalContent>
					<ModalHeader display="flex" flexDirection="row">
						<Stack w="full">
							{Boolean(showTitle) && (
								<HeadingMd text={modalTitle[journey]} />
							)}
							{Boolean(showTitle) && (
								<Text
									variant="small"
									fontWeight="normal"
									text={modalSubTitle[journey]}
								/>
							)}
						</Stack>
						<ModalCloseButton
							as={() => (
								<XMCSButton
									text={canceltxt}
									variant="outline"
									onClick={onClose}
								/>
							)}
						/>
					</ModalHeader>
					<ModalBody className={styles['custom-scrollbar']}>
						{children}
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	);
};
