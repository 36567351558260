import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { DeploymentFilter } from '../../@xmcloud/types';
import { useAuthMutation, useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import {
	DeployProcessStatus,
	IDeploymentsStatusCountResponse,
	IGetDeploymentResponse,
	IPaginatedDeploymentsResponse,
} from '../models/deploymentModel';

export const useGetDeployment = ({ id }: { id: string }) => {
	const { scope, url, queryKey } = config.deployments.deployment_v3;

	return useAuthQuery(
		queryKey(id),
		(axiosInstance) => {
			return axiosInstance?.get<IGetDeploymentResponse>(url(id));
		},
		{ refetchOnWindowFocus: true, enabled: !!id },
		scope,
	);
};

export const useDeleteDeploymentMutation = ({ id }: { id: string }) => {
	const queryClient = useQueryClient();
	const { scope, url, queryKey } = config.deployments.deployment_v3;

	const environmentDeploymentQuery =
		config.environments.environment_deployments_v2.queryKey(id);

	return useAuthMutation(
		(axiosInstance) => () => {
			return axiosInstance?.delete(url(id));
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(environmentDeploymentQuery);
				queryClient.invalidateQueries(queryKey(id));
			},
		},
		scope,
	);
};
// useUpdateDeploymentsArtifactsMutation is not used anywhere!!!
export const useUpdateDeploymentsArtifactsMutation = ({
	deploymentId,
}: {
	deploymentId: string;
}) => {
	const queryClient = useQueryClient();
	return useAuthMutation(
		(axiosInstance) => (/** pass artifacts */) => {
			return axiosInstance?.put(
				config.deployments.deployment_artifacts.url(deploymentId),
				/** artifacts obj */
			);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('deployment/artifacts');
			},
		},
		config.deployments.deployment_artifacts.scope,
	);
};

export const useDeploymentDeployMutation = (environmentId = '') => {
	const queryClient = useQueryClient();
	const environmentDeploymentQuery =
		config.environments.environment_deployments_v2.queryKey(environmentId);

	const deploymentsDeploymentQuery = config.deployments.deployment_v3;

	return useAuthMutation(
		(axiosInstance) =>
			({ deploymentId }: { deploymentId: string }) => {
				return axiosInstance?.post(
					config.deployments.deployment_deploy.url(deploymentId),
				);
			},
		{
			onSuccess: (_, values) => {
				queryClient.invalidateQueries(
					deploymentsDeploymentQuery.queryKey(values.deploymentId),
				);
				setTimeout(() => {
					queryClient.invalidateQueries(environmentDeploymentQuery);
				}, 2000);
			},
		},
		config.deployments.deployment_deploy.scope,
	);
};

export const useCancelDeploymentMutation = () => {
	const queryClient = useQueryClient();

	return useAuthMutation(
		(axiosInstance) =>
			({
				deploymentId,
			}: {
				deploymentId: string;
				environmentId?: string;
			}) => {
				return axiosInstance?.post(
					config.deployments.cancel_deployment.url(deploymentId),
				);
			},
		{
			onSuccess: (data, variables) => {
				const environmentDeploymentQuery =
					config.environments.environment_deployments_v2.queryKey(
						variables.environmentId,
					);

				queryClient.invalidateQueries(
					config.deployments.deployment_v3.queryKey(
						variables.deploymentId,
					),
				);
				queryClient.invalidateQueries(environmentDeploymentQuery);
				const res = queryClient.getQueryData(
					environmentDeploymentQuery,
				) as AxiosResponse<IGetDeploymentResponse[]>;
				const deployments = res?.data;

				const queuedDeployment = deployments?.find(
					(d) =>
						d.calculatedStatus === DeployProcessStatus.NotStarted,
				);
				if (queuedDeployment) {
					setTimeout(() => {
						queryClient.invalidateQueries(
							environmentDeploymentQuery,
						);
					}, 2500);
				}
			},
		},
		config.deployments.deployment_deploy.scope,
	);
};

export const useGetAllDeployments = (
	pageIndex = 1,
	pageSize = 10,
	filter: DeploymentFilter,
) => {
	const { scope, url, queryKey } = config.deployments.allDeployments_v3;

	return useAuthQuery(
		queryKey(pageIndex, filter),
		(axiosInstance) => {
			return axiosInstance?.get<IPaginatedDeploymentsResponse>(
				url(pageIndex, pageSize, filter),
			);
		},
		{ keepPreviousData: true, staleTime: 5000 },
		scope,
	);
};

export const useGetDeploymentStatusCount = () => {
	const { scope, url, queryKey } = config.deployments.deploymentsByStatus;
	return useAuthQuery(
		queryKey,
		(axiosInstance) => {
			return axiosInstance?.get<IDeploymentsStatusCountResponse>(url);
		},
		{},
		scope,
	);
};
