import { t } from '@transifex/native';
import * as yup from 'yup';
import { editCreateEnvironment, fourthStep } from '../core/messages/en';
import { ENVIRONMENT_NAME_VALIDATION_PATTERN } from '../utils/constants';
import { TenantType } from '../types';

const {
	nameValidation1,
	nameValidation3,
	nameValidation4,
	needToContinue,
	tenantTypeValidation1,
	tenantTypeValidation2,
	tenantTypeValidation3,
	tenantTypeValidation4,
} = editCreateEnvironment;
const { requriedCmEnv } = fourthStep;

export const editCreateEnvironmentSchema = (
	environmentPerProjectLimitation: any,
	allEnvironmentsLimitation: any,
	isEdit: boolean,
	isEHTypeProject: boolean,
	isNamePatternValidation: boolean,
) =>
	yup.object({
		name: isNamePatternValidation
			? yup
					.string()
					.required(t(nameValidation1))
					.max(100, t(nameValidation3))
					.matches(
						new RegExp(ENVIRONMENT_NAME_VALIDATION_PATTERN),
						nameValidation4,
					)
			: yup
					.string()
					.required(t(nameValidation1))
					.max(100, t(nameValidation3)),
		cmEnvironmentId:
			isEHTypeProject && !isEdit
				? yup.string().required(requriedCmEnv)
				: yup.string().notRequired(),
		deployOnCommit: yup.string().required(t(needToContinue)),
		tenantType: isEdit
			? yup.string().notRequired()
			: yup
					.string()
					.required(t(needToContinue))
					.test(
						'environment-limit-validation',
						'Environment tier validation failed',
						function (value) {
							const { path, createError } = this;

							if (
								value === TenantType.Production.toString() &&
								environmentPerProjectLimitation?.data
									?.isProdLimitExceeds
							) {
								return createError({
									path,
									message: t(tenantTypeValidation1),
								});
							} else if (
								value === TenantType.NonProduction.toString() &&
								environmentPerProjectLimitation?.data
									?.isNonProdLimitExceeds
							) {
								return createError({
									path,
									message: t(tenantTypeValidation2),
								});
							}
							return true;
						},
					)
					.test(
						'all-environment-limit-validation',
						'All environment tier validation failed',
						function (value) {
							const { path, createError } = this;

							if (
								value === TenantType.Production.toString() &&
								allEnvironmentsLimitation?.data
									.isProdLimitExceeds
							) {
								return createError({
									path,
									message: t(tenantTypeValidation3),
								});
							} else if (
								value === TenantType.NonProduction.toString() &&
								allEnvironmentsLimitation?.data
									.isNonProdLimitExceeds
							) {
								return createError({
									path,
									message: t(tenantTypeValidation4),
								});
							}
							return true;
						},
					),
	});
