import { ReviewFinish } from '..';
import { projects } from '../../../../../@xmcloud/core/messages/en';
import {
	useCreateProject,
	useDualADOProjectCreation,
} from '../../../../../@xmcloud/hooks';
import { IRepositoryTemplateDetails } from '../../../../models/repositoriesModel';

const { contentService, webApp } = projects;

export const ReviewFinishDualADO: React.FC<{
	XMCloudTemplate: IRepositoryTemplateDetails;
	token: string;
	isLoading: boolean;
}> = ({ XMCloudTemplate, token, isLoading: initialLoading }) => {
	const { isMonoRepo, isOwnCode } = useCreateProject();
	const {
		isLoading,
		handleDualProjectCreation,
		cmRepositoryCreationStatus,
		cmProjectCreationStatus,
		cmEnvironmentCreationStatus,
		cmDeploymentCreationStatus,
		ehRepositoryCreationStatus,
		ehProjectCreationStatus,
		ehEnvironmentCreationStatus,
		ehDeploymentCreationStatus,
		shouldRetry,
	} = useDualADOProjectCreation({
		XMCloudTemplate,
		token,
	});

	const listOfStatus = [
		...(isOwnCode
			? []
			: [
					{
						status: cmRepositoryCreationStatus,
						text: `${contentService} repository`,
					},
				]),

		{ status: cmProjectCreationStatus, text: `${contentService} project` },
		{
			status: cmEnvironmentCreationStatus,
			text: `${contentService} environment`,
		},
		{
			status: cmDeploymentCreationStatus,
			text: `${contentService} deployment`,
		},
		...(isMonoRepo || isOwnCode
			? []
			: [
					{
						status: ehRepositoryCreationStatus,
						text: `${webApp} repository`,
					},
				]),

		{ status: ehProjectCreationStatus, text: `${webApp} project` },
		{
			status: ehEnvironmentCreationStatus,
			text: `${webApp} environment`,
		},
		{
			status: ehDeploymentCreationStatus,
			text: `${webApp} deployment`,
		},
	];

	return (
		<ReviewFinish
			{...{
				isLoading: initialLoading || isLoading,
				shouldRetry,
				handleStartDeployment: handleDualProjectCreation,
				listOfStatus,
			}}
		/>
	);
};
