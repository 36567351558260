import { Stack, Text } from '@chakra-ui/react';
import {
	commonCw,
	createTransfer,
} from '../../../../@xmcloud/core/messages/en';
import { useCreateTransfer } from '../../../../@xmcloud/hooks';
import { useGetContent } from '../../../services/contentProvider';
import {
	HeadingMd,
	LoadingSkeleton,
	XMCSButton,
} from '../../../shared-components';
import { TreeProvider } from '../components/tree/useTreeContext';
import { TreeComponent } from '../components';
import { IContentTreeNode } from '../../../models/contentTreeNode';
import { useRef, useState } from 'react';
import {
	ITransferConfigurationNode,
	TransferConfigurationNodeType,
} from '../../../models/transferConfiguration';
import { TRANSFER_CONFIGURATION } from '../helpers';
import {
	findDescendantKeys,
	prepareData as convertContentTreeToTreeNodes,
} from '../components/tree/utils/handlers';
import { useParams } from 'react-router-dom';

export const ItemSelection: React.FC = () => {
	const { transferId } = useParams<{ transferId: string }>();
	const { next, prev, setFieldValue, values } = useCreateTransfer();
	const { data, isLoading } = useGetContent({ id: 'sourceEnvironmentId' });
	const actionRef = useRef<TreeViewProps>();
	const [anySelected, setAnySelected] = useState(false);

	const handleNext = () => {
		const selectionKeys = actionRef?.current?.onSaveHandler();

		const configuration = {
			id: 'id',
			transferId: 'transferId',
			nodes: [],
		};

		const selectedNodes = Object.keys(selectionKeys)
			.filter((key) => selectionKeys[key]?.checked)
			.map((key) => selectionKeys[key]);

		configuration.nodes = selectedNodes
			.filter(
				(node) =>
					node.isBranch ||
					!selectedNodes
						.filter((node) => node.isBranch)
						.some((branch) =>
							findDescendantKeys(branch).some(
								(key: string) => key === node.key,
							),
						),
			)
			.reduce((nodes: ITransferConfigurationNode[], node: TreeNode) => {
				nodes.push({
					id: node.key,
					item: node.label,
					path: node.path,
					type: node.isBranch
						? TransferConfigurationNodeType.Branch
						: TransferConfigurationNodeType.File,
					mergeStrategy: node.mergeStrategy,
				});

				return nodes;
			}, []);

		setFieldValue(TRANSFER_CONFIGURATION, configuration);

		next(transferId);
	};

	const { previous, continue: continueText } = commonCw;

	return (
		<Stack>
			<HeadingMd text={createTransfer.itemSelectionStepTitle} />

			{isLoading ? (
				<LoadingSkeleton amount={3} height={70} />
			) : (
				<>
					<Text variant="subtle" my={4}>
						{createTransfer.selectItemsLabel}
					</Text>
					<Stack maxW="sm">
						<Text mt={4}>
							{createTransfer.itemsLabel}
							<Text as="span"> *</Text>
						</Text>
						{data && data.data && (
							<TreeProvider>
								<TreeComponent
									value={convertContentTreeToTreeNodes([
										data?.data as IContentTreeNode,
									])}
									isReadOnly={false}
									isDisabled={false}
									ref={actionRef}
									setKeysSelected={setAnySelected}
									initialValues={values.configuration}
								></TreeComponent>
							</TreeProvider>
						)}
					</Stack>

					<Stack py={5} direction="row">
						<XMCSButton
							text={previous}
							onClick={() => prev(transferId)}
							size="md"
							variant="outline"
						/>
						<XMCSButton
							text={continueText}
							onClick={handleNext}
							size="md"
							isLoading={isLoading}
							isDisabled={!anySelected}
						/>
					</Stack>
				</>
			)}
		</Stack>
	);
};

export default ItemSelection;
