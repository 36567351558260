import { useParams } from 'react-router-dom';
import {
	useEnvironmentDetails,
	useProjectDetails,
	useSites,
} from '../../../../@xmcloud/hooks';
import {
	useGetEditingSecret,
	useGetPreviewApiToken,
} from '../../../services/environments';
import { useGetRepositoryIntegration } from '../../../services/repositories';
import { CircularLoading } from '../../../shared-components';
import { ProjectTypesEnum } from '../../create-project/helpers';
import { useState } from 'react';
import { useGetTenant } from '../../../services/platformInventory';
import { EhProjectDevSettings } from '../components/developer-settings/EhProjectDevSettings';
import { CombinedProjectDevSettings } from '../components/developer-settings/CombinedProjectDevSettings';
import { useFeature } from '../../../feature-flag/features';
import { OLDCombinedProjectDevSettings } from '../components/OLDCombinedProjectDevSettings';

const { EH } = ProjectTypesEnum;

export const DeveloperSettings: React.FC = () => {
	const { environmentId, projectId } = useParams<{
		environmentId: string;
		projectId: string;
	}>();
	const developerSettingForLowerJss = useFeature(
		'DeveloperSettingForLowerJss',
	);

	const { environment, isLoading: isEnvironmentLoading } =
		useEnvironmentDetails(environmentId!);
	const cmEnvironmentId =
		environment?.editingHostEnvironmentDetails?.cmEnvironmentId || '';
	const platformTenantId = environment?.platformTenantId || '';

	const {
		project: projectDetails,
		isLoading: projectIsLoading,
		projectType,
	} = useProjectDetails(projectId!);

	const [isEditingSecretAvailable, setIsEditingSecretAvailable] =
		useState(true);
	const isEhProject = projectType === EH;
	const currentEnvironmentId = isEhProject ? cmEnvironmentId : environmentId;

	const { data: editingSecretData, isLoading: editingSecretIsLoading } =
		useGetEditingSecret({
			environmentId: currentEnvironmentId!,
			onNotFoundError: (err) => {
				setIsEditingSecretAvailable(false);
			},
		});

	const { data: apiKeyData, isLoading: apiKeyIsLoading } =
		useGetPreviewApiToken({
			environmentId: currentEnvironmentId!,
			enabled: isEhProject ? true : false,
		});

	const { sites: sitesData, environment: cmEnvironmentDetail } =
		useSites(currentEnvironmentId);
	const sourceControlIntegrationId =
		projectDetails?.data?.sourceControlIntegrationId || '';

	const { data: srcIntegration, isLoading: isIntegrationDataLoading } =
		useGetRepositoryIntegration(sourceControlIntegrationId);
	const { data: tenantData, isLoading: isGetTenantLoading } = useGetTenant({
		tenantId: platformTenantId,
	});

	const cdpEmbeddedTenantID =
		tenantData?.data?.annotations['XMCloud.CDPEmbeddedTenantID'] || '';
	const siteName = sitesData[0]?.name || '';
	const apiKey = apiKeyData?.data?.data.item?.itemId || '';
	const editingSecret = editingSecretData?.data || '';
	const project = projectDetails?.data;
	const provider = srcIntegration?.data?.provider || '';

	const isLoading =
		projectIsLoading ||
		isEnvironmentLoading ||
		editingSecretIsLoading ||
		apiKeyIsLoading ||
		isIntegrationDataLoading ||
		isGetTenantLoading;

	if (isLoading) return <CircularLoading />;

	if (isEhProject)
		return (
			<EhProjectDevSettings
				{...{
					project,
					cmEnvironmentDetail,
					siteName,
					isEditingSecretAvailable,
					editingSecret,
					apiKey,
				}}
			/>
		);

	if (developerSettingForLowerJss)
		return (
			<CombinedProjectDevSettings
				{...{
					project,
					environmentDetail: environment,
					editingSecret,
					isEditingSecretAvailable,
					sites: sitesData,
					provider,
					cdpEmbeddedTenantID,
				}}
			/>
		);

	return (
		<OLDCombinedProjectDevSettings
			{...{
				project,
				environmentDetail: environment,
				editingSecret,
				isEditingSecretAvailable,
				sites: sitesData,
				provider,
			}}
		/>
	);
};
