import {
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Flex,
} from '@chakra-ui/react';
import { FC, ReactNode, useRef } from 'react';
import { app } from '../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	title?: string;
	children: ReactNode;
	dataTestId?: string;
	rightButtonText?: string;
	leftButtonText?: string;
	onConfirm?: () => void;
	isLoading?: boolean;
	type?: 'button' | 'submit';
	flexDirection?: 'row' | 'row-reverse';
	gap?: number;
	shouldShowActionBtn?: boolean;
	colorScheme?: string;
}

export const AlertDialogConfirmationModal: FC<Props> = ({
	isOpen,
	onClose,
	title = '',
	children,
	onConfirm,
	isLoading,
	dataTestId = 'dialog-wrapper',
	rightButtonText = app.cancel,
	leftButtonText = app.delete,
	type = 'submit',
	flexDirection = 'row',
	gap = 2,
	shouldShowActionBtn = true,
	colorScheme = 'danger',
}) => {
	const cancelRef = useRef() as React.MutableRefObject<any>;
	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			data-testid={dataTestId}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					{Boolean(title) && (
						<AlertDialogHeader>{t(title)}</AlertDialogHeader>
					)}
					<AlertDialogBody>{children}</AlertDialogBody>
					<AlertDialogFooter>
						<Flex flexDirection={flexDirection} gap={gap}>
							<Button
								onClick={onClose}
								data-testid="cancel-button"
								data-behavior-analytics-id={`cancel-${dataTestId}-button`}
								variant="ghost"
								ref={cancelRef}
							>
								{t(rightButtonText)}
							</Button>
							{shouldShowActionBtn && (
								<Button
									colorScheme={colorScheme}
									onClick={onConfirm}
									isLoading={isLoading}
									type={type}
									data-testid={`${leftButtonText}-confirm-button`}
									data-behavior-analytics-id={`confirm-${dataTestId}-button`}
								>
									{t(leftButtonText)}
								</Button>
							)}
						</Flex>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
