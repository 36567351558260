import { useCallback, useEffect, useMemo } from 'react';
import { editCreateEnvironment } from '../../../../@xmcloud/core/messages/en';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { ESourceControl } from '../../../../@xmcloud/types';
import { SelectFormControl } from '../../../shared-components';
import { REPO_BRANCH, TOptions } from '../helpers';
import { debounce } from 'lodash';

export const OLDSelectBranches: React.FC<{
	branches: TOptions;
	isLoading: boolean;
	isFetching?: boolean;
	hasMore?: boolean;
	fetchNextPage?: any;
	inputQuery?: React.MutableRefObject<string>;
}> = ({
	branches,
	isLoading,
	isFetching,
	hasMore,
	fetchNextPage,
	inputQuery,
}) => {
	const { setFieldValue, values, errors } = useCreateProject();
	const isGithubProvider = values.provider === ESourceControl.GitHub;

	const currentBranch = useMemo(() => {
		if (!branches.length) return '';

		const masterOrMainBranch = branches.find(
			(b: any) => b.value === 'main' || b.value === 'master',
		);
		return Boolean(masterOrMainBranch)
			? masterOrMainBranch?.value
			: branches[0].value;
	}, [branches]);

	useEffect(() => {
		currentBranch && setFieldValue(REPO_BRANCH, currentBranch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentBranch, values.repository]);

	const handleFetchNextPage = useCallback(() => {
		if (hasMore && isGithubProvider && !isFetching) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasMore, isFetching, isGithubProvider]);

	const onInputChange = useCallback(
		(e: string) => {
			if (inputQuery) {
				inputQuery.current = e;
			}

			if (
				e === '' ||
				!hasMore ||
				!isGithubProvider ||
				!branches?.length ||
				isFetching
			)
				return;

			const hasRepo = branches.some((r: any) => r.label.includes(e));

			if (!hasRepo) {
				handleFetchNextPage();
			}
		},
		[
			branches,
			handleFetchNextPage,
			hasMore,
			inputQuery,
			isFetching,
			isGithubProvider,
		],
	);

	const debouncedChangeHandler = useMemo(
		() => debounce(onInputChange, 300),
		[onInputChange],
	);

	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		};
	}, [debouncedChangeHandler]);

	return (
		<SelectFormControl
			{...{
				error: errors.repositoryBranch,
				isInvalid: Boolean(errors.repositoryBranch),
				isLoading: isLoading || isFetching,
				options: branches,
				onChange: (e: any) => {
					setFieldValue(REPO_BRANCH, e.value);
				},
				label: editCreateEnvironment.linkToBranch,
				name: REPO_BRANCH,
				currentValue: values.repositoryBranch,
				maxW: 'xs',
				pb: 3,
				onInputChange: debouncedChangeHandler,
				onMenuScrollToBottom: () => {
					handleFetchNextPage();
				},
			}}
		/>
	);
};
