import { useMemo } from 'react';
import { useCreateProject } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { useGetADOBranches } from '../../../services/adoApi';
import { ADO_BRANCH_PREFIX } from '../../../../@xmcloud/utils/constants';
import { OLDSelectBranches } from './OLDSelectBranches';

export const OLDSelectADOBranches: React.FC<{
	token: string;
	adoOrganization: string;
	adoProjectName: string;
}> = ({ token, adoOrganization, adoProjectName }) => {
	const { values } = useCreateProject();
	const repositoryId = values.repository;
	const { data, isLoading } = useGetADOBranches(
		token,
		adoOrganization,
		adoProjectName,
		repositoryId,
	);

	const branches = useMemo(() => {
		let branches =
			data?.data.value.map((r: any) => ({
				label: r.name.replace(ADO_BRANCH_PREFIX, ''),
				value: r.name.replace(ADO_BRANCH_PREFIX, ''),
			})) || [];
		branches.push({ label: 'none', value: '' });
		return branches;
	}, [data]);
	return <OLDSelectBranches {...{ branches, isLoading }} />;
};
