import { Navigate, useLocation, useParams } from 'react-router-dom';
import { goTo, mainPaths } from '../../../@xmcloud/core/routes/paths';
const { cmInstanceLogs } = goTo;
const { noMatch } = mainPaths;

const oldProjectUrls = {
	all: (projectId: string) => goTo.environments(projectId),
	details: (projectId: string) => goTo.projectDetails(projectId),
	'': '/projects',
};

const oldEnvUrls = {
	deployments: (projectId: string, environmentId: string) =>
		goTo.deployments(projectId, environmentId),
	sites: (projectId: string, environmentId: string) =>
		goTo.sites(projectId, environmentId),
	details: (projectId: string, environmentId: string) =>
		goTo.details(projectId, environmentId),
	logs: (projectId: string, environmentId: string, type?: string) =>
		!!type
			? goTo.cmInstanceLogs(projectId, environmentId, type)
			: goTo.cmInstanceLogTypes(projectId, environmentId),
	variables: (projectId: string, environmentId: string) =>
		goTo.variables(projectId, environmentId),
	'': '/projects',
};

type KeyType = keyof typeof oldEnvUrls | keyof typeof oldProjectUrls;

export const NavigateTo: React.FC = () => {
	const params = useParams<{
		projectId: string;
		environmentId?: string;
		deploymentId?: string;
		type?: string;
	}>();
	const { pathname } = useLocation();
	const projectId = params?.projectId || '';
	const environmentId = params?.environmentId || '';
	const type = params?.type || '';
	const deploymentId = params?.deploymentId || '';

	const lastIndex = pathname.split('/').pop() as KeyType;
	const keyInEnvUrlsObj = oldEnvUrls.hasOwnProperty(lastIndex);
	const keyInProjectUrlsObj = oldProjectUrls.hasOwnProperty(lastIndex);

	const isEnvsPath =
		!!projectId &&
		!!environmentId &&
		!type &&
		!deploymentId &&
		keyInEnvUrlsObj;
	const isProjectPath =
		!!projectId &&
		!environmentId &&
		!type &&
		!deploymentId &&
		keyInProjectUrlsObj;

	const newEnvUrl =
		isEnvsPath &&
		// @ts-ignore
		oldEnvUrls[lastIndex](projectId, environmentId);

	const newProjectUrl =
		isProjectPath &&
		// @ts-ignore
		oldProjectUrls[lastIndex](projectId);
	const to = type
		? cmInstanceLogs(projectId, environmentId, type)
		: deploymentId
			? goTo.deploymentLogs(projectId, environmentId, deploymentId)
			: isEnvsPath
				? newEnvUrl
				: isProjectPath
					? newProjectUrl
					: noMatch;
	return <Navigate to={to} />;
};
