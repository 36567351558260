import { CodeBlock } from '../../../../shared-components';
import { devSettings } from '../../../../../@xmcloud/core/messages/en';
import { EnvironmentClass } from '../../../../../@xmcloud/utils/constants';
import { IGetEnvironmentResponse } from '../../../../models/environmentModel';
import { edgePlatformUrl, environment } from '../../../../config/envConfig';

const { editingSecretNotFound } = devSettings;
const { prod: PRODUCTION } = EnvironmentClass;

export const NewJssVersionSettings: React.FC<{
	environmentDetail: IGetEnvironmentResponse;
	isPreview: boolean;
	editingSecret: string;
	siteName: string;
	isEditingSecretAvailable: boolean;
}> = ({
	environmentDetail,
	isPreview,
	editingSecret,
	siteName,
	isEditingSecretAvailable,
}) => {
	const previewContextId = environmentDetail?.previewContextId || '';
	const liveContextId = environmentDetail?.liveContextId || '';
	const isProduction = environment === PRODUCTION;

	const envVariablesLines = [
		...(!isProduction ? [`SITECORE_EDGE_URL=${edgePlatformUrl}`] : []),
		`SITECORE_EDGE_CONTEXT_ID=${
			isPreview ? previewContextId : liveContextId
		}`,
		`SITECORE_SITE_NAME=${siteName}`,
		...(isPreview
			? [
					isEditingSecretAvailable
						? `JSS_EDITING_SECRET=${editingSecret}`
						: `#JSS_EDITING_SECRET=${editingSecretNotFound}`,
				]
			: []),
	];

	return (
		<>
			<CodeBlock
				items={envVariablesLines}
				clipboardText={envVariablesLines.join('\n')}
				data-testid="code-block"
				key="environment-code-block"
			/>
		</>
	);
};
