import { Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
	app,
	projects,
	repository as repo,
} from '../../../@xmcloud/core/messages/en';
import { useProjectDetails } from '../../../@xmcloud/hooks';
import { CircularLoading, TextBox } from '../../shared-components';
import { getReadableDate } from '../../../@xmcloud/utils/dateUtils';
import { useFeature } from '../../feature-flag/features';
import { ProjectTypesEnum } from '../create-project/helpers';

const { id: idtxt, region: regiontxt, type: typetxt } = app;
const {
	account: accounttxt,
	name: nametxt,
	repositoryLinkedDate: repositoryLinkedDatetxt,
} = repo;
const { webApp, contentService } = projects;

const { EH } = ProjectTypesEnum;

export const ProjectDetailsPage: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const { project, isLoading } = useProjectDetails(projectId!);
	const projectData = project?.data;
	const createProjectV2 = useFeature('CreateProjectV2');

	if (!projectData || isLoading) return <CircularLoading />;

	const {
		id,
		region,
		repository,
		sourceControlIntegrationName,
		repositoryLinkedAt,
	} = projectData;

	const projectType = projectData?.type || '';
	const isEH = EH === projectType;

	const details = [
		...(createProjectV2
			? [
					{
						title: typetxt,
						subTitle: isEH ? webApp : contentService,
					},
				]
			: []),
		{ title: idtxt, subTitle: id },
		{ title: accounttxt, subTitle: sourceControlIntegrationName || '-' },
		{ title: nametxt, subTitle: repository || '-' },
		{
			title: repositoryLinkedDatetxt,
			subTitle:
				(repositoryLinkedAt &&
					getReadableDate(repositoryLinkedAt, 'PPp')) ||
				'-',
		},
		{ title: regiontxt, subTitle: region },
	];

	return (
		<Stack spacing={5} mt={5} pl={5}>
			{details.map(({ title, subTitle }) => (
				<TextBox {...{ title, subTitle }} key={title} />
			))}
		</Stack>
	);
};
