import { useEffect } from 'react';
import { useExperimentalFeatures } from '../../../../@xmcloud/hooks';
import { useFeature } from '../../../feature-flag/features';
import { handlePush } from '../../../../@xmcloud/utils/helpers';

export const useRedirect404 = () => {
	const { createEhAndCmProjectExperimentalFeature } =
		useExperimentalFeatures();
	const createProjectV2 = useFeature('CreateProjectV2');
	const enableEhCmProjectTemplate =
		createProjectV2 && createEhAndCmProjectExperimentalFeature;

	useEffect(() => {
		if (!enableEhCmProjectTemplate) {
			handlePush('/404');
		}
	}, [enableEhCmProjectTemplate]);
	return {};
};
