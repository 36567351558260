import { useCallback, useState } from 'react';
import { IRepositoryTemplateDetails } from '../../../models/repositoriesModel';
import {
	isCMProjectType,
	isEHProjectType,
	repoForCM,
	repoForEH,
} from '../helpers';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import {
	useGetADORepository,
	useUpdateADORepository,
} from '../../../services/adoApi';
import { useGetGithubPublicRepository } from '../../../services/githubApi';
import {
	useCreateRepositoryMutation,
	useGetRepositoryTemplates,
	useRepositoryHookSubscriptionMutation,
} from '../../../services/repositories';
import { ESourceControl } from '../../../../@xmcloud/types';
import { ADO_BRANCH_PREFIX } from '../../../../@xmcloud/utils/constants';
import {
	ResponseStatusEnum,
	ResponseStatusTypes,
} from '../../../../@xmcloud/utils/helpers';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { useOLDProjectCreation } from './useOLDProjectCreation';
import { handleResponseStaus } from './helpers';

const PROVIDER = ESourceControl.ADO;
const { IDLE, LOADING, ERROR, SUCCESS } = ResponseStatusEnum;

export function useADOProjectCreation(values: FormikValues) {
	const [repositoryDetails, setRepositoryDetails] = useState({
		repositoryBranch: '',
		repositoryName: '',
		organization: '',
		project: '',
		repo: '',
		repositoryId: '',
	});
	const [readinessRepo, setReadinessRepo] =
		useState<ResponseStatusTypes>(IDLE);
	const {
		data: repositoryTemplatesData,
		isLoading: isLoadingRepositoryTemplates,
	} = useGetRepositoryTemplates(PROVIDER);

	const isEhType = isEHProjectType(values.projectType);
	const isCmType = isCMProjectType(values.projectType);

	const repositoryTemplates =
		repositoryTemplatesData?.data || ([] as IRepositoryTemplateDetails[]);
	const foundationTemplate = repositoryTemplates[0];
	const templateOwner = foundationTemplate?.owner;
	const templateRepository = isEhType
		? repoForEH //hard coding the repo name for eh project test purpose
		: isCmType
			? repoForCM //hard coding the repo name for cm project test purpose
			: foundationTemplate?.name;

	const { data: githubRepositoryData, isLoading: isLoadingGithubRepository } =
		useGetGithubPublicRepository(templateOwner, templateRepository);
	const templateDefaultBranch =
		githubRepositoryData?.data.default_branch || '';

	const {
		mutate: createADORepo,
		isLoading: isLoadingCreateADORepo,
		status: createADORepoStatus,
	} = useCreateRepositoryMutation();

	const { data: accessTokenData, isLoading: isLoadingAccessToken } =
		useGetSourceControlAccessToken(values.sourceControlIntegrationId);
	const accessToken = accessTokenData?.data.accessToken || '';

	const {
		mutate: repoHookSubscription,
		isLoading: isLoadingHookSubscription,
		status: hookSubscriptionStatus,
	} = useRepositoryHookSubscriptionMutation();

	const {
		isLoading: isLoadingADORepository,
		isFetching: isFetchingADORepository,
	} = useGetADORepository({
		token: accessToken,
		organization: repositoryDetails.organization,
		project: repositoryDetails.project,
		repo: repositoryDetails.repo,
		onSuccess: onGetAdoSuccess,
	});

	const {
		mutate: updateADORepo,
		isLoading: isLoadingUpdateADORepo,
		status: updateADORepoStatus,
	} = useUpdateADORepository(
		accessToken,
		repositoryDetails.organization,
		repositoryDetails.project,
		repositoryDetails.repositoryId,
	);

	const {
		onProjectCreation,
		handleEnvironmentCreation,
		projectId,
		shouldRetry: shouldRetryOnCreateProject,
		isLoading: isLoadingProjectCreation,
		shouldReTryEnvironmentCreation,
		shouldReTryDeploymentCreation,
		shouldReTryDeploymentDeploy,
		shouldRetryCreateProject,
		currentEnvironmentId,
		currentDeploymentId,
		createProjectStatus,
		createEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
		onDeploymentCreation,
		onDeploymentDeploy,
	} = useOLDProjectCreation();

	const shouldRetryADORepo = createADORepoStatus === ERROR;
	const shouldRetryHookSubscription = hookSubscriptionStatus === ERROR;
	const shouldRetryUpdateADORepo = updateADORepoStatus === ERROR;
	const shouldRetry =
		shouldRetryADORepo ||
		shouldRetryUpdateADORepo ||
		shouldRetryHookSubscription ||
		shouldRetryOnCreateProject ||
		shouldReTryEnvironmentCreation ||
		shouldReTryDeploymentCreation ||
		shouldReTryDeploymentDeploy;

	const onEditADORepository = useCallback(() => {
		updateADORepo(
			{
				defaultBranch: `${ADO_BRANCH_PREFIX}${templateDefaultBranch}`,
			},
			{
				onSuccess: () => {
					onProjectCreation(
						values,
						repositoryDetails.repositoryName,
						templateDefaultBranch,
						repositoryDetails.repositoryId,
					);
				},
			},
		);
	}, [
		onProjectCreation,
		repositoryDetails.repositoryId,
		repositoryDetails.repositoryName,
		templateDefaultBranch,
		updateADORepo,
		values,
	]);

	const onRepoHookSubscription = useCallback(() => {
		const repoHookSubsPayload = {
			repositoryName: repositoryDetails.repositoryName,
			integrationId: values.sourceControlIntegrationId,
		};
		repoHookSubscription(repoHookSubsPayload, {
			onSuccess: () => {
				//trigger the 3rd step
				onEditADORepository();
			},
		});
	}, [
		onEditADORepository,
		repoHookSubscription,
		repositoryDetails.repositoryName,
		values.sourceControlIntegrationId,
	]);

	//2rd step: repository hook subscription

	function onGetAdoSuccess(defaultBranch: string) {
		if (defaultBranch && repositoryDetails.repositoryName) {
			setReadinessRepo(SUCCESS);
			onRepoHookSubscription();
		}
	}

	const onADOProjectCreation = () => {
		const {
			sourceControlIntegrationId,
			connectionName,
			repository,
			adoOrganization,
			adoProjectName,
		} = values;

		const repoPath = `${adoOrganization}/${adoProjectName}/${repository}`;

		//create ADO repo payload
		const createADORepoPayload = {
			integrationId: sourceControlIntegrationId,
			owner: connectionName,
			repositoryName: repoPath,
			templateRepository,
			templateOwner,
		};

		function onRetry() {
			if (shouldRetryADORepo) {
				handleCreationProcess(createADORepoPayload);
			} else if (shouldRetryHookSubscription) {
				onRepoHookSubscription();
			} else if (shouldRetryUpdateADORepo) {
				onEditADORepository();
			} else if (shouldRetryCreateProject) {
				onProjectCreation(
					values,
					repositoryDetails.repositoryName,
					templateDefaultBranch,
					repositoryDetails.repositoryId,
				);
			} else if (shouldReTryEnvironmentCreation) {
				handleEnvironmentCreation(
					values,
					projectId,
					templateDefaultBranch,
				);
			} else if (shouldReTryDeploymentCreation) {
				onDeploymentCreation(projectId, currentEnvironmentId);
			} else if (shouldReTryDeploymentDeploy) {
				onDeploymentDeploy(
					projectId,
					currentEnvironmentId,
					currentDeploymentId,
				);
			}
		}
		//1st step: create ADO repo
		const handleCreationProcess = (payload: any) => {
			createADORepo(payload, {
				onSuccess: (data) => {
					setReadinessRepo(LOADING);
					const repositoryName = data.data.fullName;
					const fullName = data.data.fullName;
					const repositoryBranch = data.data.defaultBranch;
					const splitedFullName = fullName.split('/');
					const organization = splitedFullName[0];
					const project = splitedFullName[1];
					const repo = splitedFullName[2];
					const id = data.data.id || '';
					setRepositoryDetails({
						repositoryBranch,
						repositoryName,
						organization,
						project,
						repo,
						repositoryId: id,
					});
					//2nd step will trigger when success of importing the repo to Azure DevOps
				},
			});
		};

		if (shouldRetry) {
			//retry step if it fails
			onRetry();
		} else {
			//trigger the 1st step
			handleCreationProcess(createADORepoPayload);
		}
	};

	const repositoryCreationStatus = handleResponseStaus(
		createADORepoStatus,
		readinessRepo,
		hookSubscriptionStatus,
	);

	const projectCreationStatus = handleResponseStaus(
		createProjectStatus,
		updateADORepoStatus,
	);

	const environmentCreationStatus = handleResponseStaus(
		createEnvironmentStatus,
	);

	const deploymentCreationStatus = handleResponseStaus(
		createDeploymentStatus,
		deploymentDeployStatus,
	);

	const isLoading =
		isLoadingRepositoryTemplates ||
		isLoadingGithubRepository ||
		isLoadingAccessToken ||
		isLoadingADORepository ||
		isFetchingADORepository ||
		isLoadingCreateADORepo ||
		isLoadingUpdateADORepo ||
		isLoadingHookSubscription ||
		isLoadingProjectCreation;

	return {
		onADOProjectCreation,
		isLoading,
		shouldRetry,
		shouldRetryADORepo,
		repositoryCreationStatus,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
		isFetchingADORepository,
	};
}
