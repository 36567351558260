import { Stack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { environments } from '../../../../@xmcloud/core/messages/en';
import {
	IS_SM_SCREEN,
	useSharedState,
} from '../../../../@xmcloud/hooks/context/useSharedState';
import { Footer, XMCSButton } from '../../../shared-components';
import ProjectEnvironmentsTable from '../tables/ProjectEnvironmentsTable';
import CreateEnvironment from './CreateEnvironment';

const { create: createtxt } = environments;

export const ProjectEnvironments: FC = () => {
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const { state: isSmScreen } = useSharedState(IS_SM_SCREEN, false);
	return (
		<>
			<Stack direction="row" justify="flex-end" py={isSmScreen ? 3 : 5}>
				{!isSmScreen && (
					<XMCSButton
						text={createtxt}
						onClick={() => setOpenCreateModal(true)}
					/>
				)}
			</Stack>

			<ProjectEnvironmentsTable />
			{Boolean(isSmScreen) && (
				<Footer>
					<XMCSButton
						text={createtxt}
						onClick={() => setOpenCreateModal(true)}
					/>
				</Footer>
			)}
			{openCreateModal && (
				<CreateEnvironment
					openModal={openCreateModal}
					setOpenModal={setOpenCreateModal}
				/>
			)}
		</>
	);
};
