import {
	useCreateRepositoryMutation,
	useRepositoryHookSubscriptionMutation,
} from '../../../app/services/repositories';
import { useCreateProject } from '../context/CreateProjectContext';
import { ResponseStatusEnum } from '../../utils/helpers';
import { IRepositoryTemplateDetails } from '../../../app/models/repositoriesModel';
import { useState } from 'react';
import { useGetGithubPublicRepository } from '../../../app/services/githubApi';
import {
	getProcessStatus,
	isCMProjectType,
	isEHProjectType,
	ProjectTypesEnum,
	repoForCM,
	repoForEH,
	TADOOrganization,
	TADOProjectName,
	TDeployOnCommit,
	TEnvironmentName,
	TProjectName,
	TRepository,
	TRepositoryBranch,
	TRepositoryId,
	TRepositoryRelativePath,
} from '../../../app/pages/create-project/helpers';
import { useCreateProjectMutationV2 } from '../../../app/services/projects';
import { useEnvironmentCreation } from '..';
import {
	useGetADORepository,
	useUpdateADORepository,
} from '../../../app/services/adoApi';
import { ADO_BRANCH_PREFIX } from '../../utils/constants';

const { ERROR, IDLE, LOADING, SUCCESS } = ResponseStatusEnum;

export const useXMCloudTemplateADOProjectCreation = ({
	projectNameSpace = 'name',
	repositoryNamespace = 'repository',
	repositoryIdNamespace = 'repositoryId',
	repositoryBranchNamespace = 'repositoryBranch',
	repositoryRelativePathNamespace = 'repositoryRelativePath',
	environmentNamespace = 'environmentName',
	deployOnCommitNamespace = 'deployOnCommit',
	adoProjectNamespace = 'adoProjectName',
	adoOrganizationNamespace = 'adoOrganization',
	XMCloudTemplate,
	projectType,
	token,
	shouldGotoDeploymentPage = false,
	shouldGotoDeploymentsPage = false,
	onCmProjectCreationSuccess,
	skipCreateRepository = false,
	repositoryBranch = '',
	cmCurrentEnvironmentId,
}: {
	token: string;
	XMCloudTemplate: IRepositoryTemplateDetails;
	projectType: ProjectTypesEnum;
	repositoryNamespace?: TRepository;
	repositoryIdNamespace?: TRepositoryId;
	repositoryBranchNamespace?: TRepositoryBranch;
	repositoryRelativePathNamespace?: TRepositoryRelativePath;
	environmentNamespace?: TEnvironmentName;
	projectNameSpace?: TProjectName;
	deployOnCommitNamespace?: TDeployOnCommit;
	adoProjectNamespace?: TADOProjectName;
	adoOrganizationNamespace?: TADOOrganization;
	shouldGotoDeploymentPage?: boolean;
	shouldGotoDeploymentsPage?: boolean;
	onCmProjectCreationSuccess?: (id: string) => void;
	skipCreateRepository?: boolean;
	repositoryBranch?: string;
	cmCurrentEnvironmentId?: string;
}) => {
	const { values, isOwnCode, isMonoRepo, setValues, isDualJourney } =
		useCreateProject();
	const sourceControlIntegrationId = values.sourceControlIntegrationId;
	const cmEnvironmentId = values.cmEnvironmentId;
	const [projectID, setprojectID] = useState('');
	const [adoRepositoyStatus, setAdoRepositoyStatus] = useState(IDLE);

	const [repositoryDetails, setRepositoryDetails] = useState({
		[repositoryBranchNamespace]: '',
		[repositoryNamespace]: '',
		[repositoryIdNamespace]: '',
		[adoOrganizationNamespace]: '',
		[adoProjectNamespace]: '',
	});

	const isEH = isEHProjectType(projectType);
	const isCm = isCMProjectType(projectType);
	const templateRepository =
		isMonoRepo && isDualJourney
			? XMCloudTemplate?.name
			: isEH
				? repoForEH
				: isCm
					? repoForCM
					: XMCloudTemplate?.name || '';
	const templateOwner = XMCloudTemplate?.owner || '';

	//get XM CLOUD template default branch
	const { data: githubRepositoryData, isLoading: isLoadingGithubRepository } =
		useGetGithubPublicRepository(templateOwner, templateRepository);
	const templateDefaultBranch =
		githubRepositoryData?.data.default_branch || '';

	const {
		mutate: createADORepo,
		isLoading: isCreateADORepoLoading,
		status: createADORepoStatus,
	} = useCreateRepositoryMutation();

	useGetADORepository({
		token,
		organization: repositoryDetails[adoOrganizationNamespace],
		project: repositoryDetails[adoProjectNamespace],
		repo: repositoryDetails[repositoryNamespace],
		onSuccess: onGetAdoSuccess,
	});

	function onGetAdoSuccess(defaultBranch: string) {
		if (defaultBranch && repositoryDetails[repositoryNamespace]) {
			setAdoRepositoyStatus(SUCCESS);
			onSecondProcess();
		}
	}

	const {
		mutate: repoHookSubscription,
		isLoading: isLoadingHookSubscription,
		status: hookSubscriptionStatus,
	} = useRepositoryHookSubscriptionMutation();

	const {
		mutate: updateADORepo,
		isLoading: isLoadingUpdateADORepo,
		status: updateADORepoStatus,
	} = useUpdateADORepository(
		token,
		repositoryDetails[adoOrganizationNamespace],
		repositoryDetails[adoProjectNamespace],
		repositoryDetails[repositoryIdNamespace],
	);

	const {
		mutate: createProject,
		isLoading: isLoadingCreateProject,
		status: createProjectStatus,
	} = useCreateProjectMutationV2();

	const {
		onEnvironmentCreation,
		onEditEnvironment,
		onDeploymentCreation,
		onDeploymentDeploy,
		isLoading: isLoadingCreateEnvironment,
		shouldReTryEnvironmentCreation,
		shouldReTryDeploymentCreation,
		shouldReTryDeploymentDeploy,
		currentEnvironmentId,
		currentDeploymentId,
		createEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
		editEnvironmentStatus,
		shouldReTryEditEnvironment,
	} = useEnvironmentCreation({
		shouldGotoDeploymentPage,
		onCmProjectCreationSuccess,
		shouldGotoDeploymentsPage,
		shouldEditEnvironment: isOwnCode,
	});
	const editEnvStatus = isOwnCode ? editEnvironmentStatus : SUCCESS;

	const shouldRetryFirstProcess = createADORepoStatus === ERROR;
	const shouldRetrySecondProcess = hookSubscriptionStatus === ERROR;
	const shouldRetryThirdProcess = updateADORepoStatus === ERROR;
	const shouldRetryFourthProcess = createProjectStatus === ERROR;
	const shouldRetryFifthProcess = shouldReTryEnvironmentCreation;
	const shouldEditEnvironmentProcess = shouldReTryEditEnvironment;
	const shouldRetrySixthProcess = shouldReTryDeploymentCreation;
	const shouldRetrySeventhProcess = shouldReTryDeploymentDeploy;

	function onFirstProcess() {
		const repoPath = `${values[adoOrganizationNamespace]}/${values[adoProjectNamespace]}/${values[repositoryNamespace]}`;
		const payload = {
			integrationId: sourceControlIntegrationId,
			owner: values.connectionName,
			repositoryName: repoPath,
			templateRepository,
			templateOwner: XMCloudTemplate?.owner || '',
		};

		createADORepo(payload, {
			onSuccess: (data) => {
				const fullName = data.data.fullName;
				const repositoryBranch = data.data.defaultBranch;
				const splitedFullName = fullName.split('/');
				const adoOrganization = splitedFullName[0];
				const adoProjectName = splitedFullName[1];
				const repositoryName = splitedFullName[2];
				const adoRepositoryId = data.data.id || '';

				setRepositoryDetails({
					[repositoryBranchNamespace]: repositoryBranch,
					[repositoryNamespace]: repositoryName,
					[repositoryIdNamespace]: adoRepositoryId,
					[adoOrganizationNamespace]: adoOrganization,
					[adoProjectNamespace]: adoProjectName,
				});
				setAdoRepositoyStatus(LOADING);
				setValues((prev) => ({
					...prev,
					[repositoryBranchNamespace]: repositoryBranch,
					[repositoryNamespace]: repositoryName,
					[repositoryIdNamespace]: adoRepositoryId,
					[adoOrganizationNamespace]: adoOrganization,
					[adoProjectNamespace]: adoProjectName,
				}));
			},
		});
	}

	function onSecondProcess(cmEnvId?: string) {
		const repoHookSubsPayload = {
			repositoryName: `${values[adoOrganizationNamespace]}/${values[adoProjectNamespace]}/${values[repositoryNamespace]}`,
			integrationId: sourceControlIntegrationId,
		};
		repoHookSubscription(repoHookSubsPayload, {
			onSuccess: () => {
				onThirdProcess(cmEnvId);
			},
		});
	}

	function onThirdProcess(cmEnvId?: string) {
		if (skipCreateRepository || isOwnCode) {
			onFourthProcess(cmEnvId);
			return;
		}
		updateADORepo(
			{
				defaultBranch: `${ADO_BRANCH_PREFIX}${templateDefaultBranch}`,
			},
			{
				onSuccess: () => {
					onFourthProcess(cmEnvId);
				},
			},
		);
	}

	function onFourthProcess(cmEnvId?: string) {
		const projectPayload = {
			name: values[projectNameSpace],
			repository: `${values[adoOrganizationNamespace]}/${values[adoProjectNamespace]}/${values[repositoryNamespace]}`,
			sourceControlIntegrationId,
			repositoryId: `${values[repositoryIdNamespace]}`,
			type: projectType,
			...(isEH && {
				editingHostProjectDetails: {
					repositoryRelativePath:
						values[repositoryRelativePathNamespace],
				},
			}),
		};
		createProject(
			{ project: projectPayload },
			{
				onSuccess: (data) => {
					const currentProjectId = data.data.id;
					setprojectID(currentProjectId);
					onFifthProcess(currentProjectId, cmEnvId);
				},
			},
		);
	}

	const onFifthProcess = (projectId: string, cmEnvId?: string) => {
		const envPayload = {
			name: values[environmentNamespace],
			tenantType: values.tenantType,
			repositoryBranch:
				values[repositoryBranchNamespace] ||
				repositoryBranch ||
				repositoryDetails[repositoryBranchNamespace] ||
				templateDefaultBranch,
			deployOnCommit: values[deployOnCommitNamespace],
			linkEnvironmentToDefaultBranch:
				values.linkEnvironmentToDefaultBranch,
			...(isEH && {
				editingHostEnvironmentDetails: {
					cmEnvironmentId:
						cmEnvId || cmEnvironmentId || cmCurrentEnvironmentId,
				},
			}),
		};
		onEnvironmentCreation(projectId, envPayload);
	};

	function onHandleCreationProcess(cmEnvId?: string) {
		const currentCmEnvId =
			typeof cmEnvId === 'string' ? cmEnvId : cmEnvironmentId;
		if (shouldRetryFirstProcess) {
			onFirstProcess();
		} else if (shouldRetrySecondProcess) {
			onSecondProcess(currentCmEnvId);
		} else if (shouldRetryThirdProcess) {
			onThirdProcess();
		} else if (shouldRetryFourthProcess) {
			onFourthProcess();
		} else if (shouldRetryFifthProcess) {
			onFifthProcess(projectID);
		} else if (shouldEditEnvironmentProcess) {
			onEditEnvironment(
				repositoryBranch,
				projectID,
				currentEnvironmentId,
			);
		} else if (shouldRetrySixthProcess) {
			onDeploymentCreation(projectID, currentEnvironmentId);
		} else if (shouldRetrySeventhProcess) {
			onDeploymentDeploy(
				projectID,
				currentEnvironmentId,
				currentDeploymentId,
			);
		} else {
			if (skipCreateRepository) {
				onSecondProcess(currentCmEnvId);
				return;
			}
			onFirstProcess();
		}
	}

	const repositoryCreationStatus = getProcessStatus(
		createADORepoStatus,
		adoRepositoyStatus,
	);
	const projectCreationStatus = getProcessStatus(
		hookSubscriptionStatus,
		createProjectStatus,
		isOwnCode || skipCreateRepository ? SUCCESS : updateADORepoStatus,
	);
	const environmentCreationStatus = getProcessStatus(
		createEnvironmentStatus,
		editEnvStatus,
	);
	const deploymentCreationStatus = getProcessStatus(
		createDeploymentStatus,
		deploymentDeployStatus,
	);
	const isRepositoryFetchingLoading = adoRepositoyStatus === LOADING;

	const isLoading =
		isCreateADORepoLoading ||
		isRepositoryFetchingLoading ||
		isLoadingCreateProject ||
		isLoadingCreateEnvironment ||
		isLoadingGithubRepository ||
		isLoadingHookSubscription ||
		isLoadingUpdateADORepo;

	const shouldRetry =
		shouldRetryFirstProcess ||
		shouldRetrySecondProcess ||
		shouldRetryThirdProcess ||
		shouldRetryFourthProcess ||
		shouldRetryFifthProcess;

	return {
		isLoading,
		onHandleCreationProcess,
		repositoryCreationStatus,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
		shouldRetry,
		currentEnvironmentId,
		repositoryDetails,
		isOwnCode,
	};
};
