import { app } from '../../../@xmcloud/core/messages/en';
import { goTo, mainPaths } from '../../../@xmcloud/core/routes/paths';

export const projectPageItems = [
	{ name: app.projects, to: mainPaths.projects },
];
export const transferDetailsPageItems = [
	{ name: app.contenttransfer, to: mainPaths.contenttransfer },
];
export const environmentItems = (
	projectName: string | undefined,
	projectId: string,
) => [
	{
		name: app.projects,
		to: mainPaths.projects,
	},
	{
		name: projectName ?? '...',
		to: goTo.environments(projectId),
	},
];

export const deploymentLogsItems = (
	projectName: string | undefined,
	projectId: string,
	environmentId: string,
	environmentName: string | undefined,
) => [
	{
		name: app.projects,
		to: mainPaths.projects,
	},
	{
		name: projectName ?? '...',
		to: goTo.environments(projectId),
	},
	{
		name: environmentName ?? '...',
		to: goTo.deployments(projectId, environmentId),
	},
];
