import { Stack, Wrap } from '@chakra-ui/react';
import { secondStep } from '../../../../@xmcloud/core/messages/en';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { SelectFormControl, XMCSButton } from '../../../shared-components';
import {
	useGetRepositoryIntegrations,
	useRepositoryIntegrateState,
} from '../../../services/repositories';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { ESourceControl, SourceControlType } from '../../../../@xmcloud/types';
import {
	ACCOUNT_TYPE,
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	CM_ADO_ORGANIZATION,
	CM_ADO_PROJECT_NAME,
	CM_REPOSITORY,
	CM_REPO_BRANCH,
	CONNECTION_NAME,
	EH_ADO_ORGANIZATION,
	EH_ADO_PROJECT_NAME,
	EH_REPOSITORY,
	EH_REPO_BRANCH,
	PROVIDER,
	REPOSITORY,
	REPO_BRANCH,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../helpers';
import { useMemo, useEffect } from 'react';
import {
	adoIntegrationUrl,
	githubIntegrationUrl,
	NEW_SOURCE_CONTROL_ACCOUNT,
} from '../../../../@xmcloud/utils/constants';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';

const { validate_integration_V2 } = config.repository;

const { info1, chooseAcccount, connectTo } = secondStep;
const { ADO, GitHub } = ESourceControl;

export const ExistingConnection: React.FC<{
	isIntegrationIdValidationValid: boolean;
	integrationValidationErrorMsg: string;
	isSourceControlIntegrationLoading: boolean;
}> = ({
	integrationValidationErrorMsg,
	isIntegrationIdValidationValid,
	isSourceControlIntegrationLoading,
}) => {
	const {
		values,
		errors,
		touched,
		setFieldTouched,
		setValues,
		setTouched,
		setErrors,
	} = useCreateProject();
	const queryClient = useQueryClient();

	const { data, isLoading: isFetchingIntegrationLoading } =
		useGetRepositoryIntegrations();

	const repositoryIntegrations = useMemo(
		() => data?.data.filter((i) => i.provider === values.provider) || [],
		[data, values.provider],
	);

	const integrationsOptions =
		repositoryIntegrations.map(({ account, id }) => ({
			label: account,
			value: id,
		})) || [];

	const isGithubProvider = values.provider === GitHub;
	const isADOProvider = values.provider === ADO;

	const integrationLabel = chooseAcccount(
		isGithubProvider ? 'GitHub' : isADOProvider ? 'Azure DevOps' : 'a',
	);

	const {
		refetch: fetchIntegrateState,
		isFetching: isFetchingIntegrateState,
	} = useRepositoryIntegrateState({ enabled: false });

	useEffect(() => {
		const channel = new BroadcastChannel(NEW_SOURCE_CONTROL_ACCOUNT);

		channel.addEventListener('message', (e) => {
			if (e.origin !== window.location.origin) return;
			queryClient.invalidateQueries(
				validate_integration_V2.queryKey(e.data.id),
			);
			setTouched({});
			setErrors({});
			setValues((prev: FormikValues) => ({
				...prev,
				[CONNECTION_NAME]: e.data.account,
				[SOURCE_CONTROL_INTEGRATION_ID]: e.data.id,
				[PROVIDER]: e.data.provider,
				[ACCOUNT_TYPE]: e.data.accountType,
			}));
		});

		return () => {
			channel.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setValues]);

	return (
		<Stack gap="2">
			<SelectFormControl
				{...{
					isInvalid: Boolean(
						(errors.sourceControlIntegrationId ||
							!isIntegrationIdValidationValid) &&
							touched.sourceControlIntegrationId,
					),
					isLoading:
						isFetchingIntegrationLoading ||
						isSourceControlIntegrationLoading,
					options: integrationsOptions,
					onChange: (e: any) => {
						const integration = repositoryIntegrations?.find(
							(i: any) => i.id === e.value,
						);
						if (!integration?.provider) return;
						setValues((prev: FormikValues) => ({
							...prev,
							[REPOSITORY]: '',
							[CM_REPOSITORY]: '',
							[EH_REPOSITORY]: '',
							[REPO_BRANCH]: '',
							[CM_REPO_BRANCH]: '',
							[EH_REPO_BRANCH]: '',
							[SOURCE_CONTROL_INTEGRATION_ID]: e.value || '',
							[CONNECTION_NAME]: integration?.account,
							[PROVIDER]:
								integration.provider as SourceControlType,
							[ACCOUNT_TYPE]: integration?.accountType || 0,
							[ADO_ORGANIZATION]: '',
							[CM_ADO_ORGANIZATION]: '',
							[EH_ADO_ORGANIZATION]: '',
							[ADO_PROJECT_NAME]: '',
							[CM_ADO_PROJECT_NAME]: '',
							[EH_ADO_PROJECT_NAME]: '',
						}));
					},
					label: integrationLabel,
					name: SOURCE_CONTROL_INTEGRATION_ID,
					currentValue: values.sourceControlIntegrationId,
					error:
						errors.sourceControlIntegrationId ||
						integrationValidationErrorMsg,
					maxW: 'sm',
					pt: 2,
					onFocus: () =>
						setFieldTouched(SOURCE_CONTROL_INTEGRATION_ID, true),
					isRequired: true,
					textContent: info1,
				}}
			/>
			<Wrap>
				<XMCSButton
					text={connectTo}
					isLoading={isFetchingIntegrateState}
					variant="link"
					onClick={() => {
						if (values.provider) {
							fetchIntegrateState().then(({ data }) => {
								const state = data?.data ?? '';
								window.open(
									isGithubProvider
										? githubIntegrationUrl(state)
										: isADOProvider
											? adoIntegrationUrl(state)
											: '#',
								);
							});
						}
					}}
				/>
			</Wrap>
		</Stack>
	);
};
