import { useCreateProject } from '../../../../@xmcloud/hooks';
import { useValidateSourceControlIntegrationV2 } from '../../../services/repositories';
import { OLDExistingConnection } from './OLDExstingConnection';
import { useADOValidation } from './useADOValidation';

export const OLDExistingADOConnection: React.FC = () => {
	const { values, next, setFieldTouched, setFieldError } = useCreateProject();
	const sourceControlIntegrationId = values.sourceControlIntegrationId;

	const { handleValidation } = useADOValidation(
		values,
		setFieldTouched,
		setFieldError,
		next,
	);

	const { data: validationData, isFetching: isLoadingIntegrationId } =
		useValidateSourceControlIntegrationV2({
			integrationId: sourceControlIntegrationId,
			enabled: !!sourceControlIntegrationId,
		});

	const errorMsg = validationData?.data?.message || '';
	const isIntegrationIdValidationValid =
		!validationData || isLoadingIntegrationId
			? true
			: validationData.data.isValid;

	async function handleNext() {
		handleValidation();
	}

	const isLoading = isLoadingIntegrationId;
	return (
		<OLDExistingConnection
			{...{
				handleNext,
				isLoading,
				errorMsg,
				isIntegrationIdValidationValid,
				isLoadingIntegrationId,
			}}
		/>
	);
};
