import useLocalStorage from 'use-local-storage';

//localStorage keys

const CREATE_EH_CM_PROJECT_EXPERIMENTAL_FEATURE =
	'Create_EH_CM_ProjectWizardExperimentalFeature';
const CONTENT_TRANSFER_EXPERIMENTAL_FEATURE =
	'ContentTransferExperimentalFeature';

export function useExperimentalFeatures() {
	const [
		createEhAndCmProjectExperimentalFeature,
		setCreateEhAndCmProjectExperimentalFeature,
	] = useLocalStorage<boolean>(
		CREATE_EH_CM_PROJECT_EXPERIMENTAL_FEATURE,
		false,
	);
	const [contentTransferExpFeat, setContentTransferExpFeature] =
		useLocalStorage<boolean>(CONTENT_TRANSFER_EXPERIMENTAL_FEATURE, false);
	return {
		createEhAndCmProjectExperimentalFeature,
		setCreateEhAndCmProjectExperimentalFeature,
		contentTransferExpFeat,
		setContentTransferExpFeature,
	};
}
