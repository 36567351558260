import { FC, useState } from 'react';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { CreateProjectProvider } from '../../../@xmcloud/hooks';
import { MainModal } from './components';
import { Outlet } from 'react-router-dom';

const { projects: projectsGoto } = goTo;

export const MainWizardCreateProject: FC = () => {
	const [isOpen, setIsOpen] = useState(true);

	function onClose() {
		setIsOpen(false);
		handlePush(projectsGoto);
	}

	return (
		<CreateProjectProvider>
			<MainModal {...{ isOpen, onClose }}>
				<Outlet />
			</MainModal>
		</CreateProjectProvider>
	);
};
