import { TenantType } from '../../@xmcloud/types';
import { DeployProcessStatus } from './deploymentModel';
import { IGetHostingProject } from './hostingModel';

export interface IGetEnvironmentResponse {
	projectId: string;
	projectName: string;
	organizationId: string;
	organizationName: string;
	id: string;
	platformTenantName: string;
	name: string;
	host: string;
	provisioningStatus: DeployProcessStatus;
	createdBy: string;
	createdAt: string;
	lastUpdatedBy: string;
	lastUpdatedAt: string;
	repositoryBranch: string | null;
	deployOnCommit: boolean | null;
	sitecoreMajorVersion?: number;
	sitecoreMinorVersion?: number;
	tenantType: 'prod' | 'nonprod';
	platformTenantId?: string;
	provisioningLastFailureMessage?: string;
	lastSuccessfulDeploymentId?: string;
	isDeleted?: boolean;
	editingHostEnvironmentDetails?: {
		cmEnvironmentId: string;
	};
	previewContextId?: string | null;
	liveContextId?: string | null;
}

export interface IEnvironmentDetails {
	name?: string;
	tenantType?: TenantType;
	repositoryBranch?: string;
	deployOnCommit?: boolean;
	sitecoreMajorVersion?: 0;
	editingHostEnvironmentDetails?: {
		cmEnvironmentId?: string;
	};
}

export interface ICreationEnvironmentDetails extends IEnvironmentDetails {
	linkEnvironmentToDefaultBranch?: boolean;
}

export interface IEnvironmentPayload {
	name?: string;
	sitecoreMajorVersion?: number;
	repositoryBranch?: string;
	deployOnCommit?: boolean;
}

export interface IEnvironmentVariableDetails {
	secret: boolean;
	name: string;
	value: string;
	target?: string;
}

export interface IEnvironmentVariablePayload {
	secret: boolean;
	value: string;
	target?: string;
}

export interface IGetEnvironmentsLimitation {
	prodCount: number;
	prodLimit: number;
	isProdLimitExceeds: boolean;
	nonProdCount: number;
	nonProdLimit: number;
	isNonProdLimitExceeds: boolean;
}

export interface IGetEnvironmentLogs {
	name: string;
	path: string;
	type: string;
	lastModified: string;
	size: number;
}

export interface IGetEnvironmentLogTypes {
	environmentId: string;
	name: string;
}

export interface IGetEnvironmentLogsDetail {
	environmentId: string;
	name: string;
	path: string;
	type: string;
	lastModified: string;
}

export interface IGraphQLRequest {
	operationName: string;
	variables: {};
	query: string;
}

export interface IGetEnvironmentSitesResponse {
	data: {
		solutionSites: IGetEnvironmentSitesSolutionSite[];
	};
}

export interface IGetEnvironmentSitesSolutionSite {
	id: string;
	displayName: string;
	name: string;
	thumbnailUrl: string;
	__typeName: string;
}

export interface IGetEnvironmentRenderingHostsAndSitesResponse {
	data: {
		languages: {
			edges: IEdge[];
		};
		renderingHosts: {
			children: {
				nodes: {
					url: {
						name: string;
						value: string;
					};
					name: string;
					itemId: string;
					path: string;
					template: {
						name: string;
						templateId: string;
					};
				}[];
			};
			name: string;
			itemId: string;
			path: string;
			template: {
				name: string;
				templateId: string;
			};
		};
		solutionSites: ISolutionSites[];
		sitesToRenderingHosts: {
			site: {
				name: string;
				itemId: string;
				path: string;
				template: {
					templateId: string;
					name: string;
				};
				children: {
					nodes: {
						name: string;
						itemId: string;
						path: string;
						template: {
							templateId: string;
							name: string;
						};
						children: {
							nodes: {
								name: string;
								itemId: string;
								path: string;
								template: {
									templateId: string;
									name: string;
								};
								children: {
									nodes: {
										name: string;
										itemId: string;
										path: string;
										template: {
											templateId: string;
											name: string;
										};
										renderingHost: {
											value: string;
										};
									}[];
								};
							}[];
						};
					}[];
				};
			};
		}[];
	};
}

export interface IStandardItemFields {
	name: string;
	itemId: string;
	path: string;
	template: {
		name: string;
		templateId: string;
	};
}

export interface IEnvironmentSite {
	organizationId: string;
	projectId: string;
	environmentId: string;
	name: string;
	itemId: string;
	renderingHost?: string;
	hostingProjectId?: string;
	environment: IGetEnvironmentResponse;
	host?: string;
	posMappings?: {
		name: string;
		language: string;
	}[];
}

export enum EnvironmentPublishMode {
	FULL,
	INCREMENTAL,
	SMART,
}

export interface IEnvironmentPublishSitePayload {
	languages?: string[];
	publishSiteMode: EnvironmentPublishMode;
}

export interface IGetEnvironmentPublishStatusResponse {
	data: {
		publishingStatus: {
			isDone: boolean;
			isFailed: boolean;
			processed: number;
			state: string;
		};
	};
}

export interface IEnvironmentPublishSiteResponse {
	data: {
		publishSite: {
			operationId: string;
		};
	};
}

export interface IGetEnvironmentDatabasesResponse {
	data: {
		databases: {
			edges: {
				node: {
					name: string;
				};
			}[];
		};
	};
}

export interface IGetEnvironmentLanguagesResponse {
	data: {
		languages: {
			edges: {
				node: {
					name: string;
				};
			}[];
		};
	};
}

export interface ISolutionSites {
	name: string;
	id: string;
	posMappings: {
		name: string;
		language: string;
	}[];
}

export interface IEdge {
	node: {
		name: string;
	};
}
export interface ISite extends ISolutionSites {
	hostedSite?: IGetHostingProject;
	edges?: IEdge[];
}

export interface IEdgeCredentials {
	apiKey: string;
	edgeUrl: string;
}

export interface IGetPreviewApiKeyResponse {
	data: {
		item: {
			itemId: string;
		};
	};
}

export type TPaginatedEnvironments = {
	pageNumber: number;
	pageSize: number;
	totalCount: number;
	data: IGetEnvironmentResponse[];
};

export enum EnvironmentRestartStatus {
	NotStarted = 0,
	Running = 1,
	Completed = 2,
	Failed = 3,
}

export interface IRestartEnvironmentResponse {
	type: string | null;
	title: string | null;
	status: number | null;
	detail: string | null;
	instance: string | null;
}

export interface IGetRestartEnvironmentResponse {
	id: string;
	environmentId: string;
	organizationId: string;
	restartStatus: EnvironmentRestartStatus;
	createdAt: string;
	createdBy: string;
}
