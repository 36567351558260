import {
	Text,
	Flex,
	Box,
	Spacer,
	Stack,
	Image,
	IconButton,
} from '@chakra-ui/react';
import { xmcloudLogo } from '../../core/icons';
import { mdiMenu } from '@mdi/js';
import { FC, useState } from 'react';
import { AppDrawer, Icon } from '../../../app/shared-components';
import { mainPaths } from '../../core/routes/paths';
import { handlePush } from '../../utils/helpers';
import UserMenu from './UserMenu';
import { AppSwitcher } from '../../../app/pages';
import { useGetOrganization } from '../../../app/services/organizations';
import NavigationVertical from '../components/NavigationVertical';

interface Props {
	lgSize: boolean;
}

export const TopBar: FC<Props> = ({ lgSize }) => {
	const [showSmLayoutDrawer, setShowSmLayoutDrawer] = useState(false);

	const { data: organizationsData } = useGetOrganization();
	const organizations = organizationsData?.data;

	return (
		<>
			<Flex data-testid="topbar">
				<Stack spacing="2" direction="row" alignItems="center">
					<AppSwitcher />
					<Image
						alt="XM Cloud Deploy logo"
						h="8"
						w="auto"
						p="1"
						src={xmcloudLogo}
						onClick={() => handlePush(mainPaths.home)}
						cursor="pointer"
					/>

					{lgSize && (
						//@ts-ignore
						<Text variant="subtle" data-testid="organization-name">
							{organizations?.displayName}
						</Text>
					)}
				</Stack>

				<Spacer />
				<Box display="flex" flexDirection="row" gap="4">
					{!lgSize && (
						<IconButton
							variant="ghost"
							size="sm"
							aria-label="burger-menu"
							icon={<Icon path={mdiMenu} />}
							onClick={() => setShowSmLayoutDrawer(true)}
						/>
					)}
					{/* //TODO: uncomment when help is ready */}
					{/* {lgSize && <HelpIconButton />} */}
					{lgSize && <UserMenu />}
				</Box>
			</Flex>
			{showSmLayoutDrawer && (
				<AppDrawer
					isOpen={showSmLayoutDrawer}
					onClose={() => setShowSmLayoutDrawer(false)}
					drawerHeader={<DrawerHeader />}
					drawerBodyOnClick={() => setShowSmLayoutDrawer(false)}
					showBottomCloseButton={false}
					dataTestId="navigation-drawer"
				>
					<NavigationVertical />
				</AppDrawer>
			)}
		</>
	);
};

const DrawerHeader: FC = () => (
	<Box gap={5} display="flex">
		<UserMenu />
		{/* //TODO: uncomment when help is ready */}
		{/* <HelpIconButton /> */}
	</Box>
);
