import { environment as envLabels } from '../../../../@xmcloud/core/messages/en';
import { FC, useState } from 'react';
import { useGetEditingSecret } from '../../../services/environments';
import { useParams } from 'react-router-dom';
import { ToggledTokenButton } from './ToggledTokenButton';

const { editingSecret, generateEditingSecretTooltip, generateEditingSecret } =
	envLabels;

const EditingSecret: FC = () => {
	const { environmentId } = useParams<{
		environmentId: string;
	}>();
	const [token, setToken] = useState('');
	const { refetch, isFetching } = useGetEditingSecret({
		environmentId: environmentId!,
		enabled: false,
	});

	return (
		<ToggledTokenButton
			{...{
				title: editingSecret,
				tooltip: generateEditingSecretTooltip,
				isLoading: isFetching,
				btnText: generateEditingSecret,
				onClick: () => {
					refetch().then((response) => {
						setToken(response?.data?.data || '');
					});
				},
				href: '',
				token,
			}}
		/>
	);
};

export default EditingSecret;
