import {
	Alert,
	AlertDescription,
	AlertIcon,
	Card,
	CardBody,
	IconButton,
	List,
	ListItem,
	ScaleFade,
	Stack,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import {
	commonCw,
	fifthStep,
	fourthStep,
} from '../../../../@xmcloud/core/messages/en';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { ESourceControl } from '../../../../@xmcloud/types';
import {
	ExtractGoTo,
	goToCreateProjectPaths,
	handleStatusText,
	isEHProjectType,
} from '../helpers';
import { HeadingLg, HeadingSm, Icon } from '../../../shared-components';
import { mdiRedo } from '@mdi/js';
import {
	ResponseStatusEnum,
	ResponseStatusTypes,
} from '../../../../@xmcloud/utils/helpers';
import { T } from '@transifex/react';
import { FooterBtnGroups } from '../components';
import { OLDReviewFinishCard } from './OLDReviewFinishCard';

const { IDLE, ERROR } = ResponseStatusEnum;
const { GitHub } = ESourceControl;

const {
	title,
	projectName,
	repoName,
	envName,
	prodEnv,
	autoDeploy,
	sourceControlConnection,
	repositoryText,
	chooseEnv,
	sourceControl,
	info1,
} = fifthStep;
const { availableEnvironments, fieldLabel1 } = fourthStep;
const { retry, startDeployment, editHost } = commonCw;

export const OLDReviewFinish: React.FC<{
	handleStartDeployment: (...arg: any) => any;
	isLoading: boolean;
	shouldRetry: boolean;
	repositoryCreationStatus?: ResponseStatusTypes;
	projectCreationStatus?: ResponseStatusTypes;
	environmentCreationStatus?: ResponseStatusTypes;
	deploymentCreationStatus?: ResponseStatusTypes;
	isFetchingADORepository?: boolean;
}> = ({
	handleStartDeployment,
	isLoading,
	shouldRetry,
	repositoryCreationStatus,
	projectCreationStatus = IDLE,
	environmentCreationStatus = IDLE,
	deploymentCreationStatus = IDLE,
	isFetchingADORepository,
}) => {
	const { prev, setActiveStep, values } = useCreateProject();
	const {
		name,
		repository,
		environmentName,
		tenantType,
		deployOnCommit,
		connectionName,
		projectType,
		journey,
		provider,
		repositoryRelativePath,
	} = values;
	const [showStatusElement, setShowStatusElement] = useState(false);

	function handlePrev() {
		prev(goToCreateProjectPaths[journey] as ExtractGoTo);
	}
	const isEhProjectType = isEHProjectType(projectType);

	const projectItems = useMemo(
		() => [{ title: projectName, subTitle: name }],
		[name],
	);

	const isGithubProvider = provider === GitHub;

	const sourceControlItem = useMemo(
		() => [
			{
				title: sourceControl,
				subTitle: isGithubProvider ? 'GitHub' : 'Azure DevOps',
			},
		],
		[isGithubProvider],
	);

	const repositoryItems = useMemo(
		() => [
			{
				title: sourceControlConnection,
				titleValue: isGithubProvider ? 'GitHub' : 'Azure DevOps',
				subTitle: connectionName,
			},
			{ title: repoName, subTitle: repository },
		],
		[isGithubProvider, connectionName, repository],
	);

	const environmentItems = useMemo(
		() => [
			{ title: envName, subTitle: environmentName },
			{ title: prodEnv, subTitle: tenantType ? 'Yes' : 'No' },
			{
				title: autoDeploy,
				subTitle: deployOnCommit ? 'Yes' : 'No',
			},
		],
		[environmentName, tenantType, deployOnCommit],
	);

	const editHostItems = useMemo(
		() => [
			{ title: availableEnvironments, subTitle: environmentName },
			{ title: fieldLabel1, subTitle: repositoryRelativePath },
		],
		[environmentName, repositoryRelativePath],
	);

	return (
		<Stack>
			<HeadingLg text={title} />
			<Stack pt="6" flexDirection={['column', 'column', 'row', 'row']}>
				<Stack gap="4" w={['100%', '100%', '55%', '55%']}>
					<OLDReviewFinishCard
						{...{
							setActiveStep,
							items: projectItems,
							stepNumber: 1,
							projectType,
						}}
						key={'1'}
					/>
					{Boolean(provider) && (
						<OLDReviewFinishCard
							{...{
								setActiveStep,
								items: sourceControlItem,
								stepNumber: 2,
								projectType,
							}}
							key={'2'}
						/>
					)}
					{Boolean(repository) && (
						<OLDReviewFinishCard
							{...{
								setActiveStep,
								items: repositoryItems,
								stepNumber: 3,
								headerText: repositoryText,
								projectType,
							}}
							key={'3'}
						/>
					)}
					{isEhProjectType ? (
						<OLDReviewFinishCard
							{...{
								setActiveStep,
								items: editHostItems,
								stepNumber: 4,
								headerText: editHost,
								projectType,
							}}
							key={'4'}
						/>
					) : (
						<OLDReviewFinishCard
							{...{
								setActiveStep,
								items: environmentItems,
								stepNumber: 4,
								headerText: chooseEnv,
								projectType,
							}}
							key={'5'}
						/>
					)}
				</Stack>
				<Stack
					pt={['3', '3', '9%', '9%']}
					w={['100%', '100%', '45%', '45%']}
				>
					<ScaleFade initialScale={0.9} in={showStatusElement}>
						<Card variant="filled">
							<CardBody>
								<List spacing="5">
									<HeadingSm text="Project creation status" />
									{!!repositoryCreationStatus && (
										<StatusListItem
											handleStartDeployment={
												handleStartDeployment
											}
											status={repositoryCreationStatus}
											text={
												isFetchingADORepository
													? info1
													: handleStatusText(
															'Repository',
														)[
															repositoryCreationStatus
														]
											}
											key="repository"
										/>
									)}
									<StatusListItem
										handleStartDeployment={
											handleStartDeployment
										}
										status={projectCreationStatus}
										text={
											handleStatusText('Project')[
												projectCreationStatus
											]
										}
										key="project"
									/>
									<StatusListItem
										handleStartDeployment={
											handleStartDeployment
										}
										status={environmentCreationStatus}
										text={
											handleStatusText('Environment')[
												environmentCreationStatus
											]
										}
										key="environment"
									/>
									<StatusListItem
										handleStartDeployment={
											handleStartDeployment
										}
										status={deploymentCreationStatus}
										text={
											handleStatusText('Deployment')[
												deploymentCreationStatus
											]
										}
										key="deployment"
									/>
								</List>
							</CardBody>
						</Card>
					</ScaleFade>
				</Stack>
			</Stack>
			<FooterBtnGroups
				{...{
					onNextClick: () => {
						setShowStatusElement(true);
						handleStartDeployment(values);
					},
					onPreviousClick: handlePrev,
					isLoading: isLoading,
					py: 5,
					rightBtnText: shouldRetry ? retry : startDeployment,
				}}
			/>
		</Stack>
	);
};

const StatusListItem: React.FC<{
	handleStartDeployment: (...arg: any) => any;
	status: ResponseStatusTypes;
	text: string;
}> = ({ status, text, handleStartDeployment }) => {
	const { values } = useCreateProject();
	const isIdleStatus = status === IDLE;
	const isErrorStatus = status === ERROR;
	return (
		<ListItem>
			<Alert
				status={isIdleStatus ? undefined : status}
				{...(isIdleStatus && { colorScheme: 'neutral' })}
				alignItems="center"
			>
				<AlertIcon />
				<>
					<AlertDescription>
						<T _str={text} />
					</AlertDescription>
					{isErrorStatus && (
						<IconButton
							icon={<Icon path={mdiRedo} />}
							aria-label="redo-btn"
							variant="ghost"
							size="xs"
							onClick={() => handleStartDeployment(values)}
						/>
					)}
				</>
			</Alert>
		</ListItem>
	);
};
