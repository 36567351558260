import { useCallback, useState } from 'react';
import { ICreationEnvironmentDetails } from '../../../app/models/environmentModel';
import { useDeploymentDeployMutation } from '../../../app/services/deployments';
import {
	useCreateEnvironmentDeploymentMutation,
	useUpdateEnvironmentMutation,
} from '../../../app/services/environments';
import { useCreateProjectEnvironmentMutation } from '../../../app/services/projects';
import { goTo } from '../../core/routes/paths';
import { handlePush } from '../../utils/helpers';

const {
	deploymentLogs: goToDeploymentLogs,
	allDeployments: goToAllDeployments,
} = goTo;

export function useEnvironmentCreation({
	shouldGotoDeploymentPage = false,
	shouldEditEnvironment = false,
	shouldGotoDeploymentsPage = false,
	onCmProjectCreationSuccess,
}: {
	shouldGotoDeploymentPage?: boolean;
	shouldEditEnvironment?: boolean;
	onCmProjectCreationSuccess?: (id: string) => void;
	shouldGotoDeploymentsPage?: boolean;
}) {
	const [currentEnvironmentId, setCurrentEnvironmentId] = useState('');
	const [currentDeploymentId, setCurrentDeploymentId] = useState('');
	const {
		mutate: createEnvironment,
		isLoading: createEnvironmentIsLoading,
		status: createEnvironmentStatus,
	} = useCreateProjectEnvironmentMutation();
	const {
		mutate: createDeployment,
		isLoading: createDeploymentIsLoading,
		status: createDeploymentStatus,
	} = useCreateEnvironmentDeploymentMutation();
	const {
		mutate: deploymentDeploy,
		isLoading: deploymentDeployIsLoading,
		status: deploymentDeployStatus,
	} = useDeploymentDeployMutation();

	const {
		mutate: editEnvironment,
		isLoading: isLoadingEditEnvironment,
		status: editEnvironmentStatus,
	} = useUpdateEnvironmentMutation({
		id: currentEnvironmentId,
	});

	//3rd step: trigger deployment after deployment creation
	const onDeploymentDeploy = useCallback(
		(projectId: string, environmentId: string, deploymentId: string) => {
			deploymentDeploy(
				{ deploymentId },
				{
					onSuccess: () => {
						if (shouldGotoDeploymentPage) {
							handlePush(
								goToDeploymentLogs(
									projectId,
									environmentId,
									deploymentId,
								),
							);
						}
						if (shouldGotoDeploymentsPage) {
							handlePush(goToAllDeployments);
						}
						onCmProjectCreationSuccess &&
							onCmProjectCreationSuccess(environmentId);
					},
				},
			);
		},
		[
			deploymentDeploy,
			onCmProjectCreationSuccess,
			shouldGotoDeploymentPage,
			shouldGotoDeploymentsPage,
		],
	);

	//2nd step: creation of deployment after environment creation
	const onDeploymentCreation = useCallback(
		(projectId: string, environmentId: string) => {
			createDeployment(
				{ environmentId },
				{
					onSuccess: (res) => {
						const deploymentId = res.data?.id || '';
						setCurrentDeploymentId(deploymentId);

						onDeploymentDeploy(
							projectId,
							environmentId,
							deploymentId,
						);
					},
				},
			);
		},
		[createDeployment, onDeploymentDeploy],
	);

	//edit environment for bring your own code step
	const onEditEnvironment = useCallback(
		(
			repositoryBranch: string,
			projectId: string,
			environmentId: string,
		) => {
			editEnvironment(
				{
					repositoryBranch,
					environmentId,
				},
				{
					onSuccess: () => {
						onDeploymentCreation(projectId, environmentId);
					},
				},
			);
		},
		[editEnvironment, onDeploymentCreation],
	);

	//1st step: creation of environment

	const onEnvironmentCreation = (
		projectId: string,
		environment: ICreationEnvironmentDetails,
	) => {
		const repositoryBranch = environment.repositoryBranch || '';
		environment.repositoryBranch = shouldEditEnvironment
			? ''
			: repositoryBranch;
		createEnvironment(
			{ projectId, environment },
			{
				onSuccess: (res) => {
					const newEnvironment = res.data;
					setCurrentEnvironmentId(newEnvironment.id);
					setCurrentEnvironmentId(newEnvironment.id);

					if (shouldEditEnvironment) {
						onEditEnvironment(
							repositoryBranch,
							projectId,
							newEnvironment.id,
						);
						return;
					}
					onDeploymentCreation(projectId, newEnvironment.id);
				},
			},
		);
	};

	const isLoading =
		createEnvironmentIsLoading ||
		createDeploymentIsLoading ||
		deploymentDeployIsLoading ||
		isLoadingEditEnvironment;

	return {
		isLoading,
		onDeploymentDeploy,
		onEnvironmentCreation,
		onDeploymentCreation,
		onEditEnvironment,
		shouldReTryEnvironmentCreation: createEnvironmentStatus === 'error',
		shouldReTryDeploymentCreation: createDeploymentStatus === 'error',
		shouldReTryDeploymentDeploy: deploymentDeployStatus === 'error',
		shouldReTryEditEnvironment: editEnvironmentStatus === 'error',
		currentEnvironmentId,
		currentDeploymentId,
		createEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
		editEnvironmentStatus,
	};
}
