import { MenuItem, MenuList, Stack, Text, Wrap } from '@chakra-ui/react';
import { mdiLinkVariant, mdiOpenInNew, mdiSourceBranch } from '@mdi/js';
import { createColumnHelper } from '@tanstack/react-table';
import { t } from '@transifex/native';
import { useRef, useState } from 'react';
import {
	app,
	environment,
	environments,
} from '../../../../@xmcloud/core/messages/en';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { handlePush, handleTo } from '../../../../@xmcloud/utils/helpers';
import config from '../../../config/config';
import { useFeature } from '../../../feature-flag/features';
import { IGetEnvironmentResponse } from '../../../models/environmentModel';
import { useDeploymentCreateAndDeploy } from '../../../services/deploymentCreateDeploy';
import { Icon, IconText, Link, RowActions } from '../../../shared-components';
import DeleteEnvironment from '../components/DeleteEnvironment';
import RestartMenuItem from '../components/RestartMenuItem';

const { autoDeploy, buildAndDeploy, variables, openDashboard } = environments;
const { contextIdLive, contextIdPreview } = environment;
const { name, id } = app;

const columnHelper = createColumnHelper<IGetEnvironmentResponse>();

export const useEnvironmentsTableColumns = () => {
	const [editModal, setEditModal] = useState(false);
	const environmenRef = useRef({} as IGetEnvironmentResponse);
	const createProjectV2 = useFeature('CreateProjectV2');

	const [deploymentCreateAndDeploy, loading] = useDeploymentCreateAndDeploy();

	return [
		columnHelper.accessor('name', {
			header: () => t(name),
			...(!createProjectV2 && {
				maxSize: 72,
			}),
			cell: (info) => {
				const {
					id,
					name,
					projectId,
					repositoryBranch,
					deployOnCommit,
				} = info.row.original;
				return (
					<Stack>
						<Link
							text={name}
							to={handleTo(goTo.deployments(projectId, id))}
							whiteSpace="wrap"
							noOfLines={2}
							maxW="lg"
						/>
						<Wrap spacingX="3" spacingY="1">
							{repositoryBranch ? (
								<IconText
									IconName={mdiLinkVariant}
									text={repositoryBranch}
									iconColor="neutral"
									iconSize={4}
									fontSize="sm"
									textColor="chakra-subtle-text"
									noOfLines={2}
								/>
							) : null}
							{deployOnCommit ? (
								<IconText
									IconName={mdiSourceBranch}
									text={autoDeploy}
									iconColor="neutral"
									iconSize={4}
									fontSize="sm"
									textColor="chakra-subtle-text"
								/>
							) : null}
						</Wrap>
					</Stack>
				);
			},
		}),
		columnHelper.accessor('id', {
			header: () => t(id),
			cell: (info) => info.getValue(),
		}),
		...(createProjectV2
			? []
			: [
					columnHelper.accessor((row) => row.previewContextId, {
						id: 'previewContextId',
						cell: (info) => info.getValue(),
						header: () => contextIdPreview,
					}),
				]),
		...(createProjectV2
			? []
			: [
					columnHelper.accessor((row) => row.liveContextId, {
						id: 'liveContextId',
						cell: (info) => info.getValue(),
						header: () => contextIdLive,
					}),
				]),
		columnHelper.accessor('lastUpdatedAt', {
			header: () => 'Date created',
			cell: (info) => getReadableDate(info.renderValue() as string),
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original } }) => (
				<>
					<RowActions
						ariaLabel="environments-table-action-menu"
						isLoading={
							loading &&
							environmenRef.current?.id === original?.id
						}
						data-testid="environments-table-action-menu"
					>
						<MenuList>
							<MenuItem
								data-behavior-analytics-id="build-and-deploy-button"
								data-testid="build-and-deploy-button"
								onClick={() => {
									environmenRef.current = original;
									const environment = original;
									deploymentCreateAndDeploy({
										environmentId: environment?.id,
										onSuccess: (
											deploymentId: string,
											data,
										) => {
											if (data?.data) {
												handlePush(
													goTo.deployments(
														environment?.projectId,
														environment?.id,
													),
												);

												return;
											}
											handlePush(
												goTo.deploymentLogs(
													environment?.projectId,
													environment?.id,
													deploymentId,
												),
											);
										},
									});
								}}
							>
								{t(buildAndDeploy)}
							</MenuItem>
							<MenuItem
								onClick={() => {
									const environment = original;
									handlePush(
										goTo.variables(
											environment?.projectId,
											environment?.id,
										),
									);
								}}
							>
								{t(variables)}
							</MenuItem>
							<MenuItem
								onClick={() => {
									const environment = original;
									environmenRef.current = environment;
									setEditModal(true);
								}}
							>
								{t(app.delete)}
							</MenuItem>
							<MenuItem
								onClick={() => {
									const environment = original;
									window.open(
										config.externalLinks.xm_cloud_dashboard(
											environment.host,
										),
									);
								}}
								justifyContent="space-between"
								_hover={{
									'.new-window-icon': {
										opacity: 1,
										transition: '0.7s',
									},
								}}
							>
								<Text>{t(openDashboard)}</Text>
								<Icon
									path={mdiOpenInNew}
									boxSize={5}
									className="new-window-icon"
									opacity={0}
									color="chakra-placeholder-color"
								/>
							</MenuItem>
							<RestartMenuItem
								environmentId={original.id}
								key={original.id}
							/>
						</MenuList>
					</RowActions>
					{editModal && (
						<DeleteEnvironment
							{...{
								openModal: editModal,
								setOpenModal: setEditModal,
								environmentDetails: environmenRef.current,
							}}
						/>
					)}
				</>
			),
			maxSize: 0,
		}),
	];
};
