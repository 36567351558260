import { Stack, Text } from '@chakra-ui/react';
import { secondStep } from '../../../../@xmcloud/core/messages/en';
import { FooterBtnGroups, Microcopy } from '../components';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import {
	InputFormControlWithTransformationMessage,
	SelectFormControl,
	XMCSButton,
} from '../../../shared-components';
import {
	useGetRepositoryIntegrations,
	useRepositoryIntegrateState,
} from '../../../services/repositories';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { ESourceControl, SourceControlType } from '../../../../@xmcloud/types';
import {
	ACCOUNT_TYPE,
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	CONNECTION_NAME,
	ExtractGoTo,
	goToCreateProjectPaths,
	PROVIDER,
	REPOSITORY,
	REPO_BRANCH,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../helpers';
import { useMemo, useEffect } from 'react';
import {
	adoIntegrationUrl,
	githubIntegrationUrl,
	NEW_SOURCE_CONTROL_ACCOUNT,
} from '../../../../@xmcloud/utils/constants';
import { useQueryClient } from 'react-query';
import config from '../../../config/config';
import { OLDADOFields } from './OLDADOFields';
import { OLDSelectGithubRepositories } from './OLDSelectGithubRepositories';

const { info1, info2, chooseAcccount, nameYourRepo, connectTo } = secondStep;
const { ADO, GitHub } = ESourceControl;

export const OLDExistingConnection: React.FC<{
	handleNext: (...args: any) => any;
	isLoading: boolean;
	errorMsg: string;
	isIntegrationIdValidationValid: boolean;
	isLoadingIntegrationId: boolean;
}> = ({
	handleNext,
	isLoading,
	errorMsg,
	isIntegrationIdValidationValid,
	isLoadingIntegrationId,
}) => {
	const {
		values,
		errors,
		handleChange,
		touched,
		setFieldTouched,
		prev,
		setValues,
		setTouched,
		setErrors,
		setFieldValue,
	} = useCreateProject();
	const queryClient = useQueryClient();

	const sourceControlIntegrationId = values.sourceControlIntegrationId;

	const { data, isLoading: isFetchingIntegrationLoading } =
		useGetRepositoryIntegrations();

	const repositoryIntegrations = useMemo(
		() => data?.data.filter((i) => i.provider === values.provider) || [],
		[data, values.provider],
	);

	const integrationsOptions =
		repositoryIntegrations.map(({ account, id }) => ({
			label: account,
			value: id,
		})) || [];

	const handlePrev = () => {
		prev(goToCreateProjectPaths[values.journey] as ExtractGoTo);
	};

	const isGithubProvider = values.provider === GitHub;
	const isADOProvider = values.provider === ADO;
	const isOwnCodeTemplate = values.isUseOwnCode;

	const showRepositorySelect =
		isOwnCodeTemplate &&
		sourceControlIntegrationId &&
		isIntegrationIdValidationValid;

	const showAdoFiels =
		isADOProvider &&
		isIntegrationIdValidationValid &&
		sourceControlIntegrationId;

	const hasAdoProjectName = values.adoProjectName && isADOProvider;

	const showRepoInputField = !isOwnCodeTemplate
		? hasAdoProjectName || isGithubProvider
			? true
			: false
		: false;
	const integrationLabel = chooseAcccount(
		isGithubProvider ? 'GitHub' : isADOProvider ? 'Azure DevOps' : 'a',
	);

	const {
		refetch: fetchIntegrateState,
		isFetching: isFetchingIntegrateState,
	} = useRepositoryIntegrateState({ enabled: false });

	useEffect(() => {
		const channel = new BroadcastChannel(NEW_SOURCE_CONTROL_ACCOUNT);

		channel.addEventListener('message', (e) => {
			if (e.origin !== window.location.origin) return;
			queryClient.invalidateQueries(
				config.repository.validate_integration_V2.queryKey(e.data.id),
			);
			setTouched({});
			setErrors({});
			setValues((prev: FormikValues) => ({
				...prev,
				[CONNECTION_NAME]: e.data.account,
				[SOURCE_CONTROL_INTEGRATION_ID]: e.data.id,
				[PROVIDER]: e.data.provider,
				[ACCOUNT_TYPE]: e.data.accountType,
			}));
		});

		return () => {
			channel.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setValues]);

	return (
		<Stack pt="1">
			<Stack direction={['column', 'column', 'row', 'row']}>
				<Stack direction="column">
					<SelectFormControl
						{...{
							isInvalid: Boolean(
								(errors.sourceControlIntegrationId &&
									touched.sourceControlIntegrationId) ||
									!isIntegrationIdValidationValid,
							),
							isLoading:
								isFetchingIntegrationLoading ||
								isLoadingIntegrationId,
							options: integrationsOptions,
							onChange: (e: any) => {
								const integration =
									repositoryIntegrations?.find(
										(i) => i.id === e.value,
									);
								if (!integration?.provider) return;
								setValues((prev: FormikValues) => ({
									...prev,
									[REPOSITORY]: '',
									[REPO_BRANCH]: '',
									[SOURCE_CONTROL_INTEGRATION_ID]:
										e.value || '',
									[CONNECTION_NAME]: integration?.account,
									[PROVIDER]:
										integration.provider as SourceControlType,
									[ACCOUNT_TYPE]:
										integration?.accountType || 0,
									[ADO_ORGANIZATION]: '',
									[ADO_PROJECT_NAME]: '',
								}));
							},
							label: integrationLabel,
							name: SOURCE_CONTROL_INTEGRATION_ID,
							currentValue: values.sourceControlIntegrationId,
							error:
								errors.sourceControlIntegrationId || errorMsg,
							maxW: 'xs',
							pt: 2,
							onFocus: () =>
								setFieldTouched(
									SOURCE_CONTROL_INTEGRATION_ID,
									true,
								),
							isRequired: true,
						}}
					/>
					<Microcopy text={info1} />
				</Stack>
				<Text pt={[2, 2, 12, 12]} pr="5" ml="-2">
					or
				</Text>
				<Stack pt={[2, 2, 10, 10]}>
					<XMCSButton
						text={connectTo}
						isLoading={isFetchingIntegrateState}
						variant="outline"
						colorScheme="primary"
						onClick={() => {
							if (values.provider) {
								fetchIntegrateState().then(({ data }) => {
									const state = data?.data ?? '';
									window.open(
										isGithubProvider
											? githubIntegrationUrl(state)
											: isADOProvider
												? adoIntegrationUrl(state)
												: '#',
									);
								});
							}
						}}
					/>
				</Stack>
			</Stack>
			{showAdoFiels && (
				<OLDADOFields
					{...{
						isIntegrationIdValidationLoading: isLoading,
						showRepositorySelect: !!showRepositorySelect,
					}}
				/>
			)}
			{showRepoInputField && (
				<InputFormControlWithTransformationMessage
					{...{
						isInvalid: Boolean(
							errors.repository && touched.repository,
						),
						label: nameYourRepo,
						name: REPOSITORY,
						value: values.repository,
						pt: 3,
						maxW: 'xs',
						onChange: handleChange,
						error: errors.repository,
						onFocus: () => setFieldTouched(REPOSITORY, true),
						isRequired: true,
						setFieldValue,
					}}
				/>
			)}
			{showRepoInputField && <Microcopy text={info2} maxW="xs" />}
			{showRepositorySelect && isGithubProvider && (
				<OLDSelectGithubRepositories
					{...{
						isIntegrationIdValidationLoading: isLoading,
					}}
				/>
			)}
			<FooterBtnGroups
				{...{
					onNextClick: handleNext,
					onPreviousClick: handlePrev,
					isLoading,
					py: 5,
				}}
			/>
		</Stack>
	);
};
