import { useCallback, useState } from 'react';
import {
	useCreateEnvironmentDeploymentMutation,
	useUpdateEnvironmentMutation,
} from '../../../services/environments';
import { useCreateProjectEnvironmentMutation } from '../../../services/projects';
import { ICreationEnvironmentDetails } from '../../../models/environmentModel';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { useDeploymentDeployMutation } from '../../../services/deployments';

export function useOLDEnvironmentCreation({
	setOpenModal,
	shouldHandlePush = false,
	shouldEditEnvironment = false,
}: {
	setOpenModal?: (o: boolean) => void;
	shouldHandlePush?: boolean;
	shouldEditEnvironment?: boolean;
}) {
	const [currentEnvironmentId, setCurrentEnvironmentId] = useState('');
	const [currentDeploymentId, setCurrentDeploymentId] = useState('');
	const {
		mutate: createEnvironment,
		isLoading: createEnvironmentIsLoading,
		status: createEnvironmentStatus,
	} = useCreateProjectEnvironmentMutation();
	const {
		mutate: createDeployment,
		isLoading: createDeploymentIsLoading,
		status: createDeploymentStatus,
	} = useCreateEnvironmentDeploymentMutation();
	const {
		mutate: deploymentDeploy,
		isLoading: deploymentDeployIsLoading,
		status: deploymentDeployStatus,
	} = useDeploymentDeployMutation();

	const {
		mutate: editEnvironment,
		isLoading: isLoadingEditEnvironment,
		status: editEnvironmentStatus,
	} = useUpdateEnvironmentMutation({
		id: currentEnvironmentId,
	});

	//3rd step: trigger deployment after deployment creation
	const onDeploymentDeploy = useCallback(
		(projectId: string, environmentId: string, deploymentId: string) => {
			deploymentDeploy(
				{ deploymentId },
				{
					onSuccess: () => {
						setOpenModal && setOpenModal(false);
						if (shouldHandlePush) {
							handlePush(
								goTo.deploymentLogs(
									projectId,
									environmentId,
									deploymentId,
								),
							);
						}
					},
				},
			);
		},
		[deploymentDeploy, setOpenModal, shouldHandlePush],
	);

	//2nd step: creation of deployment after environment creation
	const onDeploymentCreation = useCallback(
		(projectId: string, environmentId: string) => {
			createDeployment(
				{ environmentId },
				{
					onSuccess: (res) => {
						const deploymentId = res.data?.id || '';
						setCurrentDeploymentId(deploymentId);
						onDeploymentDeploy(
							projectId,
							environmentId,
							deploymentId,
						);
					},
				},
			);
		},
		[createDeployment, onDeploymentDeploy],
	);

	//edit environment for bring your own code step
	const onEditEnvironment = useCallback(
		(
			repositoryBranch: string,
			projectId: string,
			environmentId: string,
		) => {
			editEnvironment(
				{
					repositoryBranch,
					environmentId,
				},
				{
					onSuccess: () => {
						onDeploymentCreation(projectId, environmentId);
					},
				},
			);
		},
		[editEnvironment, onDeploymentCreation],
	);

	//1st step: creation of environment
	const onEnvironmentCreation = useCallback(
		(
			projectId: string,
			environment: ICreationEnvironmentDetails,
			shouldCreateDeployment = true,
		) => {
			const repositoryBranch = environment.repositoryBranch || '';
			environment.repositoryBranch = shouldEditEnvironment
				? ''
				: repositoryBranch;
			createEnvironment(
				{ projectId, environment },
				{
					onSuccess: (res) => {
						const newEnvironment = res.data;
						setCurrentEnvironmentId(newEnvironment.id);
						if (!shouldCreateDeployment) {
							setOpenModal && setOpenModal(false);
							if (shouldHandlePush) {
								handlePush(goTo.environments(projectId));
							}
							return;
						}
						if (shouldEditEnvironment) {
							onEditEnvironment(
								repositoryBranch,
								projectId,
								newEnvironment.id,
							);
							return;
						}
						onDeploymentCreation(projectId, newEnvironment.id);
					},
				},
			);
		},
		[
			createEnvironment,
			onDeploymentCreation,
			onEditEnvironment,
			setOpenModal,
			shouldEditEnvironment,
			shouldHandlePush,
		],
	);

	const isLoading =
		createEnvironmentIsLoading ||
		createDeploymentIsLoading ||
		deploymentDeployIsLoading ||
		isLoadingEditEnvironment;

	return {
		isLoading,
		onDeploymentDeploy,
		onEnvironmentCreation,
		onDeploymentCreation,
		onEditEnvironment,
		shouldReTryEnvironmentCreation: createEnvironmentStatus === 'error',
		shouldReTryDeploymentCreation: createDeploymentStatus === 'error',
		shouldReTryDeploymentDeploy: deploymentDeployStatus === 'error',
		shouldReTryEditEnvironment: editEnvironmentStatus === 'error',
		currentEnvironmentId,
		currentDeploymentId,
		createEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
		editEnvironmentStatus,
	};
}
