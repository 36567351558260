import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { IContentTreeNode } from '../models/contentTreeNode';

/**
 * Get transfer details
 * @param param0 transfer id
 * @returns IContentTreeNode
 */
export const useGetContent = ({ id }: { id: string }) => {
	const { scope, url, queryKey } = config.transfers.transferContent;
	return useAuthQuery(
		queryKey(id),
		(axiosInstance) => {
			return axiosInstance?.get<IContentTreeNode>(url(id));
		},
		{ staleTime: 5000 },
		scope,
	);
};
