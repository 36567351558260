import { useCallback, useState } from 'react';
import { useCreateProject } from '../context/CreateProjectContext';
import { connections, repository, secondStep } from '../../core/messages/en';
import { useValidateRepositoryName } from '../../../app/services/repositories';
import {
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	CM_ADO_ORGANIZATION,
	CM_ADO_PROJECT_NAME,
	CM_REPOSITORY,
	EH_ADO_ORGANIZATION,
	EH_ADO_PROJECT_NAME,
	EH_REPOSITORY,
	ExtractGoTo,
	goToCreateProjectPaths,
	REPOSITORY,
	SOURCE_CONTROL_INTEGRATION_ID,
	TADOOrganization,
	TADOProjectName,
	TRepository,
} from '../../../app/pages/create-project/helpers';
import { AxiosError } from 'axios';

const { requiredRepositoryName } = repository;
const { requiredConnection } = connections;

const { orgRequired, projectNameRequired } = secondStep;

export const useSetupRepoValidation = ({
	isIntegrationIdValidationValid,
	integrationValidationErrorMsg,
}: {
	isIntegrationIdValidationValid: boolean;
	integrationValidationErrorMsg: string;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const {
		values,
		errors,
		setFieldTouched,
		isDualJourney,
		setFieldError,
		next,
		isADOProvider,
	} = useCreateProject();

	const sourceControlIntegrationId = values.sourceControlIntegrationId;
	const isMonoRepo = values.isMonoRepo;
	const repositoryList: TRepository[] =
		isDualJourney && !isMonoRepo
			? [CM_REPOSITORY, EH_REPOSITORY]
			: [REPOSITORY];

	const adoOrgList: TADOOrganization[] =
		isDualJourney && !isMonoRepo
			? [CM_ADO_ORGANIZATION, EH_ADO_ORGANIZATION]
			: [ADO_ORGANIZATION];

	const adoProjectList: TADOProjectName[] =
		isDualJourney && !isMonoRepo
			? [CM_ADO_PROJECT_NAME, EH_ADO_PROJECT_NAME]
			: [ADO_PROJECT_NAME];

	const { validateRepoName } = useValidateRepositoryName(
		sourceControlIntegrationId,
	);

	const handleNext = useCallback(async () => {
		if (!sourceControlIntegrationId) {
			setFieldTouched(SOURCE_CONTROL_INTEGRATION_ID, true);
			setFieldError(SOURCE_CONTROL_INTEGRATION_ID, requiredConnection);
			return;
		}
		if (errors.sourceControlIntegrationId) return;
		if (!isIntegrationIdValidationValid) {
			setFieldError(
				SOURCE_CONTROL_INTEGRATION_ID,
				integrationValidationErrorMsg,
			);
			return;
		}
		if (isADOProvider) {
			for (const org of adoOrgList) {
				if (!values[org]) {
					setFieldTouched(org, true);
					setFieldError(org, orgRequired);
					return;
				}
			}
			for (const project of adoProjectList) {
				if (!values[project]) {
					setFieldTouched(project, true);
					setFieldError(project, projectNameRequired);
					return;
				}
			}
		}
		for (const repo of repositoryList) {
			if (!values[repo]) {
				setFieldTouched(repo, true);
				setFieldError(repo, requiredRepositoryName);
				return;
			}
			if (errors[repo]) return;
			if (values.isUseOwnCode) {
				next(goToCreateProjectPaths[values.journey] as ExtractGoTo);
				return;
			}
		}
		setIsLoading(true);
		for (const repo of repositoryList) {
			try {
				const isCm = repo === CM_REPOSITORY;
				const isEh = repo === EH_REPOSITORY;
				const isDefaultRepo = repo === REPOSITORY;

				const cmAdoRepoRelativePath = `${values.cmAdoOrganization}/${values.cmAdoProjectName}/${values.cmRepository}`;
				const ehAdoRepoRelativePath = `${values.ehAdoOrganization}/${values.ehAdoProjectName}/${values.ehRepository}`;
				const adoRepoRelativePath = `${values.adoOrganization}/${values.adoProjectName}/${values.repository}`;
				const currentRepo =
					isADOProvider && isCm
						? cmAdoRepoRelativePath
						: isADOProvider && isEh
							? ehAdoRepoRelativePath
							: isADOProvider && isDefaultRepo
								? adoRepoRelativePath
								: values[repo];

				const data = await validateRepoName(currentRepo);
				const isValidRepositoryName = data?.isValid;
				const messageRepositoryName = data?.message as string;
				if (!isValidRepositoryName) {
					setFieldError(repo, messageRepositoryName);
					setIsLoading(false);
					return;
				}
			} catch (e) {
				const error = e as AxiosError;
				//@ts-ignore
				setFieldError(repo, error.response?.data?.detail);
				setIsLoading(false);
				return;
			}
		}
		setIsLoading(false);

		next(goToCreateProjectPaths[values.journey] as ExtractGoTo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		sourceControlIntegrationId,
		errors,
		isIntegrationIdValidationValid,
		next,
		values,
		setFieldTouched,
		setFieldError,
		integrationValidationErrorMsg,
	]);

	return {
		handleNext,
		isLoading,
	};
};
