import { useCallback } from 'react';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { FormikErrors } from 'formik';
import { connections, repository } from '../../../../@xmcloud/core/messages/en';
import {
	useValidateRepositoryNameV2,
	useValidateSourceControlIntegrationV2,
} from '../../../services/repositories';
import {
	ExtractGoTo,
	goToCreateProjectPaths,
	REPOSITORY,
	SOURCE_CONTROL_INTEGRATION_ID,
} from '../helpers';

export const useOLDGithubValidation = (
	values: FormikValues,
	errors: FormikErrors<FormikValues>,
	setFieldTouched: any,
	setFieldError: any,
	next: (p: ExtractGoTo) => void,
) => {
	const { refetch: validateRepositoryName, isFetching: isLoadingRepoName } =
		useValidateRepositoryNameV2(
			values.repository,
			values.sourceControlIntegrationId,
		);

	const {
		isFetching: isSourceControlIntegrationLoading,
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
	} = useValidateSourceControlIntegrationV2({
		integrationId: values.sourceControlIntegrationId,
		enabled: !!values.sourceControlIntegrationId,
	});

	const isLoading = isLoadingRepoName || isSourceControlIntegrationLoading;

	const handleNext = useCallback(async () => {
		if (!values.sourceControlIntegrationId || !values.repository) {
			if (!values.sourceControlIntegrationId) {
				setFieldTouched(SOURCE_CONTROL_INTEGRATION_ID, true);
				setFieldError(
					SOURCE_CONTROL_INTEGRATION_ID,
					connections.requiredConnection,
				);
			}
			if (!values.repository) {
				setFieldTouched(REPOSITORY, true);
				setFieldError(REPOSITORY, repository.requiredRepositoryName);
			}
			return;
		}

		if (errors.sourceControlIntegrationId || errors.repository) return;
		if (values.isUseOwnCode) {
			next(goToCreateProjectPaths[values.journey] as ExtractGoTo);
			return;
		}

		if (!isIntegrationIdValidationValid) {
			setFieldError(
				SOURCE_CONTROL_INTEGRATION_ID,
				integrationValidationErrorMsg,
			);
			return;
		}

		const { data: dataRepositoryName } = await validateRepositoryName();
		const isValidRepositoryName = dataRepositoryName?.data.isValid;
		const messageRepositoryName = dataRepositoryName?.data
			.message as string;

		if (!isValidRepositoryName) {
			setFieldError(REPOSITORY, messageRepositoryName);
			return;
		}

		next(goToCreateProjectPaths[values.journey] as ExtractGoTo);
	}, [
		values.sourceControlIntegrationId,
		values.repository,
		values.isUseOwnCode,
		values.journey,
		errors.sourceControlIntegrationId,
		errors.repository,
		isIntegrationIdValidationValid,
		validateRepositoryName,
		next,
		setFieldTouched,
		setFieldError,
		integrationValidationErrorMsg,
	]);

	return {
		handleNext,
		isLoading,
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
		isSourceControlIntegrationLoading,
	};
};
