import { useMemo } from 'react';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import {
	useGetADOOrganizations,
	useGetADOProfile,
	useGetADOProjects,
	useGetADORepositories,
} from '../../../services/adoApi';
import {
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	REPO_BRANCH,
	REPOSITORY,
	REPOSITORY_ID,
	TOptions,
} from '../helpers';
import { FormFieldLoading } from '../components/index';
import { SelectFormControl } from '../../../shared-components/index';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { secondStep } from '../../../../@xmcloud/core/messages/en';
import { ScaleFade } from '@chakra-ui/react';
import { OLDSelectRepositories } from './OLDSelectRepositories.tsx';

export const OLDADOFields: React.FC<{
	isIntegrationIdValidationLoading: boolean;
	showRepositorySelect: boolean;
}> = ({ isIntegrationIdValidationLoading, showRepositorySelect }) => {
	const { values, errors, touched, setValues, setFieldTouched } =
		useCreateProject();

	const shouldCallApi =
		Boolean(values.sourceControlIntegrationId) &&
		!isIntegrationIdValidationLoading;

	const { data: accessTokenData, isLoading: isLoadingAccessToken } =
		useGetSourceControlAccessToken(values.sourceControlIntegrationId);

	const accessToken = accessTokenData?.data.accessToken || '';

	const { data: profileData, isLoading: isLoadingProfile } = useGetADOProfile(
		accessToken,
		values.sourceControlIntegrationId,
		shouldCallApi,
	);
	const publicAlias = profileData?.data.publicAlias || '';

	const { data: organizationsData, isLoading: isLoadingAccounts } =
		useGetADOOrganizations({
			token: accessToken,
			publicAlias,
			_enabled: shouldCallApi,
		});
	const adoOrganization = values.adoOrganization;
	const adoProjectName = values.adoProjectName;

	const { data: projectsData, isLoading: isLoadingProjects } =
		useGetADOProjects({
			token: accessToken,
			organization: adoOrganization,
			_enabled: shouldCallApi,
		});

	const { data: repositoriesData, isLoading: isLoadingRepositories } =
		useGetADORepositories({
			token: accessToken,
			organization: adoOrganization,
			project: adoProjectName,
			_enabled: shouldCallApi,
		});

	const adoOrganizations = useMemo(
		() =>
			organizationsData?.data.value.map((r: any) => ({
				label: r.accountName,
				value: r.accountName,
			})) || [],
		[organizationsData?.data.value],
	);

	const projects = useMemo(
		() =>
			projectsData?.data.value.map((r: any) => ({
				label: r.name,
				value: r.name,
			})) || [],
		[projectsData?.data.value],
	);

	const repositoryOptions: TOptions = useMemo(
		() =>
			repositoriesData?.data.value.map((r: any) => ({
				label: r.name,
				value: r.name,
				id: r.id,
			})) || [],
		[repositoriesData?.data.value],
	);

	const isLoading =
		isIntegrationIdValidationLoading ||
		isLoadingAccessToken ||
		isLoadingProfile ||
		isLoadingAccounts;

	if (isLoading) return <FormFieldLoading />;

	return (
		<>
			<SelectFormControl
				{...{
					isInvalid: Boolean(
						errors.adoOrganization && touched.adoOrganization,
					),
					isLoading: isLoadingAccounts,
					options: adoOrganizations,
					onChange: (e: any) => {
						setValues((prev: FormikValues) => ({
							...prev,
							[ADO_ORGANIZATION]: e.value || '',
							[ADO_PROJECT_NAME]: '',
							[REPOSITORY]: '',
							[REPOSITORY_ID]: '',
							[REPO_BRANCH]: '',
						}));
					},
					label: secondStep.chooseOrg,
					name: ADO_ORGANIZATION,
					currentValue: adoOrganization,
					maxW: 'xs',
					onFocus: () => setFieldTouched(ADO_ORGANIZATION, true),
					error: errors.adoOrganization,
				}}
			/>
			{Boolean(adoOrganization) && (
				<ScaleFade initialScale={0.9} in={Boolean(adoOrganization)}>
					<SelectFormControl
						{...{
							isInvalid: Boolean(
								errors.adoProjectName && touched.adoProjectName,
							),
							isLoading: isLoadingProjects,
							options: projects,
							onChange: (e: any) => {
								setValues((prev: FormikValues) => ({
									...prev,
									[ADO_PROJECT_NAME]: e.value || '',
									[REPOSITORY]: '',
									[REPOSITORY_ID]: '',
									[REPO_BRANCH]: '',
								}));
							},
							label: secondStep.chooseProject,
							name: ADO_PROJECT_NAME,
							currentValue: adoProjectName,
							maxW: 'xs',
							onFocus: () =>
								setFieldTouched(ADO_PROJECT_NAME, true),
							error: errors.adoProjectName,
						}}
					/>
				</ScaleFade>
			)}
			{Boolean(adoProjectName) && Boolean(showRepositorySelect) && (
				<ScaleFade initialScale={0.9} in={Boolean(adoProjectName)}>
					<OLDSelectRepositories
						{...{
							repositoryOptions,
							isLoading: isLoadingRepositories,
							accessToken,
							adoOrganization,
							adoProjectName,
						}}
					/>
				</ScaleFade>
			)}
		</>
	);
};
