import { useCallback, useState } from 'react';
import { useCreateProjectMutation } from '../../../services/projects';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { hasProjectTypes, isEHProjectType, ProjectTypesEnum } from '../helpers';
import { useOLDEnvironmentCreation } from './useOLDEnvironmentCreation';

export function useOLDProjectCreation(shouldEditEnvironment = false) {
	const [projectId, setProjectId] = useState('');
	const {
		mutate: createProject,
		isLoading: isLoadingCreateProject,
		status: createProjectStatus,
	} = useCreateProjectMutation();
	const shouldHandlePush = true;
	const {
		onEnvironmentCreation,
		onDeploymentCreation,
		onDeploymentDeploy,
		onEditEnvironment,
		isLoading: isLoadingCreateEnvironment,
		shouldReTryEnvironmentCreation,
		shouldReTryDeploymentCreation,
		shouldReTryDeploymentDeploy,
		shouldReTryEditEnvironment,
		currentEnvironmentId,
		currentDeploymentId,
		createEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
		editEnvironmentStatus,
	} = useOLDEnvironmentCreation({ shouldHandlePush, shouldEditEnvironment });

	const shouldRetryCreateProject = createProjectStatus === 'error';
	const shouldRetry =
		shouldRetryCreateProject ||
		shouldReTryEnvironmentCreation ||
		shouldReTryEditEnvironment ||
		shouldReTryDeploymentCreation ||
		shouldReTryDeploymentDeploy;

	const handleEnvironmentCreation = useCallback(
		(values: FormikValues, projectId: string, repositoryBranch: string) => {
			const {
				environmentName,
				tenantType,
				deployOnCommit,
				linkEnvironmentToDefaultBranch,
				projectType,
				cmEnvironmentId,
			} = values;

			const shouldCreateDeployment = true;
			const isEH = isEHProjectType(projectType);

			//create environment payload
			const createEnvPayloads = {
				name: environmentName,
				tenantType,
				repositoryBranch,
				deployOnCommit,
				linkEnvironmentToDefaultBranch,
				...(isEH && {
					editingHostEnvironmentDetails: {
						cmEnvironmentId,
					},
				}),
			};
			onEnvironmentCreation(
				projectId,
				createEnvPayloads,
				shouldCreateDeployment,
			);
		},
		[onEnvironmentCreation],
	);

	const onProjectCreation = useCallback(
		(
			values: FormikValues,
			repositoryName = '',
			repositoryBranch = '',
			repositoryId = '',
		) => {
			const {
				sourceControlIntegrationId,
				name,
				projectType,
				repositoryRelativePath,
			} = values;
			const hasTemplateType = hasProjectTypes(projectType);
			const isEHType = isEHProjectType(projectType);
			//create project payload
			const createProjectPayload = {
				name,
				repository: repositoryName,
				sourceControlIntegrationId,
				repositoryId: `${repositoryId}` || `${values.repositoryId}`,
				...(hasTemplateType && {
					type: projectType as ProjectTypesEnum,
				}),
				...(isEHType && {
					editingHostProjectDetails: {
						repositoryRelativePath,
					},
				}),
			};

			createProject(
				{ project: createProjectPayload },
				{
					onSuccess: (data) => {
						const currentProjectId = data.data.id;
						setProjectId(currentProjectId);
						//trigger the 3rd step
						handleEnvironmentCreation(
							values,
							currentProjectId,
							repositoryBranch,
						);
					},
				},
			);
		},
		[createProject, handleEnvironmentCreation],
	);

	const isLoading = isLoadingCreateProject || isLoadingCreateEnvironment;

	return {
		onProjectCreation,
		handleEnvironmentCreation,
		isLoading,
		shouldRetry,
		currentEnvironmentId,
		currentDeploymentId,
		onDeploymentCreation,
		onDeploymentDeploy,
		onEditEnvironment,
		projectId,
		shouldRetryCreateProject,
		shouldReTryEnvironmentCreation,
		shouldReTryDeploymentCreation,
		shouldReTryDeploymentDeploy,
		shouldReTryEditEnvironment,
		createProjectStatus,
		createEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
		editEnvironmentStatus,
	};
}
