import {
	TCalculatedStatus,
	TLogMessages,
	TStages,
} from './../../../app/pages/project/deployment-logs/handlers';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import config from '../../../app/config/config';
import { useGetMonitoringHistoricalData } from '../../../app/services/monitoring';
import { IGetDeploymentResponse } from '../../../app/models/deploymentModel';
import { groupBy } from 'lodash';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import {
	ILogMessage,
	IProcessStatusInfo,
} from '../../../app/models/deployLogger';
import { useSignalRConnection } from './useSignalRConnection';

const GETLOGMESSAGES = 'GetLogMessages';
const GETSTAGESTATE = 'GetStageState';
const STAGE = 'stage';

export function useDeploymentLogs(deployment: IGetDeploymentResponse) {
	const { deploymentId } = useParams<{ deploymentId: string }>();
	const [messages, setMessages] = useState<TLogMessages>({
		Provisioning: [],
		Build: [],
		Deployment: [],
		PostActions: [],
	});
	const [logsHistory, setLogHistory] = useState<TLogMessages>({
		Provisioning: [],
		Build: [],
		Deployment: [],
		PostActions: [],
	});
	const [currentStage, setCurrentStage] = useState<TStages>('Provisioning');

	const calculatedStatus = deployment?.calculatedStatus as TCalculatedStatus;
	const isCanceledOrCompleted =
		deployment?.isCanceled || calculatedStatus === 2;

	const queryClient = useQueryClient();
	const toast = useToast();

	const { connection, onInvoke, isInvoked } = useSignalRConnection(
		deploymentId as string,
	);

	const {
		refetch,
		isLoading: msgHistoryIsLoading,
		error,
	} = useGetMonitoringHistoricalData({
		deploymentId: deploymentId as string,
		refetchOnWindowFocus: true,
		onSuccess({ data }) {
			const logs = data.logs;
			if (!logs.length) return;
			const allLogs = groupBy(logs, (l) => l.stage) as TLogMessages;
			setLogHistory(allLogs);
		},
		onError(error) {
			const err = error as AxiosError;
			//status 0 is returned when intercepted requests for ios safari
			// status 404 is returned when deployment logs is not found
			if (err?.response?.status === 404 || err?.response?.status === 0) {
				toast.closeAll();
				return;
			}
			toast({
				status: 'error',
				description: err?.message,
			});
		},
	});

	const handleInvalidateQueries = useCallback(() => {
		queryClient.invalidateQueries(
			config.deployments.deployment_v3.queryKey(deploymentId!),
		);
		refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deploymentId, queryClient, refetch]);

	const onMessages = useCallback(
		(msgs: ILogMessage[]) => {
			if (!msgs.length) return;

			setMessages((prev) => {
				const allMessages = prev;
				for (const msg of msgs) {
					const stage = msg[STAGE];
					allMessages[stage].push(msg);
				}
				return { ...allMessages };
			});

			const stage = msgs[0].stage as TStages;
			if (currentStage !== stage) {
				setCurrentStage(stage);
				handleInvalidateQueries();
			}
		},
		[currentStage, handleInvalidateQueries],
	);

	const onStatus = useCallback(
		(statusInfo: IProcessStatusInfo) => {
			// Instead of using the data here, we will refetch deployment to use a single source of truth.
			handleInvalidateQueries();
		},
		[handleInvalidateQueries],
	);

	useEffect(() => {
		if (connection && isInvoked) {
			connection.on(GETLOGMESSAGES, onMessages);
			connection.on(GETSTAGESTATE, onStatus);
		}

		return () => {
			if (!connection) return;
			connection.off(GETLOGMESSAGES, onMessages);
			connection.off(GETSTAGESTATE, onStatus);
		};
	}, [connection, isInvoked, onMessages, onStatus]);

	useEffect(() => {
		if (connection && !isCanceledOrCompleted) {
			onInvoke();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deploymentId, isCanceledOrCompleted, connection]);

	const isLoading = !error
		? msgHistoryIsLoading || !logsHistory?.Provisioning?.length
		: msgHistoryIsLoading;

	return { messages, logsHistory, isLoading, error };
}
