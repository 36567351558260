import { FC, useMemo, useState } from 'react';
import { pageSize } from '../../../../@xmcloud/utils/constants';
import {
	IGetEnvironmentResponse,
	ISite,
} from '../../../models/environmentModel';
import { LoadingSkeleton, Table } from '../../../shared-components';
import { sitesTableColumns } from '../columns/sites';
import LinkToVercelProject from '../components/LinkToVercelProject';
import UnlinkSiteFromHost from '../components/UnlinkSiteFromHost';
import { ColumnDef } from '@tanstack/react-table';
import { useProjectDetails } from '../../../../@xmcloud/hooks';
import { useGetRepositoryIntegration } from '../../../services/repositories';

interface Props {
	environment: IGetEnvironmentResponse;
	sites: ISite[];
}

const headers = ['name', 'Hosting URL', ''];

const SitesTable: FC<Props> = ({ environment, sites }) => {
	const [openLinkToVercelModal, setOpenLinkToVercelModal] = useState(false);
	const [openUnlinkSiteConfirmation, setOpenUnlinkSiteConfirmation] =
		useState(false);
	const [selectedSite, setSelectedSite] = useState<ISite>({} as ISite);
	const { project } = useProjectDetails(environment.projectId);
	const sourceControlIntegrationId =
		project?.data.sourceControlIntegrationId || '';
	const { data: scIntegration, isLoading } = useGetRepositoryIntegration(
		sourceControlIntegrationId,
	);

	const provider = scIntegration?.data?.provider || '';

	const columns: ColumnDef<ISite, any>[] = useMemo(() => {
		return sitesTableColumns(
			environment,
			setSelectedSite,
			setOpenLinkToVercelModal,
			setOpenUnlinkSiteConfirmation,
			provider,
		);
	}, [environment, provider]);

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} />;

	return (
		<>
			<Table
				{...{
					columns,
					tableData: sites,
					dataTestId: 'sites-table',
					showPagination: sites.length > pageSize,
					initialSort: [{ id: 'name', desc: true }],
					headers,
				}}
			/>
			{openLinkToVercelModal && (
				<LinkToVercelProject
					openModal={openLinkToVercelModal}
					setOpenModal={setOpenLinkToVercelModal}
					site={selectedSite}
					environment={environment}
				/>
			)}
			{openUnlinkSiteConfirmation && (
				<UnlinkSiteFromHost
					openModal={openUnlinkSiteConfirmation}
					setOpenModal={setOpenUnlinkSiteConfirmation}
					site={selectedSite}
				/>
			)}
		</>
	);
};

export default SitesTable;
