import { Card, CardBody, SimpleGrid, Stack, Wrap } from '@chakra-ui/react';
import { mdiFeather, mdiMonitorShimmer } from '@mdi/js';
import {
	choosePath,
	templateDescriptions,
	templateTitles,
} from '../../../@xmcloud/core/messages/en';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { useCreateProject } from '../../../@xmcloud/hooks';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { HeadingMd, Icon } from '../../shared-components';
import { HeadingXl, Text } from '../../shared-components/text';
import {
	CwJourneyType,
	ExtractGoTo,
	goToCreateProjectPaths,
	JOURNEY,
	PROJECT_TYPE,
	ProjectTypesEnum,
} from './helpers';
import { useRedirect404 } from './old-components/useRedirect404';

const { pageTitle, pageSubtitle } = choosePath;
const { CM, EH } = ProjectTypesEnum;
const { dual, cm, eh } = CwJourneyType;

const paths = [
	{
		title: templateTitles[dual],
		description: templateDescriptions[dual],
		template: dual,
		path: mdiFeather,
		path2: mdiMonitorShimmer,
		color: 'purple',
		color2: 'teal',
	},
	{
		title: templateTitles[CM],
		description: templateDescriptions[CM],
		template: cm,
		path: mdiFeather,
		path2: '',
		color: 'purple',
	},
	{
		title: templateTitles[EH],
		description: templateDescriptions[EH],
		template: eh,
		path: mdiMonitorShimmer,
		path2: '',
		color: 'teal',
	},
];

export const ChoosePath: React.FC = () => {
	const { setFieldValue } = useCreateProject();

	useRedirect404();

	return (
		<Stack
			alignItems="center"
			py="5"
			gap="10"
			maxW="container.xl"
			mx="auto"
		>
			<HeadingXl as="h1" text={pageTitle} />
			<Text text={pageSubtitle} variant="subtle" />
			<SimpleGrid
				columns={[1, null, null, 3]}
				spacing={[4, null, null, 6]}
			>
				{paths.map(
					({
						title,
						description,
						template,
						path,
						path2,
						color,
						color2,
					}) => (
						<Card
							layerStyle="interactive.fill"
							size="lg"
							variant="outline"
							align="center"
							key={title}
							data-testid="card-box"
							onClick={() => {
								setFieldValue(JOURNEY, template);
								setFieldValue(
									PROJECT_TYPE,
									template === dual ? '' : template,
								);
								handlePush(
									goTo[
										goToCreateProjectPaths[
											template
										] as ExtractGoTo
									](),
								);
							}}
						>
							<CardBody as={Stack} gap="8" align="center" py="12">
								<Wrap
									spacing="3"
									align="center"
									justify="center"
								>
									<Icon
										path={path}
										boxSize="icon.4xl"
										color={color}
										layerStyle="icon.subtle"
									/>
									{path2 ? (
										<Icon
											path={path2}
											boxSize="icon.4xl"
											color={color2}
											layerStyle="icon.subtle"
										/>
									) : null}
								</Wrap>
								<Stack gap="4">
									<HeadingMd
										text={title}
										textAlign="center"
									/>
									<Text
										text={description}
										textAlign="center"
										variant="subtle"
										maxW="prose"
									/>
								</Stack>
							</CardBody>
						</Card>
					),
				)}
			</SimpleGrid>
		</Stack>
	);
};
