import {
	Accordion,
	AccordionItem as SDSAccordionItem,
	AccordionPanel,
	Box,
	ListItem,
	UnorderedList,
} from '@chakra-ui/react';
import { uniqBy } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDeploymentLogs } from '../../../../@xmcloud/hooks';
import { getReadableTime } from '../../../../@xmcloud/utils/dateUtils';
import { IGetDeploymentResponse } from '../../../models/deploymentModel';
import { AccordionButton } from './AccordionButton';
import { processGroups, TStages, Stages, logMessageTypes } from './handlers';
import { useVirtualizer } from '@tanstack/react-virtual';
import styles from '../../../../styles/scrollbar.module.css';
import { LogSelect, TerminalLoading } from '../../../shared-components';
import useResizeObserver from 'use-resize-observer';
import { AxiosError } from 'axios';
import { ILogMessage } from '../../../models/deployLogger';

interface AccordionProps {
	deployment: IGetDeploymentResponse;
	setDisableDownloadBtn: (value: boolean) => void;
}

const LogsAccordion: FC<AccordionProps> = ({
	deployment,
	setDisableDownloadBtn,
}) => {
	const processgroups = useMemo(
		() => processGroups(deployment),
		[deployment],
	);
	const { messages, logsHistory, isLoading, error } =
		useDeploymentLogs(deployment);

	useEffect(() => {
		const err = error as AxiosError | null;

		if (err?.response?.status === 404) {
			setDisableDownloadBtn(true);
		}
	}, [error, setDisableDownloadBtn]);

	return (
		<Accordion
			allowMultiple
			data-testid="deployment-logs-accordion"
			mt="8"
			mb="10"
		>
			{processgroups.map((processgroup) => {
				const key = processgroup.key;
				const type: TStages = Stages[key];
				const stageHistory = logsHistory[type];
				const stageMessages = messages[type];
				return (
					<AccordionItem
						key={key}
						processgroup={processgroup}
						deployment={deployment}
						logsHistory={stageHistory}
						messages={stageMessages}
						isLoading={isLoading}
					/>
				);
			})}
		</Accordion>
	);
};

export default LogsAccordion;

interface AccordionItemProps {
	processgroup: any;
	deployment: IGetDeploymentResponse;
	logsHistory: ILogMessage[];
	messages: ILogMessage[];
	isLoading: boolean;
}

const AccordionItem: FC<AccordionItemProps> = ({
	processgroup,
	deployment,
	logsHistory,
	messages,
	isLoading,
}) => {
	return (
		<SDSAccordionItem>
			{({ isExpanded }) => (
				<>
					<AccordionButton {...{ processgroup, deployment }} />
					<AccordionPanel
						//@ts-ignore
						as={(props) => <pre {...props} />}
						p={['0', '3', ' 3', '3']}
					>
						{isExpanded && (
							<RowVirtualizer
								{...{ logsHistory, messages, isLoading }}
							/>
						)}
					</AccordionPanel>
				</>
			)}
		</SDSAccordionItem>
	);
};

interface RowVirtualizerProps {
	logsHistory: ILogMessage[];
	messages: ILogMessage[];
	isLoading: boolean;
}
const { info, error, warn } = logMessageTypes;

export const RowVirtualizer: FC<RowVirtualizerProps> = ({
	logsHistory,
	messages,
	isLoading,
}) => {
	const logs = useMemo(() => {
		if (!logsHistory?.length) return messages || [];
		if (!messages?.length) return logsHistory || [];
		const unionLogs = [...logsHistory, ...messages];
		return uniqBy(unionLogs, (obj) => obj.logMessage);
	}, [logsHistory, messages]);

	const [currentLogs, setCurrentLogs] = useState(logs);

	const warnLogs = useMemo(
		() => logs.filter((log) => log.logLevel.includes(warn)),
		[logs],
	);
	const errorLogs = useMemo(
		() => logs.filter((log) => log.logLevel.includes(error)),
		[logs],
	);

	const loggerLineHeight = 30;

	const parentRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	const jumpToEnd = () => {
		if (parentRef.current) {
			parentRef.current.scrollTop = parentRef.current?.scrollHeight;
		}
	};

	useResizeObserver({
		ref: parentRef.current,
		onResize: () => {
			jumpToEnd();
		},
	});

	useEffect(jumpToEnd, [messages]);

	const rowVirtualizer = useVirtualizer({
		count: currentLogs.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => loggerLineHeight,
		overscan: 5,
	});

	return (
		<>
			<LogSelect
				onChange={(e) => {
					const value = e.value as string;
					const selectedLogs =
						value.toLocaleLowerCase() === error
							? errorLogs
							: value.toLocaleLowerCase() === warn
								? warnLogs
								: logs;
					setCurrentLogs(selectedLogs);

					rowVirtualizer.scrollOffset &&
						rowVirtualizer.scrollToIndex(0);
				}}
			/>
			<Box
				h="40vh"
				overflow="auto"
				bg="gray.800"
				w="100%"
				p={[1, 3, 3, 3]}
				pb={[5, 3, 3, 3]}
				ref={parentRef}
				className={styles['custom-scrollbar']}
				data-testid="deployment-logs-accordion-panel"
				mb={[5, 0, 0, 0]}
			>
				{isLoading && <TerminalLoading />}
				<UnorderedList
					sx={{
						height: `${rowVirtualizer.getTotalSize()}px`,
						position: 'relative',
						width: '2500px',
						listStyleType: 'none',
					}}
				>
					{rowVirtualizer.getVirtualItems().map((virtualRow) => {
						const { index, size, start } = virtualRow;
						const row = currentLogs[index];
						const { logMessage, logLevel } = row;

						return (
							<ListItem
								key={virtualRow.index}
								fontFamily="mono"
								sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: `${size}px`,
									transform: `translateY(${start}px)`,
									backgroundColor: 'transparent',
									color: logLevel?.includes(info)
										? 'gray.100'
										: logLevel?.includes(error)
											? 'danger'
											: logLevel?.includes(warn)
												? 'warning'
												: 'gray.100',
								}}
							>
								{getReadableTime(row.logTime, 'HH:mm:ss')}{' '}
								{logMessage}
							</ListItem>
						);
					})}
				</UnorderedList>
			</Box>
		</>
	);
};
