import { MenuItem, Tooltip, MenuList, Tag } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { app, projects } from '../../../../@xmcloud/core/messages/en';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { IGetProjectResponse } from '../../../models/projectModel';
import { Link, RowActions } from '../../../shared-components';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { handleTo } from '../../../../@xmcloud/utils/helpers';
import { createColumnHelper } from '@tanstack/react-table';
import { ProjectTypesEnum } from '../../create-project/helpers';

const {
	deleteProject,
	canNotBeDeleted,
	nuOfEnvironments,
	nuOfEnv,
	projectType,
	webApp,
	contentService,
} = projects;
const { dateCreated, name, id } = app;

const { environments: environmentsGoto } = goTo;

export type PickNameId = Pick<IGetProjectResponse, 'id' | 'name'>;
export type TProjects = IGetProjectResponse & { nuOfEnvironments: number };

const { EH } = ProjectTypesEnum;

const columnHelper = createColumnHelper<TProjects>();

export const projectsTableColumns = ({
	projectDetails,
	setOpenModal,
	isSmScreen,
	createProjectV2,
}: {
	projectDetails: React.MutableRefObject<PickNameId>;
	setOpenModal: (openModal: boolean) => void;
	isSmScreen: boolean;
	createProjectV2: boolean;
}) => {
	return [
		columnHelper.accessor('name', {
			header: () => t(name),
			cell: (info) => (
				<Link
					text={info.row.original.name}
					to={handleTo(environmentsGoto(info.row.original.id))}
					noOfLines={2}
					whiteSpace="wrap"
				/>
			),
			...(createProjectV2 && {}),
		}),
		...(createProjectV2
			? [
					columnHelper.accessor('type', {
						header: () => t(projectType),
						cell: (info) => {
							const type = info.getValue();
							const isEH = EH === type;
							return (
								<Tag colorScheme={isEH ? 'teal' : 'purple'}>
									{isEH ? webApp : contentService}
								</Tag>
							);
						},
						maxSize: 64,
					}),
				]
			: []),
		columnHelper.accessor('id', {
			header: () => t(id),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('nuOfEnvironments', {
			cell: (info) => info.getValue(),
			header: () => (isSmScreen ? t(nuOfEnv) : t(nuOfEnvironments)),
		}),
		columnHelper.accessor('lastUpdatedAt', {
			header: () => t(dateCreated),
			cell: (info) => getReadableDate(info.renderValue() as string),
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original: project } }) => (
				<RowActions ariaLabel="projects-table-action-menu">
					<MenuList>
						<Tooltip
							label={t(canNotBeDeleted)}
							isDisabled={!project.environments.length}
							shouldWrapChildren
						>
							<MenuItem
								isDisabled={!!project.environments.length}
								onClick={() => {
									projectDetails.current = {
										name: project.name,
										id: project.id,
									};
									setOpenModal(true);
								}}
								minW="3xs"
							>
								{t(deleteProject)}
							</MenuItem>
						</Tooltip>
					</MenuList>
				</RowActions>
			),
			maxSize: 0,
		}),
	];
};
