import { ReviewFinish } from '..';
import { useCreateProject } from '../../../../../@xmcloud/hooks';
import { useXMCloudTemplateGitHubProjectCreation } from '../../../../../@xmcloud/hooks/create-project/useXMCloudTemplateGitHubProjectCreation';
import { IRepositoryTemplateDetails } from '../../../../models/repositoriesModel';
import { ProjectTypesEnum } from '../../helpers';

const { CM, EH } = ProjectTypesEnum;

export const ReviewFinishGithub: React.FC<{
	XMCloudTemplate: IRepositoryTemplateDetails;
	token: string;
	isLoading: boolean;
}> = ({ XMCloudTemplate, token, isLoading: initialLoading }) => {
	const { isOwnCode, values } = useCreateProject();
	const { journey } = values;
	const projectType = journey === 'eh' ? EH : CM;
	const {
		onHandleCreationProcess,
		repositoryCreationStatus,
		isLoading,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
		shouldRetry,
	} = useXMCloudTemplateGitHubProjectCreation({
		XMCloudTemplate,
		projectType,
		token,
		shouldGotoDeploymentPage: true,
		skipCreateRepository: isOwnCode,
	});

	const listOfStatus = [
		...(isOwnCode
			? []
			: [{ status: repositoryCreationStatus, text: 'Repository' }]),
		{ status: projectCreationStatus, text: 'Project' },
		{ status: environmentCreationStatus, text: 'Environment' },
		{ status: deploymentCreationStatus, text: 'Deployment' },
	];

	return (
		<ReviewFinish
			{...{
				isLoading: initialLoading || isLoading,
				shouldRetry,
				handleStartDeployment: onHandleCreationProcess,
				listOfStatus,
			}}
		/>
	);
};
