import { useCreateProject } from '../../../../@xmcloud/hooks';
import { InputFormControlWithTransformationMessage } from '../../../shared-components';
import { secondStep } from '../../../../@xmcloud/core/messages/en';
import { TRepository } from '../helpers';

const { info2, nameYourRepo } = secondStep;

export const RepositoryNameInputField: React.FC<{
	repositoryNamespace: TRepository;
}> = ({ repositoryNamespace }) => {
	const {
		values,
		errors,
		touched,
		setFieldTouched,
		handleChange,
		setFieldValue,
	} = useCreateProject();
	return (
		<InputFormControlWithTransformationMessage
			{...{
				isInvalid: Boolean(
					errors[repositoryNamespace] && touched[repositoryNamespace],
				),
				label: nameYourRepo,
				name: repositoryNamespace,
				value: values[repositoryNamespace],
				pt: 0,
				maxW: 'sm',
				onChange: handleChange,
				error: errors[repositoryNamespace],
				onFocus: () => setFieldTouched(repositoryNamespace, true),
				setFieldValue,
				isRequired: true,
				textContent: info2,
			}}
		/>
	);
};
