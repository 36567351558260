import {
	useADOProjectCreation,
	useCreateProject,
} from '../../../../@xmcloud/hooks';
import { OLDReviewFinish } from './OLDReviewFinish';

export const OLDReviewFinishADO: React.FC = () => {
	const { values } = useCreateProject();
	const {
		isLoading,
		onADOProjectCreation,
		shouldRetry,
		repositoryCreationStatus,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
		isFetchingADORepository,
	} = useADOProjectCreation(values);

	return (
		<OLDReviewFinish
			{...{
				isLoading,
				shouldRetry,
				handleStartDeployment: onADOProjectCreation,
				repositoryCreationStatus,
				projectCreationStatus,
				environmentCreationStatus,
				deploymentCreationStatus,
				isFetchingADORepository,
			}}
		/>
	);
};
