import { Spacer, Stack } from '@chakra-ui/react';
import { UnauthenticatedAxiosProvider } from '../../apiUtils/AxiosProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { Icon, LoadingScreen, XMCSButton } from '../../shared-components';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { mainPaths } from '../../../@xmcloud/core/routes/paths';
import { app } from '../../../@xmcloud/core/messages/en';
import { StatusPage } from '..';
import { mdiArrowLeft } from '@mdi/js';
import { Layout, LayoutMain } from '../../shared-components/';

export const PublicStatusPage: React.FC = () => {
	const { isAuthenticated, isLoading } = useAuth0();
	if (isLoading) return <LoadingScreen />;
	if (isAuthenticated && mainPaths.status) {
		handlePush(mainPaths.systemStatus);
	}

	return (
		<UnauthenticatedAxiosProvider>
			{/* @ts-ignore */}
			<Layout>
				<LayoutMain display="flex" justifyContent="center">
					<Stack w={['100%', '100%', '70%', '70%']}>
						<XMCSButton
							text={app.home}
							leftIcon={<Icon path={mdiArrowLeft} />}
							onClick={() => handlePush(mainPaths.home)}
							variant="link"
						/>
						<Spacer />
						<StatusPage />
					</Stack>
				</LayoutMain>
			</Layout>
		</UnauthenticatedAxiosProvider>
	);
};
