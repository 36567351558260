import { useEffect, useMemo, useState } from 'react';
import {
	AlertDialogConfirmationModal,
	CodeBlock,
	HeadingMd,
	HeadingSm,
	SelectFormControl,
	TextInfoIcon,
	ToggleStack,
	TransifexTextAsBold,
	Text as XMCSText,
} from '../../../../shared-components';
import { IGetProjectResponse } from '../../../../models/projectModel';
import { IGetEnvironmentResponse } from '../../../../models/environmentModel';
import { app, devSettings } from '../../../../../@xmcloud/core/messages/en';
import { isPhoneDevice } from '../../../../../@xmcloud/utils/helpers';
import {
	Stack,
	TabList,
	Tab,
	Divider,
	Card,
	CardHeader,
	CardBody,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { NewJssVersionSettings } from './NewJssVersionSettings';
import { LowerJssVersionSettings } from './LowerJssVersionSettings';
import { EnumInNumberSourceControl } from '../../../../../@xmcloud/types';

const {
	chooseSite,
	preview,
	live,
	ContextId,
	info1,
	info2,
	title,
	info,
	txt1,
	txt2,
	txt4,
	noSite,
	step1,
	step2,
	step3,
	title1,
	jssVersionTooltip,
	contextIdTooltip,
} = devSettings;
const { close: closetxt } = app;

const SITE_NAME = 'siteName';
const runCodeLines = [`npm run start:connected`];
const { ADO, GitHub } = EnumInNumberSourceControl;

export const CombinedProjectDevSettings: React.FC<{
	project: IGetProjectResponse;
	environmentDetail: IGetEnvironmentResponse;
	editingSecret: string;
	isEditingSecretAvailable: boolean;
	sites: any[];
	provider: string | number;
	cdpEmbeddedTenantID: string;
}> = ({
	project,
	environmentDetail,
	editingSecret,
	isEditingSecretAvailable,
	sites = [],
	provider,
	cdpEmbeddedTenantID,
}) => {
	const [isPreview, setIsPreview] = useState(true);
	const [siteName, setSiteName] = useState('');
	const [openInfo, setOpenInfo] = useState({ isOpen: false, info: 1 });
	const [isNewVersion, setIsNewVersion] = useState(true);

	const repository = project?.repository || '';
	const sourceControlIntegrationName =
		project?.sourceControlIntegrationName || '';
	const isAdoProvider = provider === ADO;
	const isGitProvider = provider === GitHub;
	const adoRepo = project?.repository?.split('/') || [];

	const gitClone = `git clone https://github.com/${sourceControlIntegrationName}/${repository}.git`;
	const adoClone = `git clone https://dev.azure.com/${adoRepo[0]}/${adoRepo[1]}/_git/${adoRepo[2]}`;

	const checkedoutCodeLine = [
		`${
			isAdoProvider
				? adoClone
				: isGitProvider
					? gitClone
					: 'clone your repository'
		}`,
		!!repository
			? `cd ${repository}\\src\\sxastarter`
			: 'cd path to your app directory',
		`npm install`,
	];

	const siteList = useMemo(
		() => sites.map((s) => ({ label: s.name, value: s.name })),
		[sites],
	);

	useEffect(() => {
		if (sites.length && !siteName) {
			setSiteName(sites[0].name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sites.length]);

	return (
		<>
			<Stack
				w="100%"
				py={6}
				direction={['column', 'column', 'row', 'row']}
				gap={8}
			>
				<Stack w={['100%', '100%', '70%', '70%']}>
					<HeadingSm text={title} />
					<Stack
						direction="row"
						gap={6}
						pb="4"
						flexWrap="wrap"
						bgColor={['chakra-subtle-bg', 'chakra-body-bg']}
					>
						<SelectFormControl
							{...{
								options: siteList,
								onChange: (e: any) => {
									setSiteName(e.value);
								},
								label: chooseSite,
								name: SITE_NAME,
								currentValue: siteName,
								maxW: '2xs',
								isDisabled: !siteList.length,
								pt: 0,
								showInfoIcon: true,
								infoText: info1,
								placeholder: siteList.length
									? 'Select...'
									: noSite,
								onInfoIconClick: () =>
									isPhoneDevice &&
									setOpenInfo({ isOpen: true, info: 1 }),
							}}
						/>
						<Toggle
							setOpenInfo={setOpenInfo}
							setIsPreview={setIsNewVersion}
							dataTestId1="new-jss-version-toggle"
							dataTestId2="old-jss-version-toggle"
							tabText1={'JSS 21.6+'}
							tabText2={'JSS 21.5 or earlier'}
							title={'JSS version'}
							tooltipText={jssVersionTooltip}
						/>
						<Toggle
							setOpenInfo={setOpenInfo}
							setIsPreview={setIsPreview}
							dataTestId1="contextid-preview-button"
							dataTestId2="contextid-live-button"
							tabText1={preview}
							tabText2={live}
							title={ContextId}
							tooltipText={
								!isNewVersion ? contextIdTooltip : info2
							}
							isDisabled={!isNewVersion}
						/>
					</Stack>
					{isNewVersion ? (
						<NewJssVersionSettings
							{...{
								environmentDetail,
								isPreview,
								editingSecret,
								siteName,
								isEditingSecretAvailable,
							}}
						/>
					) : (
						<LowerJssVersionSettings
							{...{
								siteName,
								cdpEmbeddedTenantID,
								sites,
							}}
						/>
					)}
					<Stack>
						<Divider py="3" />
						<Stack pt={[10, 10, 5, 5]}>
							<HeadingSm text={title1} />
							<Stack gap={5}>
								<Stack>
									<XMCSText pb={[4, 4, 1, 1]} text={step1} />
									<CodeBlock
										items={checkedoutCodeLine}
										clipboardText={checkedoutCodeLine.join(
											'\n',
										)}
										data-testid="code-block"
										key="checkout-code-block"
									/>
								</Stack>
								<XMCSText w="full" text={step2} />
								<Stack>
									<XMCSText w="full" text={step3} />
									<CodeBlock
										items={runCodeLines}
										clipboardText={runCodeLines.join('\n')}
										data-testid="code-block"
										key="run-code-block"
									/>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				<Stack w={['100%', '100%', '30%', '30%']} pt={[2, 2, 8, 8]}>
					<Card variant="filled">
						<CardHeader>
							<HeadingMd text={info} />
						</CardHeader>
						<CardBody>
							<XMCSText text={txt1} />
							<XMCSText py={3} text={txt2} />
							<TransifexTextAsBold
								text={txt4}
								boldText={
									isNewVersion ? 'Site, JSS version' : 'Site '
								}
								boldText2={
									isNewVersion ? 'Context' : 'JSS version'
								}
							/>
						</CardBody>
					</Card>
				</Stack>
			</Stack>
			{openInfo.isOpen && (
				<AlertDialogConfirmationModal
					{...{
						isOpen: openInfo.isOpen,
						onClose: () => setOpenInfo({ isOpen: false, info: 1 }),
						shouldShowActionBtn: false,
						rightButtonText: closetxt,
					}}
				>
					<XMCSText
						mt="4"
						text={openInfo.info === 1 ? info1 : info2}
					/>
				</AlertDialogConfirmationModal>
			)}
		</>
	);
};

const Toggle = ({
	setOpenInfo,
	setIsPreview,
	dataTestId1,
	dataTestId2,
	tabText1,
	tabText2,
	title,
	tooltipText,
	isDisabled = false,
}: {
	setOpenInfo: (a: any) => void;
	setIsPreview: (a: boolean) => void;
	dataTestId1: string;
	dataTestId2: string;
	tabText1: string;
	tabText2: string;
	title: string;
	tooltipText: string;
	isDisabled?: boolean;
}) => {
	return (
		<Stack>
			<TextInfoIcon
				text={title}
				tooltipText={tooltipText}
				onClick={() => setOpenInfo({ isOpen: true, info: 2 })}
			/>
			<ToggleStack
				bgColor="chakra-body-bg"
				defaultIndex={isDisabled ? -1 : 0}
				data-testid={`toggle-stack-${title}`}
			>
				<TabList>
					<Tab
						onClick={() => setIsPreview(true)}
						data-testid={dataTestId1}
						isDisabled={isDisabled}
					>
						{t(tabText1)}
					</Tab>
					<Tab
						onClick={() => setIsPreview(false)}
						data-testid={dataTestId2}
						isDisabled={isDisabled}
					>
						{t(tabText2)}
					</Tab>
				</TabList>
			</ToggleStack>
		</Stack>
	);
};
