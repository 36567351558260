import { useLocation, useParams } from 'react-router-dom';
import { goTo } from '../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../@xmcloud/utils/helpers';
import { sleep } from '../../../@xmcloud/utils/promiseUtils';
import { useGetDeployment } from '../../services/deployments';
import { useGetEnvironment } from '../../services/environments';
import { LoadingScreen } from '../../shared-components';

export const RedirectToNewUI: React.FC = () => {
	const params = useParams<{
		environmentId?: string;
		deploymentId?: string;
	}>();
	const location = useLocation();
	const hasEnvironmentId = params.environmentId !== undefined;
	const hasDeploymentId = params.deploymentId !== undefined;
	const { data: environmentData } = useGetEnvironment({
		id: params?.environmentId || '',
		enabled: hasEnvironmentId,
	});
	const environment = environmentData?.data;
	const { data: deploymentData } = useGetDeployment({
		id: params.deploymentId || '',
	});
	const deployment = deploymentData?.data;

	if (hasEnvironmentId && environment) {
		const { id, projectId } = environment;
		const isCmInstanceLogUrl = location.pathname.endsWith('/logs/types');
		(async () => {
			await sleep(500);
			isCmInstanceLogUrl
				? handlePush(goTo.cmInstanceLogTypes(projectId, id))
				: handlePush(goTo.deployments(projectId, id));
		})();
	}

	if (hasDeploymentId && deployment) {
		const { id, environmentId, projectId } = deployment;
		(async () => {
			await sleep(500);
			handlePush(goTo.deploymentLogs(projectId, environmentId, id));
		})();
	}

	return <LoadingScreen />;
};
