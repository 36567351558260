import { ToastProps, useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { AxiosInstance } from 'axios';

import {
	MutationFunction,
	QueryFunction,
	QueryFunctionContext,
	QueryKey,
	useMutation,
	UseMutationOptions,
	UseMutationResult,
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { handlePush } from '../../@xmcloud/utils/helpers';
import {
	isAxiosError,
	isError,
	isProblemErrors,
	ErrorResponseDetails,
} from '../../@xmcloud/utils/errorUtils';
import { useAuthenticatedAxios } from './AxiosProvider';
import { HttpStatusCodes } from './resources';

export const defaultErrorHandler = (
	err: unknown,
	scope: string | undefined,
	toast: (props: ToastProps) => void,
) => {
	if (isAxiosError(err)) {
		if (err.response) {
			// All access denied responses should lead to access denied page.
			if (err.response.status === HttpStatusCodes.ACCESS_DENIED) {
				handlePush('/access-denied');
				return;
			}

			if (err.response.status === HttpStatusCodes.REQUEST_TIMEOUT) {
				return toast({
					status: 'error',
					description: t(
						`Request failed with status code ${HttpStatusCodes.REQUEST_TIMEOUT} Gateway timeout.`,
					),
				});
			}

			const resData = err.response.data as ErrorResponseDetails | string;
			const statusText = err.response.statusText || '';
			//@ts-ignore
			const title = resData?.title || '';
			//@ts-ignore
			const detail = resData?.detail || '';
			const status = err.response.status;

			if (isProblemErrors(resData)) {
				Object.values(resData.errors).forEach((message) =>
					toast({
						status: 'error',
						description: message,
					}),
				);

				return;
			}
			if (!resData) {
				return toast({
					status: 'error',
					description: err.message,
				});
			}

			if (typeof resData === 'string') {
				return toast({
					status: 'error',
					title: statusText,
					description: `Request failed with status code ${status}. ${resData}`,
				});
			}

			return toast({
				status: 'error',
				title: title,
				description: `Request failed with status code ${
					resData.status ? resData.status : status
				}. ${detail}`,
			});
		}

		if (err.request) {
			// The request was made but no response was received
			if (navigator.onLine === true) {
				return toast({
					status: 'error',
					description: t(
						'Server failed to respond. Server could be offline and might be the issue.',
					),
				});
			}

			return toast({
				status: 'error',
				description: t(
					'Server failed to respond. You appear to be offline and might be the issue.',
				),
			});
		}

		// Something happened in setting up the request that triggered an Error
		// Error already logging to console and should be handled elsewhere.
		return;
	}

	if (isError(err)) {
		return toast({
			status: 'error',
			title: err.name,
			description: err.message,
		});
	}

	if (typeof err === 'string') {
		return toast({ status: 'error', description: err });
	}
};

/**
 * Needs to be used inside Auth0Context and AxiosProvider
 */
export const useAuthQuery = <
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	key: TQueryKey,
	fetcher: (
		axiosInstanceWithAuth: AxiosInstance,
		queryFnContext?: QueryFunctionContext<QueryKey, TQueryKey>,
	) => TQueryFnData | Promise<TQueryFnData>,
	options?: Omit<
		UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
		'queryKey' | 'queryFn'
	>,
	scope?: string,
): UseQueryResult<TData, TError> => {
	const toast = useToast();
	const axiosContext = useAuthenticatedAxios();
	if (!axiosContext)
		throw new Error(
			'useAuthQuery can not be used outside of auth and axios context',
		);

	const enhancedFetcher: QueryFunction<TQueryFnData, TQueryKey> = (params) =>
		fetcher(axiosContext, params);
	options = options || {};

	const onErrorCallback = options.onError;

	options.onError = (err) => {
		if (onErrorCallback) {
			onErrorCallback(err);
		} else {
			defaultErrorHandler(err, scope, toast);
		}
	};

	return useQuery<TQueryFnData, TError, TData, TQueryKey>(
		key,
		enhancedFetcher,
		options,
	);
};

export interface ExtraAuthOptions {
	skipDefaultErrorHandler?: boolean;
}

/**
 * Needs to be used inside Auth0Context and AxiosProvider
 */
export const useAuthMutation = <
	TData = unknown,
	TError = unknown,
	TVariables = void,
	TContext = unknown,
>(
	mutationFn: (
		axiosInstanceWithAuth: AxiosInstance,
	) => MutationFunction<TData, TVariables>,
	options?: Omit<
		UseMutationOptions<TData, TError, TVariables, TContext>,
		'mutationFn'
	> &
		ExtraAuthOptions,
	scope?: string,
): UseMutationResult<TData, TError, TVariables, TContext> => {
	const toast = useToast();
	const axiosContext = useAuthenticatedAxios();

	if (!axiosContext)
		throw new Error(
			'useAuthMutation can not be used outside of auth and axios context',
		);

	options = options || {};

	const customErrorCallback = options.onError;

	options.onError = (err, variables, context) => {
		customErrorCallback && customErrorCallback(err, variables, context);

		if (!(options?.skipDefaultErrorHandler ?? false)) {
			defaultErrorHandler(err, scope, toast);
		}
	};

	const enhancedMutationFn: MutationFunction<TData, TVariables> =
		mutationFn(axiosContext);

	return useMutation<TData, TError, TVariables, TContext>(
		enhancedMutationFn,
		options,
	);
};
