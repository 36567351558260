import { Link, MenuItem, Text, Tooltip } from '@chakra-ui/react';
import { t } from '@transifex/native';
import {
	environments,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { mdiOpenInNew } from '@mdi/js';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { DeploymentCreateAndDeployParams } from '../../../services/deploymentCreateDeploy';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import config from '../../../config/config';
import RestartMenuItem from './RestartMenuItem';
import { Icon } from '../../../shared-components';
import { ProjectTypesEnum } from '../../create-project/helpers';

const {
	linkEnvironment,
	delete: deleteEnv,
	editDetails,
	buildAndDeploy,
	openDashboard,
} = environments;

const { errMsg4, noProvider } = systemHealthStatus;

interface Props {
	setOpenDeleteModal: (open: boolean) => void;
	setOpenEditModal: (open: boolean) => void;
	environmentId: string;
	projectId: string;
	projectType: ProjectTypesEnum;
	deploymentCreateAndDeploy: (
		params: DeploymentCreateAndDeployParams,
	) => void;
	host: string;
	setLinkToAnotherBranchModal: (open: boolean) => void;
	isProviderHealthy: boolean;
	isAdoProvider: boolean;
	isGithubProvider: boolean;
	integrationErrorMessage: string;
	isNotValidIntegration: boolean;
}

const { EH } = ProjectTypesEnum;

const EnvironmentMenuItems: React.FC<Props> = ({
	setOpenDeleteModal,
	setOpenEditModal,
	environmentId,
	projectId,
	projectType,
	deploymentCreateAndDeploy,
	host,
	setLinkToAnotherBranchModal,
	isProviderHealthy,
	isAdoProvider,
	isGithubProvider,
	integrationErrorMessage,
	isNotValidIntegration,
}) => {
	const hasProvider = isAdoProvider || isGithubProvider;

	return (
		<>
			<MenuItem
				onClick={() => {
					deploymentCreateAndDeploy({
						environmentId,
						onSuccess: (deploymentId: string, data) => {
							if (data?.data) return;
							handlePush(
								goTo.deploymentLogs(
									projectId,
									environmentId,
									deploymentId,
								),
							);
						},
					});
				}}
				data-testid="menu-build-deploy-item"
			>
				{t(buildAndDeploy)}
			</MenuItem>
			<MenuItem
				onClick={() => setOpenEditModal(true)}
				data-testid="menu-edit-env-details-item"
			>
				{t(editDetails)}
			</MenuItem>
			<MenuItem
				onClick={() => setOpenDeleteModal(true)}
				data-testid="menu-delete-env-item"
			>
				{t(deleteEnv)}
			</MenuItem>
			<Tooltip
				label={
					isNotValidIntegration
						? t(integrationErrorMessage)
						: t(hasProvider ? errMsg4 : noProvider, {
								variableTxt: isAdoProvider
									? 'Azure DevOps'
									: isGithubProvider
										? 'GitHub'
										: '',
								variableTxt2:
									'link environment to another branch',
							})
				}
				aria-label="link environment another branch tooltip"
				whiteSpace="pre-wrap"
				isDisabled={isProviderHealthy}
			>
				<MenuItem
					onClick={() => setLinkToAnotherBranchModal(true)}
					data-testid="menu-link-env-item"
					isDisabled={!isProviderHealthy}
				>
					{t(linkEnvironment)}
				</MenuItem>
			</Tooltip>
			<MenuItem
				as={Link}
				isExternal
				href={config.externalLinks.xm_cloud_dashboard(host)}
				boxShadow="none !important"
				textDecoration="none !important"
				data-testid="menu-xm-cloud-item"
				justifyContent="space-between"
				_hover={{
					'.new-window-icon': {
						opacity: 1,
						transition: '0.7s',
					},
				}}
			>
				<Text>{t(openDashboard)}</Text>
				<Icon
					path={mdiOpenInNew}
					boxSize={5}
					color="chakra-placeholder-color"
					className="new-window-icon"
					opacity={0}
				/>
			</MenuItem>
			{projectType !== EH && (
				<RestartMenuItem
					environmentId={environmentId}
					key={environmentId}
				/>
			)}
		</>
	);
};

export default EnvironmentMenuItems;
