import { CodeBlock } from '../../../../shared-components';
import { Text, Stack, Box } from '@chakra-ui/react';
import { IGetProjectResponse } from '../../../../models/projectModel';
import { IGetEnvironmentResponse } from '../../../../models/environmentModel';
import { devSettings } from '../../../../../@xmcloud/core/messages/en';

export const EhProjectDevSettings: React.FC<{
	project: IGetProjectResponse;
	cmEnvironmentDetail: IGetEnvironmentResponse;
	siteName: string;
	isEditingSecretAvailable: boolean;
	editingSecret: string;
	apiKey: string;
}> = ({
	project,
	cmEnvironmentDetail,
	siteName,
	isEditingSecretAvailable,
	editingSecret,
	apiKey,
}) => {
	const sitecoreApiHost = `https://${cmEnvironmentDetail?.host}/`;
	const graphQlEndpoint = `https://${cmEnvironmentDetail?.host}/sitecore/api/graph/edge`;
	const codeBlockItems = [
		{
			title: 'Checkout code',
			code: [
				`git clone https://github.com/${project?.sourceControlIntegrationName}/${project?.repository}.git`,
				`cd ${project?.repository}\\src`,
			],
			showBlock: true,
		},
		{
			title: '.env.local',
			code: [
				isEditingSecretAvailable
					? `JSS_EDITING_SECRET=${editingSecret}`
					: `#JSS_EDITING_SECRET=${devSettings.editingSecretNotFound}`,
				`JSS_APP_NAME="${siteName}"`,
				`SITECORE_API_HOST=${sitecoreApiHost}`,
				`GRAPH_QL_ENDPOINT=${graphQlEndpoint}`,
				`SITECORE_API_KEY="${apiKey}"`,
				`DISABLE_SSG_FETCH=true`,
				`PUBLIC_URL=http://localhost:3000`,
			],
			showBlock: true,
		},
		{
			title: 'Run code',
			code: [`npm install`, `npm run start:connected`],
			showBlock: true,
		},
	];

	return (
		<Stack pb="12" pt="3" px="4">
			<Box w={['100%', '100%', '80%', '80%']}>
				{codeBlockItems.map(({ title, code }) => (
					<Stack key={title} py={3}>
						<Text variant="subtle" fontWeight="semibold">
							{title}
						</Text>
						<CodeBlock
							items={code}
							clipboardText={code.join('\n')}
						/>
					</Stack>
				))}
			</Box>
		</Stack>
	);
};
