import { useAuth0 } from '@auth0/auth0-react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useCallback, useEffect, useMemo, useState } from 'react';

import config from '../../../app/config/config';
import { sleep } from '../../utils/promiseUtils';

const url = config.monitoring.signalr_connection.url;
const SUBSCRIBE_TO_GROUP = 'SubscribeToGroup';

export const useSignalRConnection = (deploymentId: string) => {
	const [token, setToken] = useState('');
	const [isStarted, setIsStarted] = useState(false);
	const [isInvoked, setIsInvoked] = useState(false);

	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		(async () => {
			const token = await getAccessTokenSilently();
			setToken(token);
		})();
	}, [getAccessTokenSilently]);

	const connection = useMemo(() => {
		if (!token) return null;

		return new HubConnectionBuilder()
			.withUrl(url, { accessTokenFactory: () => token })
			.withAutomaticReconnect([0, 0, 1000, 1000, 2000, 2000])
			.build();
	}, [token]);

	const start = useCallback(async () => {
		if (!connection || isStarted) return;

		const maxRetries = 10;
		for (let attempt = 0; attempt < maxRetries; attempt++) {
			try {
				await sleep(1000);
				await connection.start();
				console.log('SignalR connection is established.');
				setIsStarted(true);
				break;
			} catch (error) {
				console.error(
					`Attempt ${
						attempt + 1
					} failed to establish connection. Retrying...`,
				);
				setIsStarted(false);
				if (attempt === maxRetries - 1) {
					console.error('Max retries reached. Connection failed.');
				}
			}
		}
	}, [connection, isStarted]);

	const onSubscribeToGroupChannel = useCallback(
		async (connection: HubConnection) => {
			if (!deploymentId) return;

			const maxRetries = 10;
			for (let attempt = 0; attempt < maxRetries; attempt++) {
				try {
					await sleep(1000);
					await connection.invoke(SUBSCRIBE_TO_GROUP, {
						DeploymentId: deploymentId,
					});
					console.log('Connected to the channel.');
					setIsInvoked(true);
					break;
				} catch (err) {
					console.error(`Attempt ${attempt + 1} failed. Retrying...`);
					setIsInvoked(false);
					console.error(
						`Could not invoke signalR method: ${SUBSCRIBE_TO_GROUP}`,
						err,
					);
					setIsStarted(false);
					if (attempt === maxRetries - 1) {
						console.error(
							'Max retries reached. Connection to channel failed.',
						);
					}
				}
			}
		},
		[deploymentId],
	);

	const onInvoke = async () => {
		if (!connection) return;
		start().then(() => {
			onSubscribeToGroupChannel(connection);
		});
	};

	useEffect(() => {
		const controller = new AbortController();

		return () => {
			if (connection) {
				connection.stop();
				controller.abort();
				setIsStarted(false);
				setIsInvoked(false);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connection]);

	return { start, connection, onInvoke, isInvoked };
};
