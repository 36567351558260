import { fifthStep, fourthStep, projects } from '../../core/messages/en';
import { TenantType } from '../../types';
import { useCreateProject } from '../context/CreateProjectContext';

const {
	projectName,
	repoName,
	repo,
	envName,
	prodEnv,
	autoDeploy,
	sourceControlConnection,
	sourceControl,
	envtxt,
	adoOrgName: adoOrgNametxt,
	adoProjectName: adoProjectNametxt,
} = fifthStep;
const { availableEnvironments, fieldLabel1 } = fourthStep;
const { contentService, webApp } = projects;

const getProjectNameItem = ({
	cardTitle,
	name,
	key,
}: {
	cardTitle?: string;
	name: string;
	key: number;
}) => ({
	stepNumber: 1,
	cardTitle,
	items: [{ title: projectName, subTitle: name }],
	key: key,
});

const getSourceControlItem = (isGithubProvider: boolean, key: number = 1) => ({
	stepNumber: 2,
	cardTitle: '',
	items: [
		{
			title: sourceControl,
			subTitle: isGithubProvider ? 'GitHub' : 'Azure DevOps',
		},
	],
	key: key,
});

const getRepositoryItems = ({
	isGithubProvider,
	cardTitle = '',
	connectionName,
	adoOrganization,
	adoProjectName,
	repository,
	key,
}: {
	isGithubProvider: boolean;
	cardTitle?: string;
	connectionName: string;
	adoOrganization?: string;
	adoProjectName?: string;
	repository: string;

	key: number;
}) => ({
	key,
	stepNumber: 3,
	cardTitle: `${repo}${cardTitle}`,
	items: [
		{
			title: sourceControlConnection,
			titleValue: isGithubProvider ? 'GitHub' : 'Azure DevOps',
			subTitle: connectionName,
		},
		...(!!adoOrganization
			? [{ title: adoOrgNametxt, subTitle: adoOrganization }]
			: []),
		...(!!adoProjectName
			? [{ title: adoProjectNametxt, subTitle: adoProjectName }]
			: []),
		{ title: repoName, subTitle: repository },
	],
});

const getEnvironmentItems = ({
	key,
	cardTitle = '',
	environmentName,
	tenantType,
	deployOnCommit,
	repositoryRelativePath,
	availableCmEnvironmentName,
	showTenantType,
}: {
	key: number;
	cardTitle?: string;
	environmentName: string;
	tenantType: TenantType;
	deployOnCommit: boolean;
	repositoryRelativePath?: string;
	availableCmEnvironmentName?: string;
	showTenantType?: boolean;
}) => ({
	key,
	stepNumber: 4,
	cardTitle: `${envtxt}${cardTitle}`,
	items: [
		{ title: envName, subTitle: environmentName },
		...(!!repositoryRelativePath
			? [{ title: fieldLabel1, subTitle: repositoryRelativePath }]
			: []),
		...(showTenantType
			? [{ title: prodEnv, subTitle: tenantType ? 'Yes' : 'No' }]
			: []),
		...(!!availableCmEnvironmentName
			? [
					{
						title: availableEnvironments,
						subTitle: availableCmEnvironmentName,
					},
				]
			: []),
		{
			title: autoDeploy,
			subTitle: deployOnCommit ? 'Yes' : 'No',
		},
	],
});

export const useReviewCardList = () => {
	const {
		values,
		isDualJourney,
		isGithubProvider,
		isMonoRepo,
		isOwnCode,
		isEhProject,
		isADOProvider,
	} = useCreateProject();
	const {
		name,
		ehProjectname,
		cmProjectname,
		repository,
		cmRepository,
		ehRepository,
		environmentName,
		cmEnvironmentName,
		ehEnvironmentName,
		tenantType,
		deployOnCommit,
		cmDeployOnCommit,
		ehDeployOnCommit,
		connectionName,
		cmRepositoryRelativePath,
		ehRepositoryRelativePath,
		availableCmEnvironmentName,
		repositoryRelativePath,
		adoOrganization,
		adoProjectName,
		cmAdoOrganization,
		cmAdoProjectName,
		ehAdoOrganization,
		ehAdoProjectName,
	} = values;

	const currentRepoReviewList =
		isMonoRepo || !isDualJourney
			? [
					getRepositoryItems({
						isGithubProvider,
						connectionName,
						adoOrganization: isADOProvider ? adoOrganization : '',
						adoProjectName: isADOProvider ? adoProjectName : '',
						repository,
						key: 14,
					}),
				]
			: [
					getRepositoryItems({
						isGithubProvider,
						cardTitle: `: ${contentService}`,
						connectionName,
						repository: cmRepository,
						adoOrganization: isADOProvider ? cmAdoOrganization : '',
						adoProjectName: isADOProvider ? cmAdoProjectName : '',
						key: 15,
					}),
					getRepositoryItems({
						isGithubProvider,
						cardTitle: `: ${webApp}`,
						connectionName,
						repository: ehRepository,
						adoOrganization: isADOProvider ? ehAdoOrganization : '',
						adoProjectName: isADOProvider ? ehAdoProjectName : '',
						key: 16,
					}),
				];

	const reviewList = isDualJourney
		? [
				getProjectNameItem({
					cardTitle: `Project: ${contentService}`,
					name: cmProjectname,
					key: 1,
				}),
				getProjectNameItem({
					cardTitle: `Project: ${webApp}`,
					name: ehProjectname,
					key: 2,
				}),
				getSourceControlItem(isGithubProvider, 3),
				...currentRepoReviewList,
				getEnvironmentItems({
					cardTitle: `: ${contentService}`,
					environmentName: cmEnvironmentName,
					tenantType,
					deployOnCommit: cmDeployOnCommit,
					repositoryRelativePath: isOwnCode
						? cmRepositoryRelativePath
						: '',
					key: 4,
					showTenantType: true,
				}),
				getEnvironmentItems({
					cardTitle: `: ${webApp}`,
					environmentName: ehEnvironmentName,
					tenantType,
					deployOnCommit: ehDeployOnCommit,
					repositoryRelativePath: isOwnCode
						? ehRepositoryRelativePath
						: '',
					showTenantType: false,
					key: 5,
				}),
			]
		: [
				getProjectNameItem({
					name,
					key: 1,
				}),
				getSourceControlItem(isGithubProvider, 2),
				...currentRepoReviewList,
				getEnvironmentItems({
					environmentName,
					tenantType,
					deployOnCommit,
					repositoryRelativePath: isOwnCode
						? repositoryRelativePath
						: '',
					showTenantType: !isEhProject,
					availableCmEnvironmentName: isEhProject
						? availableCmEnvironmentName
						: '',
					key: 3,
				}),
			];

	return { reviewList };
};
