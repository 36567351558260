import { app } from '../../../@xmcloud/core/messages/en';
import { useGetStatus } from '../../services/status';
import { IStatusRecord } from '../../models/statusModel';
import { CardBox, LoadingSkeleton, XMCSH3 } from '../../shared-components';
import { IconText } from '../../shared-components/';
import { mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import { Stack } from '@chakra-ui/react';

export const StatusPage: React.FC = () => {
	const { data: statusData, isLoading } = useGetStatus();

	const status = statusData?.data;
	const statusRecords =
		Object.entries(status?.entries || []).map<IStatusRecord>(
			([key, value]) => {
				return {
					name: key,
					status: value.status,
					description: value.description,
				};
			},
		) || [];

	return (
		<Stack pb="14" gap="5" w="100%">
			<XMCSH3 title={app.systemStatus} />
			{isLoading ? (
				<LoadingSkeleton amount={10} height={14} />
			) : (
				status &&
				statusRecords.map((statusRecord) => (
					<CardBox
						key={statusRecord.name}
						title={statusRecord.name}
						description={statusRecord.description}
					>
						<IconText
							IconName={
								statusRecord.status === 'Healthy'
									? mdiCheckCircle
									: mdiAlertCircle
							}
							text={statusRecord.status}
							iconColor={
								statusRecord.status === 'Healthy'
									? 'success'
									: 'danger'
							}
						/>
					</CardBox>
				))
			)}
		</Stack>
	);
};
