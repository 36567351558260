import { Stack, Text } from '@chakra-ui/react';
import { createTransfer } from '../../../../@xmcloud/core/messages/en';
import {
	HeadingLg,
	InputFormControl,
	LoadingSkeleton,
	SelectFormControl,
	XMCSButton,
} from '../../../shared-components';
import { useCreateTransfer } from '../../../../@xmcloud/hooks';
import {
	SOURCE_ENVIRONMENT_ID,
	TARGET_ENVIRONMENT_ID,
	TRANSFER_CONFIGURATION,
	TRANSFER_NAME,
} from '../helpers';
import { useGetTransferConfiguration } from '../../../services/transferConfiguration';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateTransferContext';
import { ITransferConfiguration } from '../../../models/transferConfiguration';
import { useGetEnvironmentsByQuery } from '../../../services/environments';

export const TransferName: React.FC = () => {
	const {
		values,
		errors,
		handleChange,
		next,
		setFieldValue,
		setFieldTouched,
		touched,
		isValid,
		setInitialValues,
	} = useCreateTransfer();
	const { transferId } = useParams<{ transferId: string }>();
	const { data: configuration, isLoading: isLoadingConfiguration } =
		useGetTransferConfiguration({ id: transferId ?? '' });
	const {
		transferDetailsStepTitle,
		transferDetailsNameField,
		transferDetailsTargetEnvironmentField,
		transferDetailsSourceEnvironmentField,
		createTransferNextButtonTitle,
		transferDetailsNamePlaceholder,
		transferDetailsSourceEnvironmentPlaceholder,
		transferDetailsTargetEnvironmentPlaceholder,
	} = createTransfer;

	const { data: environments, isLoading: environmentIsLoading } =
		useGetEnvironmentsByQuery({});

	const environmentOptions =
		environments?.map((environment) => ({
			label: environment.name,
			value: environment.id,
		})) || [];

	const clearConfiguration = () => {
		setFieldValue(TRANSFER_CONFIGURATION, {
			transferId: '',
			nodes: [],
			sourceEnvironmentId: '',
			targetEnvironmentId: '',
			name: '',
		} as ITransferConfiguration);
	};
	useEffect(() => {
		const init: FormikValues = {
			transferName: '',
			sourceEnvironmentId: '',
			targetEnvironmentId: '',

			configuration: {
				transferId: '',
				nodes: [],
				sourceEnvironmentId: '',
				targetEnvironmentId: '',
				name: '',
			},
		};

		if (transferId && configuration?.data && environments) {
			setFieldTouched(TRANSFER_NAME, true);
			init.transferName = configuration?.data.name;
			init.configuration.nodes.push(...configuration.data.nodes);
			if (
				environments?.some(
					(env) => env.id === configuration.data.sourceEnvironmentId,
				)
			) {
				setFieldTouched(SOURCE_ENVIRONMENT_ID, true);

				init.sourceEnvironmentId =
					configuration.data.sourceEnvironmentId;
			} else {
				// TODO: replace this placeholder after design completed
				alert(
					`The environment with id: ${configuration.data.sourceEnvironmentId} does not exist!`,
				);
			}
			if (
				environments?.some(
					(env) => env.id === configuration.data.targetEnvironmentId,
				)
			) {
				setFieldTouched(TARGET_ENVIRONMENT_ID, true);

				init.targetEnvironmentId =
					configuration.data.targetEnvironmentId;
			} else {
				// TODO: replace this placeholder after design completed
				alert(
					`The environment with id: ${configuration.data.targetEnvironmentId} does not exist!`,
				);
			}
		}

		setInitialValues(init);
	}, [
		configuration?.data,
		environments,
		setFieldTouched,
		setInitialValues,
		transferId,
	]);

	async function handleNext() {
		if (errors.transferName) {
			setFieldTouched(TRANSFER_NAME, true);
			return;
		}
		if (errors.sourceEnvironmentId) {
			setFieldTouched(SOURCE_ENVIRONMENT_ID, true);
			return;
		}
		if (errors.targetEnvironmentId) {
			setFieldTouched(TARGET_ENVIRONMENT_ID, true);
			return;
		}

		next(transferId);
	}

	return (
		<Stack>
			<HeadingLg text={transferDetailsStepTitle} />
			{transferId && isLoadingConfiguration ? (
				<LoadingSkeleton amount={3} height={70} />
			) : (
				<>
					<Stack py="6" maxW="sm">
						<InputFormControl
							{...{
								isInvalid: Boolean(
									errors.transferName && touched.transferName,
								),
								label: transferDetailsNameField,
								name: TRANSFER_NAME,
								value: values.transferName,
								onChange: handleChange,
								error: errors.transferName,
								onFocus: () =>
									setFieldTouched(TRANSFER_NAME, true),
							}}
						/>
						<Text variant="small">
							{transferDetailsNamePlaceholder}
						</Text>
						<SelectFormControl
							{...{
								isInvalid: Boolean(
									errors.sourceEnvironmentId &&
										touched.sourceEnvironmentId,
								),
								isLoading: environmentIsLoading,
								options: environmentOptions,
								onChange: (e: any) => {
									if (transferId) {
										clearConfiguration();
									}
									setFieldValue(
										SOURCE_ENVIRONMENT_ID,
										e.value,
									);
								},
								label: transferDetailsSourceEnvironmentField,
								name: SOURCE_ENVIRONMENT_ID,
								currentValue: values.sourceEnvironmentId,
								error: errors.sourceEnvironmentId,
								onFocus: () =>
									setFieldTouched(
										SOURCE_ENVIRONMENT_ID,
										true,
									),
							}}
						/>
						<Text variant="small">
							{transferDetailsSourceEnvironmentPlaceholder}
						</Text>
						<SelectFormControl
							{...{
								isInvalid: Boolean(
									errors.targetEnvironmentId &&
										touched.targetEnvironmentId,
								),
								isLoading: environmentIsLoading,
								// TODO: filter !=sourceId
								options: environmentOptions,
								onChange: (e: any) => {
									if (transferId) {
										clearConfiguration();
									}
									setFieldValue(
										TARGET_ENVIRONMENT_ID,
										e.value,
									);
								},
								label: transferDetailsTargetEnvironmentField,
								name: TARGET_ENVIRONMENT_ID,
								currentValue: values.targetEnvironmentId,
								error: errors.targetEnvironmentId,
								onFocus: () =>
									setFieldTouched(
										TARGET_ENVIRONMENT_ID,
										true,
									),
							}}
						/>
						<Text variant="small">
							{transferDetailsTargetEnvironmentPlaceholder}
						</Text>
					</Stack>

					<Stack>
						<XMCSButton
							disabled={!isValid}
							text={createTransferNextButtonTitle}
							onClick={handleNext}
							alignSelf="start"
							size="md"
						/>
					</Stack>
				</>
			)}
		</Stack>
	);
};

export default TransferName;
