import { SIZE_QUERY_PARAMETER } from '../../utils/constants';
import { appendQueryStringToUrl } from '../../utils/helpers';

const mainPaths = {
	home: '/',
	projects: '/projects',
	project: '/projects/:projectId/',
	environment: '/projects/:projectId/environments/:environmentId/',
	deployment:
		'/projects/:projectId/environments/:environmentId/deployments/:deploymentId',
	deployments: '/deployments',
	credentials: '/credentials',
	systemStatus: '/system-status',
	contenttransfer: '/content-transfer',
	createTransfer: '/create-transfer',
	transferDetails: '/transfer-details/:transferId',
	status: 'status',
	documentation: '/documentation',
	connections: '/connections',
	settings: '/settings',
	accessDenied: '/access-denied',
	vercelInstall: '/vercel-install',
	gitHubCallback: '/github-callback',
	adoCallback: '/azure-callback',
	createProject: '/create-project',
	oldEnvUrls: '/environment/:environmentId/*',
	oldDepUrls: '/deployment/:deploymentId/*',
	oldProjectUrls: '/project/:projectId/project-environments/*',
	oldCmLogUrl:
		'/project/:projectId/environment/:environmentId/cm-instance-logs/:type/*',
	oldProEnvUrls: '/project/:projectId/environment/:environmentId/*',
	oldEnvDepUrl:
		'/project/:projectId/environmentdeployment/:environmentId/logs/:deploymentId/*',
	noMatch: '/404',
	error401: '/401',
} as const;

// Path: /transfer-details
const transferDetailsPaths = {
	status: 'status',
	details: 'details',
} as const;

// Path: /projects/:projectId/
const projectPaths = {
	environments: 'environments',
	projectDetails: 'project-details',
	editProject: 'edit-project',
} as const;

// Path: /projects/:projectId/environments/:environmentId/
const environmentPath = {
	deployments: 'deployments',
	sites: 'sites',
	details: 'details',
	cmLogTypes: 'log-types',
	cmLogs: `log-types/:type/logs`,
	variables: 'variables',
	devSettings: 'developer-settings',
	logs: '/logs',
} as const;

// Path: /create-project
const createProjectPaths = {
	choosePath: 'choose-path',
	combinedSteps: 'combined/:step', //RM:current combined steps, it will remove after the combined steps are removed
	dualSteps: 'dual/:step',
	ehSteps: 'eh/:step',
	cmSteps: 'cm/:step',
	create: '/create-project',
	combined: '/combined', //RM:current combined steps, it will remove after the combined steps are removed
	dual: '/dual',
	cm: '/cm',
	eh: '/eh',
} as const;

// Path: /create-transfer
const createTransferPaths = {
	createTransferSteps: 'step/:step',
	transferAgain: 'retransfer/:transferId/step/:step',
} as const;
// Path: /credentials
const credentialsPaths = {
	environment: 'environment',
	organization: 'organization',
} as const;

// Path: /connections
const connectionsPaths = {
	sourcecontrol: 'sourcecontrol',
	hosting: 'hosting',
} as const;

const goTo = {
	main: '/',
	projects: '/projects',
	allDeployments: '/deployments',
	environments: (projectId: string) => `/projects/${projectId}/environments`,
	projectDetails: (projectId: string) =>
		`/projects/${projectId}/project-details`,
	deployments: (projectId: string, environmentId: string) =>
		`/projects/${projectId}/environments/${environmentId}/${environmentPath.deployments}`,
	sites: (projectId: string, environmentId: string) =>
		`/projects/${projectId}/environments/${environmentId}/${environmentPath.sites}`,
	details: (projectId: string, environmentId: string) =>
		`/projects/${projectId}/environments/${environmentId}/${environmentPath.details}`,
	cmInstanceLogTypes: (projectId: string, environmentId: string) =>
		`/projects/${projectId}/environments/${environmentId}/${environmentPath.cmLogTypes}`,
	cmInstanceLogs: (
		projectId: string,
		environmentId: string,
		type: string,
		fileSize?: number,
	) => {
		const url = `/projects/${projectId}/environments/${environmentId}/log-types/${type}/logs`;
		return appendQueryStringToUrl(url, SIZE_QUERY_PARAMETER, fileSize);
	},
	variables: (projectId: string, environmentId: string) =>
		`/projects/${projectId}/environments/${environmentId}/${environmentPath.variables}`,
	devSettings: (projectId: string, environmentId: string) =>
		`/projects/${projectId}/environments/${environmentId}/${environmentPath.devSettings}`,
	transferStatus: (transferId: string) =>
		`/transfer-details/${transferId}/status`,
	transferDetails: (transferId: string) =>
		`/transfer-details/${transferId}/details`,
	transferAgain: (transferId: string, step = 1) =>
		`/create-transfer/retransfer/${transferId}/step/${step}`,
	deploymentLogs: (
		projectId: string,
		environmentId: string,
		deploymentId: string,
	) =>
		`/projects/${projectId}/environments/${environmentId}/deployments/${deploymentId}`,
	organizationCredentials: () => '/credentials/organization',
	environmentCredentials: () => '/credentials/environment',
	sourceControl: () => '/connections/sourcecontrol',
	hosting: () => '/connections/hosting',
	createProjectTemplate: (page = 1) => `/create-project/combined/${page}`,
	createCMProject: (page = 1) => `/create-project/cm/${page}`,
	createFrontendProject: (page = 1) => `/create-project/eh/${page}`,
	createTransferTo: (page = 1) => `/create-transfer/step/${page}`,
	createDualProject: (page = 1) => `/create-project/dual/${page}`,
	choosePath: `${createProjectPaths.create}/${createProjectPaths.choosePath}`,
	createTransferPath: '/create-transfer/step/',
	editProject: (projectId: string) => `/projects/${projectId}/edit-project`,
	systemStatus: '/system-status',
};

// old url paths
const oldPaths = [
	'/environment/:environmentId/details',
	'/deployment/:deploymentId/details',
	'/environment/:environmentId/logs/types',
];

export {
	mainPaths,
	connectionsPaths,
	credentialsPaths,
	projectPaths,
	environmentPath,
	goTo,
	oldPaths,
	createProjectPaths,
	transferDetailsPaths,
	createTransferPaths,
};
