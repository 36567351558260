import { useParams } from 'react-router-dom';
import { CwJourneyType, steps } from './helpers';
import { MemoizedMainTemplate } from './components';

const { combined } = CwJourneyType;

export const CombinedFoundationMain: React.FC = () => {
	const { step: stepParam } = useParams<{ step: string }>();

	return (
		<MemoizedMainTemplate
			{...{
				path: 'createProjectTemplate',
				steps,
				stepParam: stepParam!,
				currentJourney: combined,
			}}
		/>
	);
};
