import {
	commonCw,
	fifthStep,
	firstStep,
} from '../../../@xmcloud/core/messages/en';
import { GoToKeys } from '../../../@xmcloud/types';
import {
	ResponseStatusEnum,
	ResponseStatusTypes,
} from '../../../@xmcloud/utils/helpers';

export const REPOSITORY = 'repository';
export const SOURCE_CONTROL_INTEGRATION_ID = 'sourceControlIntegrationId';
export const PROVIDER = 'provider';
export const IS_SECOND_STEP_REQUIRED = 'isSecondStepRequired';
export const CONNECTION_NAME = 'connectionName';
export const REPO_BRANCH = 'repositoryBranch';
export const PROJECT_NAME = 'name';
export const ENVIRONMENT_NAME = 'environmentName';
export const TENANT_TYPE = 'tenantType';
export const DEPLOY_ON_COMMIT = 'deployOnCommit';
export const LINK_ENV_TO_DEFAULT_BRANCH = 'linkEnvironmentToDefaultBranch';
export const ACCOUNT_TYPE = 'accountType';
export const ADO_PROJECT_NAME = 'adoProjectName';
export const ADO_ORGANIZATION = 'adoOrganization';
export const REPOSITORY_ID = 'repositoryId';
export const PROJECT_TYPE = 'projectType';
export const REPOSITORY_RELATIVE_PATH = 'repositoryRelativePath';
export const CM_ENVIRONMENT_ID = 'cmEnvironmentId';
export const IS_USE_OWN_CODE = 'isUseOwnCode';
export const NAME = 'name';
export const ACCOUNT = 'account';
export const PROVIDER_NAME = 'providerName';
export const ENVIRONMENTS = 'environments';
export const HAS_ACCOUNT = 'hasAccount';
export const JOURNEY = 'journey';
export const IS_MONO_REPO = 'isMonoRepo';

export const CM_PROJECT_NAME = 'cmProjectname';
export const EH_PROJECT_NAME = 'ehProjectname';

export const CM_REPOSITORY = 'cmRepository';
export const EH_REPOSITORY = 'ehRepository';

export const CM_REPOSITORY_ID = 'cmRepositoryId';
export const EH_REPOSITORY_ID = 'ehRepositoryId';

export const CM_REPO_BRANCH = 'cmRepositoryBranch';
export const EH_REPO_BRANCH = 'ehRepositoryBranch';

export const CM_ADO_PROJECT_NAME = 'cmAdoProjectName';
export const EH_ADO_PROJECT_NAME = 'ehAdoProjectName';

export const CM_ADO_ORGANIZATION = 'cmAdoOrganization';
export const EH_ADO_ORGANIZATION = 'ehAdoOrganization';

export const CM_ENVIRONMENT_NAME = 'cmEnvironmentName';
export const EH_ENVIRONMENT_NAME = 'ehEnvironmentName';

export const CM_REPOSITORY_RELATIVE_PATH = 'cmRepositoryRelativePath';
export const EH_REPOSITORY_RELATIVE_PATH = 'ehRepositoryRelativePath';

export const CM_DEPLOY_ON_COMMIT = 'cmDeployOnCommit';
export const EH_DEPLOY_ON_COMMIT = 'ehDeployOnCommit';

export const AVAILABLE_CM_ENVIRONMENT_NAME = 'availableCmEnvironmentName';

export const PROJECT_ID = 'projectId';
export const CM_PROJECT_ID = 'cmProjectId';
export const EH_PROJECT_ID = 'ehProjectId';

const { setupRepo, authEnv, reviewFinish, editHost, chooseSourceControls } =
	commonCw;
const { title: nameYourProject, title2: nameYourProjects } = firstStep;
const { info1: adoCreateRepo, createGithubRepoInfo } = fifthStep;

const commonSteps = [
	{ title: nameYourProject, index: 1 },
	{ title: chooseSourceControls, index: 2 },
	{ title: setupRepo, index: 3 },
];

export const steps = [
	...commonSteps,
	{ title: authEnv, index: 4 },
	{ title: reviewFinish, index: 5 },
];

export const frontEndSteps = [
	...commonSteps,
	{ title: editHost, index: 4 },
	{ title: reviewFinish, index: 5 },
];

export const dualSteps = [
	{ title: nameYourProjects, index: 1 },
	{ title: chooseSourceControls, index: 2 },
	{ title: setupRepo, index: 3 },
	{ title: authEnv, index: 4 },
	{ title: reviewFinish, index: 5 },
];

export type TOptions = Array<{
	value: string | boolean | number | null;
	label: string;
	id?: string | number;
}>;

export enum TemplateInNumberEnum {
	COMBINED = 1,
	EH = 2,
	CM = 3,
}

export enum ProjectTypesEnum {
	COMBINED = 'combined', // combined CM and EH
	CM = 'cm', // standalone CM
	EH = 'eh', // front-end
}

export enum OwnCodeEnum {
	OWNCODE = 'owncode',
}

export const goToCreateProjectPaths = {
	combined: 'createProjectTemplate',
	dual: 'createDualProject',
	eh: 'createFrontendProject',
	cm: 'createCMProject',
};

export enum CwJourneyType {
	dual = 'dual',
	eh = 'eh',
	cm = 'cm',
	combined = 'combined', //RM: will be removed
}

export type TCwJourney = keyof typeof CwJourneyType;

export type ExtractGoTo = Extract<
	| 'createProjectTemplate'
	| 'createFrontendProject'
	| 'createCMProject'
	| 'createDualProject',
	GoToKeys
>;
export type TProjectId = 'projectId' | 'cmProjectId' | 'ehProjectId';
export type TProjectName = 'name' | 'cmProjectname' | 'ehProjectname';

export type TRepository = 'repository' | 'cmRepository' | 'ehRepository';

export type TRepositoryId =
	| 'repositoryId'
	| 'cmRepositoryId'
	| 'ehRepositoryId';

export type TRepositoryBranch =
	| 'repositoryBranch'
	| 'cmRepositoryBranch'
	| 'ehRepositoryBranch';

export type TADOProjectName =
	| 'adoProjectName'
	| 'cmAdoProjectName'
	| 'ehAdoProjectName';

export type TADOOrganization =
	| 'adoOrganization'
	| 'cmAdoOrganization'
	| 'ehAdoOrganization';

export type TEnvironmentName =
	| 'environmentName'
	| 'cmEnvironmentName'
	| 'ehEnvironmentName';

export type TRepositoryRelativePath =
	| 'repositoryRelativePath'
	| 'cmRepositoryRelativePath'
	| 'ehRepositoryRelativePath';

export type TDeployOnCommit =
	| 'deployOnCommit'
	| 'cmDeployOnCommit'
	| 'ehDeployOnCommit';

const { CM, EH, COMBINED } = ProjectTypesEnum;

export const hasProjectTypes = (type: string) =>
	CM === type || EH === type || COMBINED === type;

export const isEHProjectType = (type: string) => EH === type;
export const isCombinedProjectType = (type: string) => COMBINED === type;
export const isCMProjectType = (type: string) => CM === type;
export const isDualJourney = (journey: string) =>
	CwJourneyType.dual === journey;

//it is hardcoded to create eh and cm projects by getting the repo name
export const repoForEH = 'xmcloud-foundation-frontend-dev';
export const repoForCM = 'xmcloud-foundation-authoring-dev';

const { IDLE, LOADING, ERROR, SUCCESS } = ResponseStatusEnum;

export const getProcessStatus = (
	...statuses: ResponseStatusTypes[]
): ResponseStatusTypes =>
	statuses.some((s) => s === ERROR)
		? ERROR
		: statuses.some((s) => s === LOADING)
			? LOADING
			: statuses.every((s) => s === SUCCESS)
				? SUCCESS
				: IDLE;

export const handleStatusText = (
	component: string,
	isCreateRepo = false,
	isAdo = false,
) => ({
	[IDLE]: `${component} creation is in queue.`,
	[LOADING]: !isCreateRepo
		? `${component} creation is in progress.`
		: isAdo
			? adoCreateRepo
			: createGithubRepoInfo,
	[ERROR]: `${component} creation is failed.`,
	[SUCCESS]: `${component} creation is successfully completed.`,
});
