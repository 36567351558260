import {
	Text,
	Link as SDSLink,
	MenuItem,
	MenuList,
	Box,
} from '@chakra-ui/react';
import { t } from '@transifex/native';
import { deployments } from '../../../../@xmcloud/core/messages/en';
import {
	DeployProcessStatus,
	IGetDeploymentResponse,
} from '../../../models/deploymentModel';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import { IconText, Link, RowActions } from '../../../shared-components';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import {
	handleHTMLTagLink,
	handlePush,
	handleTo,
} from '../../../../@xmcloud/utils/helpers';
import {
	mdiAlertCircle,
	mdiCheckCircle,
	mdiCircleOutline,
	mdiCloseCircleOutline,
} from '@mdi/js';
import { useMemo } from 'react';
import { StatusHeader } from '../../../shared-components/popover/DeploymentsStatusHeader';
import { createColumnHelper } from '@tanstack/react-table';
import { statusDescriptions } from '../../deployments/components/DeploymentsTable';
const {
	createDate,
	latestDeployment,
	queued,
	inprogress,
	complete,
	failed,
	skipped,
	viewLogs,
	cancel,
	deployBuildArtifact,
} = deployments;

export type TDeployments = IGetDeploymentResponse & { status: string };
const getReadableName = (
	createdAt: string | undefined,
	projectId: string,
	environmentId: string,
	id: string,
	status: DeployProcessStatus | undefined,
): JSX.Element => {
	const date = getReadableDate(createdAt);
	if (status === DeployProcessStatus.Skipped) {
		return <Text>{date}</Text>;
	}
	return (
		<Link
			text={date}
			to={handleTo(goTo.deploymentLogs(projectId, environmentId, id))}
		/>
	);
};

const columnHelper = createColumnHelper<TDeployments>();

export const useDeploymentsTableColumns = (
	setDeploymentId: (id: string) => void,
	setDeployToOtherEnvModal: (value: boolean) => void,
	setshowCancelDeploymentModal: (value: boolean) => void,
) => {
	return useMemo(
		() => [
			columnHelper.accessor('createdAt', {
				header: () => t(createDate),
				cell: ({
					row: {
						original: {
							createdAt,
							projectId,
							environmentId,
							id,
							calculatedStatus,
						},
					},
				}) =>
					getReadableName(
						createdAt,
						projectId,
						environmentId,
						id,
						calculatedStatus,
					),
			}),
			columnHelper.accessor('triggerMessage', {
				header: () => t(latestDeployment),
				cell: ({
					row: {
						original: { triggerMessage },
					},
				}) => {
					const isHTMLTag =
						triggerMessage && triggerMessage.startsWith('<a');
					const aTag =
						triggerMessage && handleHTMLTagLink(triggerMessage);

					return (
						<Text noOfLines={2} whiteSpace="wrap">
							{isHTMLTag ? (
								<SDSLink
									href={(aTag && aTag.link) ?? ''}
									isExternal
									boxShadow="none !important"
								>
									{aTag && aTag.text}
								</SDSLink>
							) : (
								triggerMessage
							)}
						</Text>
					);
				},
			}),
			columnHelper.accessor('calculatedStatus', {
				header: () => <StatusHeader allStatus={statusDescriptions} />,
				cell: ({
					row: {
						original: { calculatedStatus },
					},
				}) => {
					const status = statusIconsTextList[calculatedStatus];
					return (
						<IconText
							{...{
								text: status.text,
								...(status?.isIndeterminate && {
									isIndeterminate: true,
								}),
								...(status?.IconName && {
									IconName: status.IconName,
								}),
								...(status?.iconColor && {
									iconColor: status.iconColor,
								}),
							}}
						/>
					);
				},
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({
					row: {
						original: {
							calculatedStatus,
							projectId,
							environmentId,
							id,
						},
					},
				}) =>
					calculatedStatus === DeployProcessStatus.Skipped ? (
						<Box h="10" />
					) : (
						<>
							<RowActions ariaLabel="environment-deployment-table-action-menu">
								<MenuList>
									<MenuItem
										onClick={() => {
											handlePush(
												goTo.deploymentLogs(
													projectId,
													environmentId,
													id,
												),
											);
										}}
									>
										{t(viewLogs)}
									</MenuItem>
									{Boolean(
										calculatedStatus ===
											DeployProcessStatus.InProgress ||
											calculatedStatus ===
												DeployProcessStatus.NotStarted,
									) && (
										<MenuItem
											onClick={() => {
												setDeploymentId(id);
												setshowCancelDeploymentModal(
													true,
												);
											}}
										>
											{t(cancel)}
										</MenuItem>
									)}
									{calculatedStatus ===
										DeployProcessStatus.Complete && (
										<MenuItem
											onClick={() => {
												setDeploymentId(id);
												setDeployToOtherEnvModal(true);
											}}
										>
											{t(deployBuildArtifact)}
										</MenuItem>
									)}
								</MenuList>
							</RowActions>
						</>
					),
				maxSize: 0,
			}),
		],
		[
			setDeploymentId,
			setDeployToOtherEnvModal,
			setshowCancelDeploymentModal,
		],
	);
};

export const statusIconsTextList = [
	{ IconName: mdiCircleOutline, text: queued, iconColor: 'warning' },
	{ isIndeterminate: true, text: inprogress },
	{ IconName: mdiCheckCircle, text: complete, iconColor: 'success' },
	{ IconName: mdiAlertCircle, text: failed, iconColor: 'danger' },
	{
		IconName: mdiCloseCircleOutline,
		text: skipped,
		iconColor: 'neutral',
	},
];
