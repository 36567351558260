import { Stack, Tooltip } from '@chakra-ui/react';
import { XMCSButton } from '../../../shared-components';
import { commonCw } from '../../../../@xmcloud/core/messages/en';

interface Props {
	onPreviousClick: () => void;
	onNextClick: () => void;
	isLoading?: boolean;
	py?: number;
	type?: 'button' | 'submit' | 'reset';
	rightBtnText?: string;
	isDisabled?: boolean;
	tooltiptxt?: string;
	showPreviousBtn?: boolean;
}
const { previous, continue: continueText } = commonCw;

export const FooterBtnGroups: React.FC<Props> = ({
	onPreviousClick,
	onNextClick,
	isLoading,
	py = 7,
	type = 'button',
	rightBtnText = continueText,
	tooltiptxt = '',
	isDisabled,
	showPreviousBtn = true,
}) => (
	<Stack py={py} direction="row">
		{showPreviousBtn && (
			<XMCSButton
				text={previous}
				onClick={onPreviousClick}
				size="md"
				variant="outline"
			/>
		)}
		<Tooltip label={tooltiptxt} shouldWrapChildren isDisabled={!isDisabled}>
			<XMCSButton
				text={rightBtnText}
				onClick={onNextClick}
				size="md"
				isLoading={isLoading}
				type={type}
				isDisabled={isLoading || isDisabled}
			/>
		</Tooltip>
	</Stack>
);
