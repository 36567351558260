import { IEnvironmentVariableDetails } from '../../../models/environmentModel';
import {
	environmentVariable as enEnvironmentVariable,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { createColumnHelper } from '@tanstack/react-table';
import { RowActions } from '../../../shared-components';
import { MenuItem, MenuList, Tooltip } from '@chakra-ui/react';

const {
	errMsg4,
	configuration: configurationtxt,
	editDeleteVariable: editDeleteVariabletxt,
} = systemHealthStatus;
const {
	name: nametxt,
	value: valuetxt,
	secret: secrettxt,
	target: targettxt,
	targetAll: targetAlltxt,
	edit: edittxt,
	delete: deletetxt,
} = enEnvironmentVariable;

const columnHelper = createColumnHelper<IEnvironmentVariableDetails>();

export const variablesTableColumns = (
	setSelectedVariable: (variable: IEnvironmentVariableDetails) => void,
	setEditVariableModalIsOpen: (isOpen: boolean) => void,
	setShowDeleteModal: (isOpen: boolean) => void,
	isConfigurationHealthy: boolean,
) => {
	return [
		columnHelper.accessor('name', {
			header: () => t(nametxt),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('value', {
			header: () => t(valuetxt),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('secret', {
			header: () => t(secrettxt),
			cell: (info) => (info.getValue() ? 'Yes' : 'No'),
		}),
		columnHelper.accessor('target', {
			header: () => t(targettxt),
			cell: (info) =>
				Boolean(info.getValue()) ? info.getValue() : targetAlltxt,
			enableSorting: false,
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original: variable } }) => (
				<Tooltip
					label={t(errMsg4, {
						variableTxt: configurationtxt,
						variableTxt2: editDeleteVariabletxt,
					})}
					isDisabled={isConfigurationHealthy}
					shouldWrapChildren
				>
					<RowActions
						ariaLabel="variables-table-action-menu"
						isDisabled={!isConfigurationHealthy}
						data-testid="variables-table-action-menu"
					>
						<MenuList>
							<MenuItem
								onClick={() => {
									setSelectedVariable(variable);
									setEditVariableModalIsOpen(true);
								}}
							>
								{t(edittxt)}
							</MenuItem>

							<MenuItem
								onClick={() => {
									setSelectedVariable(variable);
									setShowDeleteModal(true);
								}}
							>
								{t(deletetxt)}
							</MenuItem>
						</MenuList>
					</RowActions>
				</Tooltip>
			),
			maxSize: 0,
		}),
	];
};
