import { Stack, Link, Divider } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
	app,
	environment as envLabels,
	environments as environmentsLabels,
} from '../../../../@xmcloud/core/messages/en';
import {
	HeadingMd,
	Icon,
	IconText,
	LoadingSkeleton,
	TextBox,
} from '../../../shared-components';
import { mdiLink, mdiSourceBranch } from '@mdi/js';
import { t } from '@transifex/native';
import { mdiOpenInNew } from '@mdi/js';
import EdgeToken from '../components/EdgeToken';
import {
	useEnvironmentDetails,
	useProjectDetails,
} from '../../../../@xmcloud/hooks';
import { ApiTokenComponent } from '../components/EdgeAPIToken';
import EditingSecret from '../components/EditingSecret';
import { useFeature } from '../../../feature-flag/features';
import { ProjectTypesEnum } from '../../create-project/helpers';
import { ContextID } from '../components/ContextID';

export const EnvironmentDetails: React.FC = () => {
	const { environmentId, projectId } = useParams<{
		environmentId: string;
		projectId: string;
	}>();
	const createProjectV2 = useFeature('CreateProjectV2');

	const { environment, isLoading: isEnvironmentLoading } =
		useEnvironmentDetails(environmentId!);

	const {
		project: projectDetails,
		isLoading: projectIsLoading,
		projectType,
	} = useProjectDetails(projectId!);

	const project = projectDetails?.data;
	const environmentDetails = environment;
	const isLoading = projectIsLoading || isEnvironmentLoading;
	const isEHProject = projectType === ProjectTypesEnum.EH;
	const previewContextId = environmentDetails?.previewContextId ?? '';
	const liveContextId = environmentDetails?.liveContextId ?? '';

	if (isLoading) return <LoadingSkeleton height={36} amount={12} mt={10} />;

	const additionalProjectDetails = [
		{
			title: envLabels.type,
			subTitle:
				environmentDetails.tenantType === 'prod'
					? 'Production'
					: 'Non production',
		},
		{ title: envLabels.region, subTitle: project?.region },
		{
			title: envLabels.baseImageVersion,
			subTitle: `${environmentDetails.sitecoreMajorVersion}.${environmentDetails.sitecoreMinorVersion}`,
		},
	];

	const details = [
		{ title: envLabels.id, subTitle: environmentDetails.id },
		...(isEHProject ? [] : additionalProjectDetails),
	];

	const authoringGraphQlEndpoint = `https://${environmentDetails.host}/sitecore/api/authoring/graphql/playground`;
	const dashboardUrl = `https://${environmentDetails.host}/sitecore`;

	return (
		<>
			<Stack spacing={5} mt={5} pl={5} pb={[20, 20, 12, 12]}>
				<HeadingMd text={app.details} />
				<Stack>
					{environmentDetails.repositoryBranch ? (
						<IconText
							text={environmentDetails.repositoryBranch}
							IconName={mdiLink}
							iconSize={5}
							iconColor="neutral"
						/>
					) : null}
					{environmentDetails.deployOnCommit ? (
						<IconText
							text={environmentsLabels.autoDeploy}
							IconName={mdiSourceBranch}
							iconSize={5}
							iconColor="neutral"
						/>
					) : null}
				</Stack>
				<ContextID {...{ previewContextId, liveContextId }} />
				{details.map(({ title, subTitle }) => (
					<TextBox {...{ title, subTitle }} key={title} />
				))}
				<Divider />
				<HeadingMd text={envLabels.hostNamesAndUrls} />
				<TextBox
					title={envLabels.hostName}
					subTitle={environmentDetails.host}
					key={envLabels.hostName}
				/>
				<TextBox
					title={envLabels.dashboard}
					subTitle={dashboardUrl}
					key={envLabels.dashboard}
					isLink={true}
					iconSize={14}
					linkIcon={mdiOpenInNew}
					subTitleFontWeight="semibold"
					href={dashboardUrl}
				/>
				<TextBox
					subTitle=""
					title={envLabels.authoringGraphQlEndpoint}
					key={envLabels.authoringGraphQlEndpoint}
					linkIcon={mdiOpenInNew}
					tooltip={envLabels.authoringGraphQlEndpointTooltip}
				>
					<Link
						isExternal
						href={authoringGraphQlEndpoint}
						boxShadow="none !important"
						fontWeight={'semibold'}
					>
						{t(envLabels.launchIDE)}
						<Icon path={mdiOpenInNew} ml={1} />
					</Link>
				</TextBox>
				<ApiTokenComponent host={environmentDetails.host} />
				<EdgeToken />
				{createProjectV2 && isEHProject && <EditingSecret />}
				<Divider />
			</Stack>
		</>
	);
};
