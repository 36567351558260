import { useCreateProject } from '../../../../@xmcloud/hooks';
import { useOLDGithubValidation } from './useOLDGithubValidation';
import { useValidateSourceControlIntegrationV2 } from '../../../services/repositories';
import { OLDExistingConnection } from './OLDExstingConnection';

export const OLDExistingGithubConnection: React.FC = () => {
	const { values, errors, setFieldTouched, setFieldError, next } =
		useCreateProject();

	const sourceControlIntegrationId = values.sourceControlIntegrationId;

	const { data: validationData, isFetching: isLoadingIntegrationId } =
		useValidateSourceControlIntegrationV2({
			integrationId: sourceControlIntegrationId,
			enabled: !!sourceControlIntegrationId,
		});

	const errorMsg = validationData?.data?.message || '';
	const isIntegrationIdValidationValid = !validationData
		? true
		: validationData.data.isValid;

	const { handleNext, isLoading: validationIsLoading } =
		useOLDGithubValidation(
			values,
			errors,
			setFieldTouched,
			setFieldError,
			next,
		);
	const isLoading = validationIsLoading || isLoadingIntegrationId;

	return (
		<OLDExistingConnection
			{...{
				handleNext,
				isLoading,
				errorMsg,
				isIntegrationIdValidationValid,
				isLoadingIntegrationId,
			}}
		/>
	);
};
