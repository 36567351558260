import * as yup from 'yup';
import {
	firstStep,
	projects,
	environments,
	thirdStep,
	secondStep,
	fourthStep,
	editProject,
} from '../core/messages/en';
import { ENVIRONMENT_NAME_VALIDATION_PATTERN } from '../utils/constants';

const { requiredName } = projects;
const { maxCharacters, notSameProjectName, notSameRepoName } = firstStep;
const { requiredEnvironmentName, invalidEnvironmentName } = environments;
const { maxCharacters: envMaxCharacters } = thirdStep;
const {
	repoRequired,
	orgRequired,
	projectNameRequired,
	repositoryNameValidationMessage,
} = secondStep;
const { pathRequired, requriedCmEnv } = fourthStep;
const { forbiddenChars, cannotEndWith } = editProject;

const notRequired = () => yup.string().notRequired();

const handleName = (isRequired: boolean) =>
	isRequired
		? yup
				.string()
				.required(requiredName)
				.max(100, maxCharacters)
				.testForbidChars(forbiddenChars)
				.endsWithForbidChars(cannotEndWith)
		: notRequired();

const validateRepository = (isRequired: boolean) =>
	isRequired
		? yup
				.string()
				.required(repoRequired)
				.matches(
					/^[A-Za-z0-9_.-]{1,100}$/,
					repositoryNameValidationMessage,
				)
		: notRequired();

const validateAdoProjectName = (isRequired: boolean) =>
	isRequired ? yup.string().required(projectNameRequired) : notRequired();

const validateAdoOrganization = (isRequired: boolean) =>
	isRequired ? yup.string().required(orgRequired) : notRequired();
const validateEnvironment = (isRequired: boolean) =>
	isRequired
		? yup
				.string()
				.required(requiredEnvironmentName)
				.max(100, envMaxCharacters)
				.matches(
					new RegExp(ENVIRONMENT_NAME_VALIDATION_PATTERN),
					invalidEnvironmentName,
				)
		: notRequired();
const validateRelativePath = (isRequired: boolean) =>
	isRequired ? yup.string().required(pathRequired) : notRequired();

export const createProjectValidationSchema = ({
	isADOProvider,
	isEHProject,
	isMonoRepo,
	isOwnCode,
	isDualJourney,
}: {
	isADOProvider: boolean;
	isEHProject: boolean;
	isMonoRepo: boolean;
	isOwnCode: boolean;
	isDualJourney: boolean;
}) =>
	yup.object({
		name: handleName(!isDualJourney),
		cmProjectname: handleName(isDualJourney),
		ehProjectname: handleName(isDualJourney).notOneOf(
			[yup.ref('cmProjectname'), null],
			notSameProjectName,
		),
		repository: validateRepository(isMonoRepo),
		cmRepository: validateRepository(!isMonoRepo),
		ehRepository: isOwnCode
			? validateRepository(!isMonoRepo)
			: validateRepository(!isMonoRepo).notOneOf(
					[yup.ref('cmRepository'), null],
					notSameRepoName,
				),
		adoProjectName: validateAdoProjectName(isADOProvider && isMonoRepo),
		cmAdoProjectName: validateAdoProjectName(isADOProvider && !isMonoRepo),
		ehAdoProjectName: validateAdoProjectName(isADOProvider && !isMonoRepo),
		adoOrganization: validateAdoOrganization(isADOProvider && isMonoRepo),
		cmAdoOrganization: validateAdoOrganization(
			isADOProvider && !isMonoRepo,
		),
		ehAdoOrganization: validateAdoOrganization(
			isADOProvider && !isMonoRepo,
		),
		repositoryRelativePath: validateRelativePath(
			isOwnCode && !isDualJourney,
		),
		cmRepositoryRelativePath: validateRelativePath(
			isOwnCode && isDualJourney,
		),
		ehRepositoryRelativePath: validateRelativePath(
			isOwnCode && isDualJourney,
		),
		environmentName: validateEnvironment(!isDualJourney),
		cmEnvironmentName: validateEnvironment(isDualJourney),
		ehEnvironmentName: validateEnvironment(isDualJourney),
		cmEnvironmentId: isEHProject
			? yup.string().required(requriedCmEnv)
			: notRequired(),
	});
