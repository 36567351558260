import {
	Stack,
	Box,
	Alert,
	AlertIcon,
	AlertDescription,
	AlertTitle,
	Spacer,
	Button,
	HStack,
	Text,
	Link,
} from '@chakra-ui/react';
import {
	commonCw,
	createTransfer,
	transferDetails,
} from '../../../../@xmcloud/core/messages/en';
import { useCreateTransfer } from '../../../../@xmcloud/hooks';
import {
	ComplexTextBox,
	HeadingMd,
	Icon,
	LoadingSkeleton,
	XMCSButton,
} from '../../../shared-components';
import { useGetProjectEnvironmentsList } from '../../../services/projects';
import { t } from '@transifex/native';
import {
	TCalculatedStatus,
	calculatedAlertStatus,
	getMergeStrategyLabel,
} from '../../contenttransfer/handlers';
import {
	ITransferConfiguration,
	ITransferConfigurationNode,
	TransferConfigurationNodeType,
} from '../../../models/transferConfiguration';
import { getMissedNodes, getMissedPaths, isRoot } from './utils/helpers';
import { useEffect, useState } from 'react';
import { TransferValidationStatus } from '../../../models/transferModel';
import { useGetContent } from '../../../services/contentProvider';
import { IContentTreeNode } from '../../../models/contentTreeNode';
import { TRANSFER_CONFIGURATION } from '../helpers';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { createIcon } from '../../../shared-components/icons/handlers';
import { useParams } from 'react-router';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import { mdiForward, mdiInformationOutline } from '@mdi/js';

export const ReviewAndTransfer: React.FC = () => {
	const { transferId } = useParams<{ transferId: string }>();
	const { prev, values, setFieldValue } = useCreateTransfer();
	const { data: environments, isLoading: environmentIsLoading } =
		useGetProjectEnvironmentsList({
			projectId: 'test',
			enabled: true,
		});
	const [validationStatus, setStatus] = useState(
		TransferValidationStatus.Failed,
	);
	const [missedNodes, setMissedNodes] = useState<
		ITransferConfigurationNode[]
	>([]);
	const [resolvedMissedParents, setResolvedMissedParents] = useState(false);
	const [hasMissedParents, setHasMissedPrents] = useState(false);
	const source = environments?.data.find(
		(en) => en.id === values.sourceEnvironmentId,
	);
	const target = environments?.data.find(
		(en) => en.id === values.targetEnvironmentId,
	);

	const { data: sourceContent, isLoading: isContentLoading } = useGetContent({
		id: 'sourceEnvironmentId',
	});
	const { data: targetContent, isLoading: isTargetContentLoading } =
		useGetContent({
			id: 'targetEnvironmentId',
		});
	const { previous } = commonCw;
	const originalNodes: ITransferConfigurationNode[] =
		values.configuration?.nodes.map((node) => {
			if (isRoot(node)) {
				return {
					id: node.id,
					item: node.item,
					mergeStrategy: node.mergeStrategy,
					type: TransferConfigurationNodeType.Tree,
					path: '',
				};
			}
			return {
				id: node.id,
				item: node.item,
				mergeStrategy: node.mergeStrategy,
				type: node.type,
				path: node.path,
			};
		});
	const calculatedStatus: TCalculatedStatus = validationStatus ?? 5;
	const status = calculatedAlertStatus[calculatedStatus];
	useEffect(() => {
		if (sourceContent?.data && targetContent?.data) {
			const segments = getMissedPaths(values.configuration);
			const potentialMissedParents = getMissedNodes(
				targetContent?.data as IContentTreeNode,
				segments,
			);
			const originalParents = getMissedNodes(
				sourceContent?.data as IContentTreeNode,
				segments,
			);
			const missedParents = originalParents.filter(
				(original) =>
					!potentialMissedParents.some(
						(node) => node.path === original.path,
					),
			);
			if (missedParents?.length) {
				const checkedParents = missedParents.filter(
					(missedParent) =>
						!values.configuration.nodes.some(
							(existedNode) =>
								existedNode.path === missedParent.path,
						),
				);
				if (checkedParents?.length) {
					// TODO: inherit child merge strategy
					setMissedNodes(checkedParents);
					setHasMissedPrents(true);
				}
			}
		}
	}, [sourceContent, targetContent, values.configuration]);

	const handleNext = () => {
		const configuration: ITransferConfiguration = {
			transferId: transferId ?? '',
			name: values.transferName,
			sourceEnvironmentId: values.sourceEnvironmentId,
			targetEnvironmentId: values.targetEnvironmentId,
			nodes: [],
		};
		configuration.nodes.push(...missedNodes);

		setFieldValue(TRANSFER_CONFIGURATION, configuration);
		// TODO: useStartTransfer();
		// TODO: saveConfiguration();
		handlePush(goTo.transferStatus(values.configuration.transferId));
	};

	const createNode = (
		node: ITransferConfigurationNode,
		prefix: string,
	): JSX.Element => {
		return (
			<Box key={`stack-${prefix}${node.id}`}>
				<ComplexTextBox
					{...{
						primaryTitle: node.item,
						secondaryTitle: '-',
						thirdTitle: getMergeStrategyLabel(node.mergeStrategy!),
						subTitle: node.path,
					}}
					key={`stack-complex-${prefix}${node.id}`}
				>
					{createIcon(node)}
				</ComplexTextBox>
			</Box>
		);
	};

	const onAutomaticallyResolve = () => {
		setResolvedMissedParents(true);
		setStatus(TransferValidationStatus.Resolved);
	};

	return (
		<Stack key="stack-first-id">
			<HeadingMd text={createTransfer.reviewAndTransferStepTitle} />
			{environmentIsLoading ||
			isContentLoading ||
			isTargetContentLoading ? (
				<LoadingSkeleton amount={3} height={70} />
			) : (
				<>
					<Stack key="stack-second-id">
						<Stack
							bg="chakra-subtle-bg"
							w="xl"
							p={4}
							gap={1}
							key="stack-third-id"
						>
							<Box key="stack-box-first-id">
								<Text variant="small" fontWeight="semibold">
									{t(
										createTransfer.transferDetailsLabel.toUpperCase(),
									)}
								</Text>
							</Box>
							<Box key="stack-box-second-id">
								<Text fontWeight="semibold">
									{t(createTransfer.transferDetailsNameField)}
								</Text>
								<Text>{values.transferName}</Text>
							</Box>
							<Box key="stack-box-third-id">
								<Text fontWeight="semibold">
									{t(transferDetails.labelSourceEnv)}
								</Text>
								<Link href={`https://${source?.host}`}>
									{source?.name}
								</Link>
							</Box>
							<Box key="stack-box-fourth-id">
								<Text fontWeight="semibold">
									{t(transferDetails.labelTargetEnv)}
								</Text>
								<Link href={`https://${target?.host}`}>
									{target?.name}
								</Link>
							</Box>
						</Stack>

						<Stack
							bg="chakra-subtle-bg"
							w="xl"
							p={4}
							gap={3}
							key="stack-fourth-id"
						>
							<Box key="stack-first-box-first-id">
								<Text
									variant="small"
									fontWeight="semibold"
									pe={1}
								>
									{t(
										createTransfer.selectedItemsAndTransferOptions.toUpperCase(),
									)}
								</Text>
								{hasMissedParents && (
									<Alert
										//@ts-ignore
										status={status}
										mt="4"
										minH="14"
										display="flex"
										flexWrap="wrap"
									>
										<HStack>
											<Box key="alert-first-box-first-id">
												{resolvedMissedParents && (
													<Icon
														path={
															mdiInformationOutline
														}
														color="primary"
													/>
												)}
												{!resolvedMissedParents && (
													<AlertIcon />
												)}
											</Box>
											<Box key="alert-first-box-second-id">
												<AlertTitle></AlertTitle>
												<AlertDescription>
													{t(
														resolvedMissedParents
															? createTransfer.reviewAutomaticallyAdded
															: createTransfer.parentItemsNotExistAlert,
													)}
												</AlertDescription>
											</Box>

											<Spacer />
											<Box key="alert-second-box-first-id">
												{!resolvedMissedParents && (
													<Button
														size="sm"
														variant="outline"
														rightIcon={
															<Icon
																path={
																	mdiForward
																}
															/>
														}
														onClick={() =>
															onAutomaticallyResolve()
														}
													>
														{t(
															createTransfer.resolveAutomaticallyLabel,
														)}
													</Button>
												)}
											</Box>
										</HStack>
									</Alert>
								)}
							</Box>
							{hasMissedParents && resolvedMissedParents && (
								<Text variant="strong" fontSize="sm">
									{t(createTransfer.automaticallyAddedLabel)}
								</Text>
							)}
							{hasMissedParents &&
								resolvedMissedParents &&
								missedNodes.map((node) =>
									createNode(node, 'missed'),
								)}
							{hasMissedParents && resolvedMissedParents && (
								<Text variant="strong" fontSize="sm">
									{t(createTransfer.yourSelectionLabel)}
								</Text>
							)}
							{originalNodes &&
								originalNodes.map((node) =>
									createNode(node, 'original'),
								)}
						</Stack>
					</Stack>

					<Stack py={5} direction="row" key="stack-footer-id">
						<XMCSButton
							text={previous}
							onClick={() => prev(transferId)}
							size="md"
							variant="outline"
						/>
						<XMCSButton
							text={createTransfer.createTransferTitle}
							onClick={handleNext}
							size="md"
							isLoading={environmentIsLoading}
							isDisabled={
								hasMissedParents && !resolvedMissedParents
							}
						/>
					</Stack>
				</>
			)}
		</Stack>
	);
};

export default ReviewAndTransfer;
