import { useCreateProject } from '../../../../@xmcloud/hooks';
import { OLDReviewFinish } from './OLDReviewFinish';
import { useOLDGithubProjectCreation } from './useOLDGithubProjectCreation';

export const OLDReviewFinishGithub: React.FC = () => {
	const { values } = useCreateProject();
	const {
		isLoading,
		onGithubProjectCreation,
		shouldRetry,
		repositoryCreationStatus,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
	} = useOLDGithubProjectCreation(values);
	return (
		<OLDReviewFinish
			{...{
				isLoading,
				shouldRetry,
				handleStartDeployment: onGithubProjectCreation,
				repositoryCreationStatus,
				projectCreationStatus,
				environmentCreationStatus,
				deploymentCreationStatus,
			}}
		/>
	);
};
