import { Card, CardBody, Stack } from '@chakra-ui/react';
import { fifthStep } from '../../../../@xmcloud/core/messages/en';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../../@xmcloud/utils/helpers';
import { FormPlaceHolder, Text, XMCSButton } from '../../../shared-components';
import {
	ExtractGoTo,
	goToCreateProjectPaths,
	ProjectTypesEnum,
} from '../helpers';

const { edit } = fifthStep;

export const OLDReviewFinishCard: React.FC<{
	setActiveStep: (s: number) => void;
	items: { title: string; subTitle: string; titleValue?: string }[];
	stepNumber: number;
	headerText?: string;
	projectType: ProjectTypesEnum;
}> = ({
	setActiveStep,
	items = [],
	stepNumber = 1,
	headerText = '',
	projectType,
}) => {
	return (
		<Card variant="filled">
			<CardBody>
				<Stack direction="row" justifyContent="space-between">
					<Stack direction="column" gap="2" w="85% !important">
						{Boolean(headerText) && (
							<Text
								fontSize="lg"
								fontWeight="semibold"
								color="chakra-subtle-text"
								text={headerText}
							/>
						)}
						{items.map((i) => (
							<FormPlaceHolder
								{...{
									title: i.title,
									description: i.subTitle,
									titleValue: i?.titleValue || '',
									spacing: 0,
									pt: 0,
								}}
								key={i.title}
							/>
						))}
					</Stack>
					<XMCSButton
						size="sm"
						variant="ghost"
						colorScheme="primary"
						onClick={() => {
							setActiveStep(stepNumber);
							handlePush(
								goTo[
									goToCreateProjectPaths[
										projectType
									] as ExtractGoTo
								](stepNumber),
							);
						}}
						text={edit}
					/>
				</Stack>
			</CardBody>
		</Card>
	);
};
