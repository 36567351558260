import { SelectBranches } from '..';
import { useCreateProject } from '../../../../../@xmcloud/hooks';
import { useMemo, useRef } from 'react';
import { useGetGitHubPaginatedBranches } from '../../../../services/githubApi';
import { TRepository, TRepositoryBranch } from '../../helpers';

export const SelectGithubBranches: React.FC<{
	token: string;
	repositoryNamespace: TRepository;
	repositoryBranchNamespace: TRepositoryBranch;
}> = ({ token, repositoryNamespace, repositoryBranchNamespace }) => {
	const inputQuery = useRef('');
	const { values, isGithubProvider } = useCreateProject();
	const owner = values.connectionName;
	const repo = values[repositoryNamespace];

	const { data, isLoading, hasMore, fetchNextPage, isFetching } =
		useGetGitHubPaginatedBranches({
			token,
			owner,
			repo,
			onSuccess,
		});

	function onSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (query === '' || !hasMore || !isGithubProvider || !lastPage.length)
			return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPage();
		}
	}

	const branches = useMemo(() => {
		let branches =
			data?.map((r: any) => ({
				label: r.name,
				value: r.name,
			})) || [];
		branches.push({ label: 'none', value: '' });
		return branches;
	}, [data]);

	return (
		<SelectBranches
			{...{
				branches,
				isLoading,
				hasMore,
				fetchNextPage,
				isFetching,
				inputQuery,
				repositoryBranchNamespace,
			}}
		/>
	);
};
