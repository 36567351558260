import { Stack, Wrap } from '@chakra-ui/react';
import { useMemo } from 'react';
import {
	commonCw,
	fourthStep,
	thirdStep,
} from '../../../../@xmcloud/core/messages/en';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { TenantType } from '../../../../@xmcloud/types';
import { useGetEnvironmentsByQuery } from '../../../services/environments';
import {
	CheckboxFormControl,
	HeadingLg,
	InputFormControl,
	SelectFormControl,
} from '../../../shared-components';
import { DualCardItem, FooterBtnGroups } from '../components';
import {
	AVAILABLE_CM_ENVIRONMENT_NAME,
	CM_DEPLOY_ON_COMMIT,
	CM_ENVIRONMENT_ID,
	CM_ENVIRONMENT_NAME,
	CM_REPOSITORY_RELATIVE_PATH,
	DEPLOY_ON_COMMIT,
	EH_DEPLOY_ON_COMMIT,
	EH_ENVIRONMENT_NAME,
	EH_REPOSITORY_RELATIVE_PATH,
	ENVIRONMENT_NAME,
	ExtractGoTo,
	goToCreateProjectPaths,
	REPOSITORY_RELATIVE_PATH,
	TDeployOnCommit,
	TENANT_TYPE,
	TEnvironmentName,
	TRepositoryRelativePath,
} from '../helpers';

const { Production, NonProduction } = TenantType;

const { info1, info2, info3, prodEnv, autoDeploy } = thirdStep;
const { authEnv: authEnvtxt, envName: envNametxt, editHost } = commonCw;
const { fieldLabel1, desc1, availableEnvironments } = fourthStep;

const AuthoringEnvironment: React.FC = () => {
	const { values, next, prev, setFieldTouched, isDualJourney, isEhProject } =
		useCreateProject();

	function handleNext() {
		const envList: TEnvironmentName[] = isDualJourney
			? [CM_ENVIRONMENT_NAME, EH_ENVIRONMENT_NAME]
			: [ENVIRONMENT_NAME];

		const relativePathList: TRepositoryRelativePath[] = isDualJourney
			? [CM_REPOSITORY_RELATIVE_PATH, EH_REPOSITORY_RELATIVE_PATH]
			: [REPOSITORY_RELATIVE_PATH];

		for (const env of envList) {
			if (!values[env]) {
				setFieldTouched(env, true);
				return;
			}
		}
		for (const path of relativePathList) {
			if (!values[path]) {
				setFieldTouched(path, true);
				return;
			}
		}

		if (!values.cmEnvironmentId && isEhProject) {
			setFieldTouched(CM_ENVIRONMENT_ID, true);
			return;
		}
		next(goToCreateProjectPaths[values.journey] as ExtractGoTo);
	}

	function handlePrev() {
		prev(goToCreateProjectPaths[values.journey] as ExtractGoTo);
	}

	return (
		<Stack gap="7" pb="12">
			<HeadingLg text={isEhProject ? editHost : authEnvtxt} />
			{isDualJourney ? (
				<Wrap spacing="4">
					<DualCardItem {...{ colorScheme: 'purple' }}>
						<EnvironmentItem
							{...{
								environmentName: CM_ENVIRONMENT_NAME,
								repositoryRelativePath:
									CM_REPOSITORY_RELATIVE_PATH,
								deployOnCommit: CM_DEPLOY_ON_COMMIT,
							}}
						/>
					</DualCardItem>
					<DualCardItem {...{ colorScheme: 'teal' }}>
						<EnvironmentItem
							{...{
								environmentName: EH_ENVIRONMENT_NAME,
								repositoryRelativePath:
									EH_REPOSITORY_RELATIVE_PATH,
								isWebApp: true,
								deployOnCommit: EH_DEPLOY_ON_COMMIT,
							}}
						/>
					</DualCardItem>
				</Wrap>
			) : (
				<EnvironmentItem
					{...{
						environmentName: ENVIRONMENT_NAME,
						repositoryRelativePath: REPOSITORY_RELATIVE_PATH,
						deployOnCommit: DEPLOY_ON_COMMIT,
					}}
				/>
			)}
			<FooterBtnGroups
				{...{
					onNextClick: handleNext,
					onPreviousClick: handlePrev,
					isLoading: false,
					py: 0,
				}}
			/>
		</Stack>
	);
};

export default AuthoringEnvironment;

const EnvironmentItem = ({
	environmentName,
	repositoryRelativePath,
	isWebApp = false,
	deployOnCommit,
}: {
	environmentName: TEnvironmentName;
	repositoryRelativePath: TRepositoryRelativePath;
	isWebApp?: boolean;
	deployOnCommit: TDeployOnCommit;
}) => {
	const {
		values,
		errors,
		setValues,
		setFieldValue,
		handleChange,
		setFieldTouched,
		touched,
		isOwnCode,
		isEhProject,
	} = useCreateProject();

	const { data, fetchNextPage, hasNextPage, isFetching } =
		useGetEnvironmentsByQuery({
			_enabled: isEhProject,
		});
	const environmentsOptions = useMemo(() => {
		return (
			data?.map((e) => ({
				label: `${e.projectName} / ${e.name}`,
				value: e.id,
			})) || []
		);
	}, [data]);

	const showSLA = !isEhProject && !isWebApp;

	return (
		<Stack gap="7">
			<InputFormControl
				{...{
					isInvalid: Boolean(
						errors[environmentName] && touched[environmentName],
					),
					label: envNametxt,
					name: environmentName,
					value: values[environmentName],
					onChange: handleChange,
					error: errors[environmentName],
					maxW: 'sm',
					onFocus: () => setFieldTouched(environmentName, true),
					isRequired: true,
					textContent: info1,
				}}
			/>
			{isEhProject && (
				<SelectFormControl
					{...{
						isInvalid: Boolean(
							errors.cmEnvironmentId && touched.cmEnvironmentId,
						),
						isLoading: isFetching,
						options: environmentsOptions,
						onChange: (e: any) => {
							setValues((prev: FormikValues) => ({
								...prev,
								[CM_ENVIRONMENT_ID]: e.value,
								[AVAILABLE_CM_ENVIRONMENT_NAME]: e.label,
							}));
						},
						label: availableEnvironments,
						name: CM_ENVIRONMENT_ID,
						currentValue: values.cmEnvironmentId,
						error: errors.cmEnvironmentId,
						maxW: 'sm',
						onFocus: () => setFieldTouched(CM_ENVIRONMENT_ID, true),
						onMenuScrollToBottom: () => {
							hasNextPage && fetchNextPage();
						},
						isRequired: true,
						pt: 0,
					}}
				/>
			)}
			{isOwnCode && (
				<InputFormControl
					{...{
						isInvalid: Boolean(
							errors[repositoryRelativePath] &&
								touched[repositoryRelativePath],
						),
						label: fieldLabel1,
						name: repositoryRelativePath,
						value: values[repositoryRelativePath],
						pt: 0,
						maxW: 'sm',
						onChange: handleChange,
						error: errors[repositoryRelativePath],
						onFocus: () =>
							setFieldTouched(repositoryRelativePath, true),
						isRequired: true,
						textContent: desc1,
					}}
				/>
			)}
			<CheckboxFormControl
				{...{
					label: autoDeploy,
					name: deployOnCommit,
					isChecked: values[deployOnCommit],
					onChange: (e) =>
						setFieldValue(deployOnCommit, e.target.checked),
					textContent: info3,
					maxW: 'sm',
					pt: 1,
				}}
			/>
			{showSLA && (
				<CheckboxFormControl
					{...{
						label: prodEnv,
						name: TENANT_TYPE,
						isChecked: values.tenantType === Production,
						onChange: (e) =>
							setFieldValue(
								TENANT_TYPE,
								e.target.checked ? Production : NonProduction,
							),
						textContent: info2,
						maxW: 'sm',
					}}
				/>
			)}
		</Stack>
	);
};
