import { systemHealthStatus } from '../../../../@xmcloud/core/messages/en';
import { AlertStatus } from '../../../shared-components';
import {
	useComponentHealthy,
	useCreateProject,
} from '../../../../@xmcloud/hooks';
import { DualProjectName, SingleProjectName } from '../components';
import { Stack } from '@chakra-ui/react';

const { errMsg4, errMsg3 } = systemHealthStatus;

export const ProjectName: React.FC = () => {
	const { isDualJourney } = useCreateProject();
	const {
		isBlockersHealthy,
		isLoading: isComponentHealthLoading,
		getUnhealthyBlockerList,
	} = useComponentHealthy({});

	const isLoading = isComponentHealthLoading;
	const showErrorAlert = !isBlockersHealthy && !isComponentHealthLoading;
	const unhealthyBlockerList = showErrorAlert
		? getUnhealthyBlockerList()
		: [];
	const hasPluralErrors = unhealthyBlockerList.length > 1;

	return (
		<Stack gap="7">
			{isDualJourney ? (
				<DualProjectName
					{...{
						isContinueBtnDisabled: showErrorAlert,
						isContinueBtnLoading: isLoading,
					}}
				/>
			) : (
				<SingleProjectName
					{...{
						isContinueBtnDisabled: showErrorAlert,
						isContinueBtnLoading: isLoading,
					}}
				/>
			)}
			{showErrorAlert && (
				<AlertStatus
					description={hasPluralErrors ? errMsg3 : errMsg4}
					status="error"
					data-testid="bloker-error-alert"
					maxW="sm"
					variableTxt2="create a project"
					variableTxt={unhealthyBlockerList.join(', ')}
				/>
			)}
		</Stack>
	);
};

export default ProjectName;
