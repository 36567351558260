import { MemoizedMainTemplate } from './components';
import { useParams } from 'react-router-dom';
import { CwJourneyType, steps } from './helpers';
import { useRedirect404 } from './old-components/useRedirect404';

const { cm } = CwJourneyType;

export const CmMain: React.FC = () => {
	const { step: stepParam } = useParams<{ step: string }>();
	useRedirect404();

	return (
		<MemoizedMainTemplate
			{...{
				path: 'createCMProject',
				steps,
				stepParam: stepParam!,
				currentJourney: cm,
			}}
		/>
	);
};
