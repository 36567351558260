import { MemoizedMainTemplate } from './components';
import { useParams } from 'react-router-dom';
import { frontEndSteps, CwJourneyType } from './helpers';
import { useRedirect404 } from './old-components/useRedirect404';

const { eh } = CwJourneyType;

export const FrontEndMain: React.FC = () => {
	const { step: stepParam } = useParams<{ step: string }>();

	useRedirect404();

	return (
		<MemoizedMainTemplate
			{...{
				path: 'createFrontendProject',
				steps: frontEndSteps,
				stepParam: stepParam!,
				currentJourney: eh,
			}}
		/>
	);
};
