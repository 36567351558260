import { SelectBranches } from '..';
import { useCreateProject } from '../../../../../@xmcloud/hooks';
import { useMemo } from 'react';
import { ADO_BRANCH_PREFIX } from '../../../../../@xmcloud/utils/constants';
import { useGetADOBranches } from '../../../../services/adoApi';
import {
	TADOOrganization,
	TADOProjectName,
	TRepository,
	TRepositoryBranch,
} from '../../helpers';

export const SelectADOBranches: React.FC<{
	token: string;
	adoOrganizationNamespace: TADOOrganization;
	adoProjectNamespace: TADOProjectName;
	repositoryNamespace: TRepository;
	repositoryBranchNamespace: TRepositoryBranch;
}> = ({
	token,
	adoOrganizationNamespace,
	adoProjectNamespace,
	repositoryNamespace,
	repositoryBranchNamespace,
}) => {
	const { values } = useCreateProject();
	const repositoryValue = values[repositoryNamespace];
	const adoAdoOrganizationValue = values[adoOrganizationNamespace] || '';
	const adoProjectNamevalue = values[adoProjectNamespace] || '';
	const { data, isLoading } = useGetADOBranches(
		token,
		adoAdoOrganizationValue,
		adoProjectNamevalue,
		repositoryValue,
	);

	const branches = useMemo(() => {
		let branches =
			data?.data.value.map((r: any) => ({
				label: r.name.replace(ADO_BRANCH_PREFIX, ''),
				value: r.name.replace(ADO_BRANCH_PREFIX, ''),
			})) || [];
		branches.push({ label: 'none', value: '' });
		return branches;
	}, [data]);

	return (
		<SelectBranches
			{...{ branches, isLoading, repositoryBranchNamespace }}
		/>
	);
};
