import {
	ReactNode,
	createContext,
	useContext,
	useRef,
	useState,
	useCallback,
} from 'react';
import { editProjectValidationSchema } from '../../yup-validations';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { EnumInNumberSourceControl, ESourceControl } from '../../types';
import { NAME, REPOSITORY } from '../../../app/pages/create-project/helpers';
import { difference, handlePush } from '../../utils/helpers';
import { EditProjectFormikValues } from '../../../app/models/projectModel';
import {
	useGetValidateProjectName,
	useLinkRepositoryMutation,
	useUnlinkRepositoryMutation,
	useUpdateProjectMutation,
} from '../../../app/services/projects';
import { useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { t } from '@transifex/native';
import { editProject, projects } from '../../core/messages/en';
import { useRepositoryHookSubscriptionMutation } from '../../../app/services/repositories';
import { goTo } from '../../core/routes/paths';
import { useUpdateEnvironmentMutation } from '../../../app/services/environments';
import { sleep } from '../../utils/promiseUtils';
import config from '../../../app/config/config';
import { useQueryClient } from 'react-query';
import { IGetEnvironmentResponse } from '../../../app/models/environmentModel';
import { useGetGitubRepository } from '../../../app/services/githubApi';
import { HttpStatusCodes } from '../../../app/apiUtils/resources';
import { useGetADORepository } from '../../../app/services/adoApi';
import { useComponentHealthy, useValidateConnection } from '..';

const { projectDetailsEdit, error1 } = projects;
const { error4, error5 } = editProject;

const { environments: environmentsPath } = goTo;

export type FormikValues = {
	name: string;
	sourceControlIntegrationId: string;
	account: string;
	provider: EnumInNumberSourceControl | 0;
	providerName: ESourceControl | '';
	repository: string;
	adoOrganization: string;
	adoProjectName: string;
	repositoryId: string | number;
	environments: Partial<IGetEnvironmentResponse>[];
	hasAccount: boolean;
};
type TPrev = (a: FormikValues) => FormikValues;

export type TEditProjectValues = {
	editName: boolean;
	editProvider: boolean;
	editAccount: boolean;
	editRepository: boolean;
	editOrganization: boolean;
	editProject: boolean;
};

type EditProjectContextType = {
	errors: FormikErrors<FormikValues>;
	values: FormikValues;
	handleChange: any;
	formikHandleSubmit: any;
	setFieldError: (field: string, message: string) => void;
	setFieldValue: (field: string, value: any) => void;
	setFieldTouched: any;
	touched: FormikTouched<FormikValues>;
	setValues:
		| ((
				values: React.SetStateAction<FormikValues>,
				shouldValidate?: boolean | undefined,
		  ) => Promise<void> | Promise<FormikErrors<FormikValues>>)
		| ((prev: TPrev) => void);
	setTouched: any;
	setErrors: (errors: FormikErrors<FormikValues>) => void;
	prevValues: FormikValues;
	setPrevValues: React.Dispatch<React.SetStateAction<FormikValues>>;
	editValues: TEditProjectValues;
	setEditValues: React.Dispatch<React.SetStateAction<TEditProjectValues>>;
	isSubmittingLoading: boolean;
	resetForm: any;
	accessToken: string;
	integrationIdValidationMsg: string;
	isIntegrationValidationFetching: boolean;
	isValidIntegration: boolean;
	isComponentHealthLoading: boolean;
	isAdoProvider: boolean;
	isGithubProvider: boolean;
	isADOHealthy: boolean;
	isGithubHealthy: boolean;
	isCurrentProviderHealthy: boolean;
	isComponentHealthError: boolean;
};

export const EditProjectContext = createContext<EditProjectContextType>(
	{} as EditProjectContextType,
);

const { ADO, GitHub } = EnumInNumberSourceControl;
const { NOT_FOUND } = HttpStatusCodes;

const initialValues: FormikValues = {
	name: '',
	sourceControlIntegrationId: '',
	account: '',
	provider: 0,
	providerName: '',
	adoOrganization: '',
	adoProjectName: '',
	repository: '',
	repositoryId: '',
	environments: [],
	hasAccount: false,
};

const initialEditValues = {
	editName: false,
	editProvider: false,
	editAccount: false,
	editRepository: false,
	editOrganization: false,
	editProject: false,
};

const EditProjectProvider = ({ children }: { children: ReactNode }) => {
	const { projectId } = useParams<{ projectId: string }>();
	const [prevValues, setPrevValues] = useState(initialValues);
	const [editValues, setEditValues] =
		useState<TEditProjectValues>(initialEditValues);
	const [hasSubmitted, setSubmitted] = useState(false);

	const toast = useToast();
	const queryClient = useQueryClient();

	const isADOProvider = useRef(false);

	const { mutate: updateProject, isLoading: isUpdateProjectLoading } =
		useUpdateProjectMutation({
			id: projectId!,
		});

	const { mutate: updateEnvironment, isLoading: isUpdateEnvironmentLoading } =
		useUpdateEnvironmentMutation({ id: '' });

	const {
		mutate: repoHookSubscription,
		isLoading: isLoadingHookSubscription,
	} = useRepositoryHookSubscriptionMutation();

	const { mutate: unlinkRepository, isLoading: isUnlinkRepositoryLoading } =
		useUnlinkRepositoryMutation();

	const { mutate: linkRepository, isLoading: isLinkRepositoryLoading } =
		useLinkRepositoryMutation({ projectId: projectId! });

	const onSubmitSuccess = useCallback(() => {
		toast({
			status: 'success',
			description: t(projectDetailsEdit),
		});
		handlePush(environmentsPath(projectId!));
		setSubmitted(false);
	}, [projectId, toast]);

	const onUpdateProject = useCallback(
		(updatedValues: EditProjectFormikValues, onSucces?: () => void) => {
			updateProject(updatedValues, {
				onSuccess: () => {
					onSucces ? onSucces() : onSubmitSuccess();
				},
				onError: (e) => {
					onError(e);
				},
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateProject, onSubmitSuccess, prevValues],
	);

	const onUpdateEnvironment = useCallback(
		(values: FormikValues) => {
			const { environments } = values;
			let promises = [];

			for (const env of environments) {
				const { id, repositoryBranch, deployOnCommit } = env;
				promises.push(
					updateEnvironment({
						environmentId: id,
						repositoryBranch: repositoryBranch || '',
						deployOnCommit: !!deployOnCommit,
					}),
				);
			}

			Promise.all(promises)
				.then(async () => {
					await sleep(3500);
					const { project, get_projects_list } = config.projects;

					queryClient.invalidateQueries(get_projects_list.queryKey);
					queryClient.invalidateQueries(project.queryKey(projectId!));

					onSubmitSuccess();
				})
				.catch(() => {
					toast({
						status: 'error',
						description: t(error1),
						duration: null,
						isClosable: true,
					});
					handlePush(environmentsPath(projectId!));
					setSubmitted(false);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateEnvironment, onSubmitSuccess, prevValues],
	);

	const onUpdateRepository = useCallback(
		(values: FormikValues) => {
			const { repository, repositoryId, sourceControlIntegrationId } =
				values;
			unlinkRepository(projectId!, {
				onSuccess: () => {
					const linkPayload = {
						repository,
						repositoryId: `${repositoryId}`,
						integrationId: sourceControlIntegrationId,
					};
					linkRepository(linkPayload, {
						onSuccess: () => {
							//update the environment
							onUpdateEnvironment(values);
						},
						onError: (e) => {
							onError(e);
						},
					});
				},
				onError: (e) => {
					onError(e);
				},
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			linkRepository,
			onUpdateEnvironment,
			projectId,
			toast,
			unlinkRepository,
			prevValues,
		],
	);

	const validationSchema = editProjectValidationSchema({
		isADOProvider: isADOProvider.current,
	});

	const {
		errors,
		values,
		touched,
		setValues,
		resetForm,
		setErrors,
		setTouched,
		handleChange,
		setFieldError,
		setFieldValue,
		setFieldTouched,
		handleSubmit: formikHandleSubmit,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		validateOnBlur: false,
		onSubmit: async (values: FormikValues) => {
			const { name, repository, sourceControlIntegrationId, hasAccount } =
				values;
			const namePayload = { name };
			if (prevValues.name !== name) {
				const { data } = await validateProjectName();
				const isValid = data?.data?.isValid;
				const message = data?.data?.message;

				if (!isValid) {
					setFieldError(NAME, message);
					return;
				}
			}
			setSubmitted(true);

			const diffValues = difference(values, prevValues);

			const isNameChanged = diffValues?.name;
			const isRepoChanged =
				diffValues?.repository || diffValues?.repositoryId;

			if (values.provider === GitHub && !hasAccount) {
				// update GitHub project if there is no account
				onUpdateProject(diffValues);
				return;
			}

			if (values.provider === ADO && !hasAccount) {
				// update ado project if there is no account
				const subscriptionPayload = {
					repositoryName: repository,
					integrationId: sourceControlIntegrationId,
				};
				repoHookSubscription(subscriptionPayload, {
					onSuccess: () => {
						// update the project
						onUpdateProject(diffValues);
					},
					onError: (e) => {
						onError(e);
					},
				});
				return;
			}

			const isGitHubProvider = values.provider === GitHub;
			const refetchCurrentProviderRepository = isGitHubProvider
				? refetchGithubRepository
				: refetchADORepository;

			const onRepositoryAvailability = () => {
				refetchCurrentProviderRepository()
					.then((data) => {
						const { isError } = data;
						if (isError) {
							onGetRepositoryError(data.error);
							return;
						}
						onUpdateRepository(values);
					})
					.catch((e) => {
						onGetRepositoryError(e);
					});
			};

			if (!isNameChanged && isRepoChanged) {
				//update repository if name is not changed
				onRepositoryAvailability();
			} else {
				const onProjectUpdateSuccess = () => {
					//update repository
					onRepositoryAvailability();
				};
				//update project
				onUpdateProject(
					namePayload,
					!!isRepoChanged ? onProjectUpdateSuccess : undefined,
				);
			}
		},
	});

	const {
		token: accessToken,
		isIntegrationValidationFetching,
		integrationIdValidationMsg,
		isValidIntegration,
	} = useValidateConnection({
		sourceControlIntegrationId: values.sourceControlIntegrationId,
	});

	const {
		isLoading: isGitHubRepositoryLoading,
		refetch: refetchGithubRepository,
	} = useGetGitubRepository({
		token: accessToken,
		owner: values.account,
		repo: values.repository,
		_enabled: false,
	});

	const {
		isFetching: isFetchingADORepository,
		refetch: refetchADORepository,
	} = useGetADORepository({
		token: accessToken,
		organization: values.adoOrganization,
		project: values.adoProjectName,
		repo: values.repository.split('/')[2],
		_enabled: false,
	});

	function onError(e: any) {
		const message = e?.response?.data?.detail || e?.message;
		toast({
			status: 'error',
			description: t(message),
			duration: null,
			isClosable: true,
		});
		setValues({
			...prevValues,
		});
		setSubmitted(false);
	}

	function onGetRepositoryError(e: any) {
		const isGitHubProvider = values.provider === GitHub;
		const isADOProvider = values.provider === ADO;

		if (isGitHubProvider) {
			const { queryKey } =
				config.sourceControl.get_app_installation_github_repositories;
			queryClient.invalidateQueries(
				queryKey(values.sourceControlIntegrationId),
			);
		}

		if (isADOProvider) {
			const { queryKey } = config.sourceControl.get_ado_repositories;
			queryClient.invalidateQueries(
				queryKey(values.adoOrganization, values.adoProjectName),
			);
		}
		const isNotFound = e.response?.status === NOT_FOUND;
		const err = {
			message: isNotFound
				? error4(`Repository (${values.repository})`)
				: error5,
		};
		onError(err);
		setTimeout(() => {
			setFieldTouched(REPOSITORY, true);
			if (isNotFound) {
				setFieldError(
					REPOSITORY,
					error4(`Repository (${values.repository})`),
				);
			} else {
				setFieldError(REPOSITORY, error5);
			}
		}, 100);
	}

	const { refetch: validateProjectName, isLoading: validationIsLoading } =
		useGetValidateProjectName(values.name || '');

	isADOProvider.current = values.provider === ADO;

	const {
		isLoading: isComponentHealthLoading,
		isAdoProvider,
		isGithubProvider,
		isADOHealthy,
		isGithubHealthy,
		isCurrentProviderHealthy,
		isError: isComponentHealthError,
	} = useComponentHealthy({ provider: values.provider });

	const isSubmittingLoading =
		isUpdateProjectLoading ||
		validationIsLoading ||
		isLoadingHookSubscription ||
		isUnlinkRepositoryLoading ||
		isLinkRepositoryLoading ||
		isUpdateEnvironmentLoading ||
		isGitHubRepositoryLoading ||
		isFetchingADORepository ||
		hasSubmitted;

	return (
		<EditProjectContext.Provider
			value={{
				touched,
				errors,
				values,
				prevValues,
				editValues,
				isSubmittingLoading,
				accessToken,
				integrationIdValidationMsg,
				isIntegrationValidationFetching,
				isValidIntegration,
				isComponentHealthLoading,
				isCurrentProviderHealthy,
				isAdoProvider,
				isGithubProvider,
				isADOHealthy,
				isGithubHealthy,
				isComponentHealthError,
				handleChange,
				formikHandleSubmit,
				setFieldError,
				setFieldValue,
				setFieldTouched,
				setValues,
				setTouched,
				setErrors,
				resetForm,
				setPrevValues,
				setEditValues,
			}}
		>
			{children}
		</EditProjectContext.Provider>
	);
};

function useEditProject(): EditProjectContextType {
	const context = useContext(EditProjectContext);

	if (context === undefined) {
		throw new Error(
			'useEditProject must be used within a EditProjectProvider',
		);
	}

	return context;
}

export { useEditProject, EditProjectProvider };
