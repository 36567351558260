import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import {
	defaultErrorHandler,
	useAuthMutation,
	useAuthQuery,
} from '../apiUtils/queryHelper';
import config from '../config/config';
import {
	HostingProvider,
	ICreateHostingProjectPayload,
	IGetHostingInstallation,
	IGetHostingProject,
	IValidateInstallationResponse,
} from '../models/hostingModel';
import { isAxiosError } from '../../@xmcloud/utils/errorUtils';
import { HttpStatusCodes } from '../apiUtils/resources';
import { useToast } from '@chakra-ui/react';

export const useHostingInstallMutation = () => {
	const queryClient = useQueryClient();
	return useAuthMutation(
		(axiosInstance) =>
			({
				code,
				provider,
				state,
			}: {
				code: string;
				provider: HostingProvider;
				state: string | null;
			}) => {
				return axiosInstance?.post<
					{
						code: string;
						provider: HostingProvider;
						state: string | null;
					},
					AxiosResponse<void>
				>(config.hosting.install.url, {
					code,
					provider,
					state,
				});
			},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(
					config.hosting.get_installations_list.queryKey,
				);
			},
		},
		config.hosting.install.scope,
	);
};

export const useGetHostingInstallationsList = ({
	enabled,
}: {
	enabled?: boolean;
}) => {
	return useAuthQuery(
		config.hosting.get_installations_list.queryKey,
		(axiosInstance) => {
			return axiosInstance?.get<IGetHostingInstallation[]>(
				config.hosting.get_installations_list.url,
			);
		},
		{ enabled, refetchOnWindowFocus: true },
		config.hosting.get_installations_list.scope,
	);
};

export const useCreateHostingProjectMutation = ({
	environmentId,
}: {
	environmentId: string;
}) => {
	const queryClient = useQueryClient();
	return useAuthMutation(
		(axiosInstance) => (projectData: ICreateHostingProjectPayload) => {
			return axiosInstance?.post(
				config.hosting.create_project.url,
				projectData,
			);
		},
		{
			onSuccess: () => {
				// TODO: invalidate sites
				//queryClient.invalidateQueries('projects');
				queryClient.invalidateQueries(
					config.hosting.get_projects_list.queryKey(environmentId),
				);
			},
		},
		config.projects.project.scope,
	);
};

export const useGetHostingProjectsList = ({
	environmentId,
	enabled,
	onError,
	refetchInterval = 0,
	refetchOnWindowFocus = false,
	onSuccess,
}: {
	environmentId?: string;
	enabled?: boolean;
	onError?: ((err: unknown) => void) | undefined;
	onSuccess?: () => void;
	refetchInterval?: number;
	refetchOnWindowFocus?: boolean;
}) => {
	return useAuthQuery(
		config.hosting.get_projects_list.queryKey(environmentId),
		(axiosInstance) => {
			return axiosInstance?.get<IGetHostingProject[]>(
				config.hosting.get_projects_list.url(environmentId),
			);
		},
		{
			enabled,
			onError(err) {
				onError && onError(err);
			},
			onSuccess() {
				onSuccess && onSuccess();
			},
			refetchInterval,
			refetchOnWindowFocus,
		},
		config.hosting.get_installations_list.scope,
	);
};

export const useGetStateForInstallation = ({
	enabled,
}: {
	enabled?: boolean;
}) => {
	return useAuthQuery(
		[],
		(axiosInstance) => {
			return axiosInstance?.get<string>(config.hosting.get_state.url);
		},
		{ enabled },
		config.hosting.get_state.scope,
	);
};

export const useValidateHostingInstallation = ({
	integrationId,
	enabled,
}: {
	integrationId?: string;
	enabled?: boolean;
}) => {
	return useAuthQuery(
		[],
		(axiosInstance) => {
			return axiosInstance?.get<IValidateInstallationResponse>(
				config.hosting.validate_installation.url(integrationId),
			);
		},
		{ enabled },
		config.hosting.validate_installation.scope,
	);
};

export const useDeleteHostingProjectMutation = ({
	environmentId,
}: {
	environmentId: string;
}) => {
	const queryClient = useQueryClient();
	return useAuthMutation(
		(axiosInstance) =>
			({
				projectId,
				deleteFromProvider,
			}: {
				projectId: string;
				deleteFromProvider: boolean;
			}) => {
				return axiosInstance?.delete(
					config.hosting.delete_project.url(
						projectId,
						deleteFromProvider,
					),
				);
			},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(
					config.hosting.get_projects_list.queryKey(environmentId),
				);
			},
		},
		config.hosting.delete_project.scope,
	);
};

export const useDeleteHostingConnectionMutation = () => {
	const queryClient = useQueryClient();
	const toast = useToast();
	const { scope } = config.hosting.delete_connection;
	return useAuthMutation(
		(axiosInstance) =>
			({ id }: { id: string }) => {
				return axiosInstance?.delete(
					config.hosting.delete_connection.url(id),
				);
			},
		{
			skipDefaultErrorHandler: true,
			onSuccess: () => {
				queryClient.invalidateQueries(
					config.hosting.get_installations_list.queryKey,
				);
			},
			onError: (err) => {
				if (
					isAxiosError(err) &&
					err.response &&
					err.response.data &&
					err.response.status === HttpStatusCodes.BAD_REQUEST
				) {
					toast({
						status: 'error',
						description: err.response.data as string,
					});
					return;
				} else {
					defaultErrorHandler(err, scope, toast);
				}
			},
		},
		config.hosting.delete_connection.scope,
	);
};
