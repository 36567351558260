import { Link as SDSLink, LinkProps } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

type Props = LinkProps & {
	text: string | null;
	to: string;
};

export const Link: React.FC<Props> = ({ text, to, ...rest }) => (
	<SDSLink as={ReactRouterLink} to={to} {...rest} boxShadow="none !important">
		{text}
	</SDSLink>
);
