import { useParams } from 'react-router-dom';
import { CwJourneyType, dualSteps } from './helpers';
import { MemoizedMainTemplate } from './components';
import { useRedirect404 } from './old-components/useRedirect404';

const { dual } = CwJourneyType;

export const DualMain: React.FC = () => {
	const { step: stepParam } = useParams<{ step: string }>();

	useRedirect404();

	return (
		<MemoizedMainTemplate
			{...{
				path: 'createDualProject',
				steps: dualSteps,
				stepParam: stepParam!,
				currentJourney: dual,
			}}
		/>
	);
};
