import { Stack } from '@chakra-ui/react';
import {
	firstStep,
	projects,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import { HeadingLg, InputFormControl } from '../../../shared-components';
import {
	useCreateProject,
	useExperimentalFeatures,
} from '../../../../@xmcloud/hooks';
import { useGetValidateProjectName } from '../../../services/projects';
import {
	CM_PROJECT_NAME,
	EH_PROJECT_NAME,
	ExtractGoTo,
	goToCreateProjectPaths,
} from '../helpers';
import { DualCardItem, FooterBtnGroups } from '.';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import { handlePush } from '../../../../@xmcloud/utils/helpers';

const { title2: title2txt } = firstStep;
const { nameTitle, requiredName } = projects;
const { choosePath } = goTo;
const { errMsg2 } = systemHealthStatus;

export const DualProjectName: React.FC<{
	isContinueBtnDisabled: boolean;
	isContinueBtnLoading: boolean;
}> = ({ isContinueBtnDisabled, isContinueBtnLoading }) => {
	const { values, errors, next, setFieldError, setFieldTouched } =
		useCreateProject();

	const {
		refetch: refetchCmProjectNameValidation,
		isFetching: isCmProjectNameLoading,
	} = useGetValidateProjectName(values.cmProjectname);
	const {
		refetch: refetchEhProjectNameValidation,
		isFetching: isEhProjectNameLoading,
	} = useGetValidateProjectName(values.ehProjectname);

	async function handleNext() {
		if (
			!values.cmProjectname ||
			!values.ehProjectname ||
			errors.cmProjectname ||
			errors.ehProjectname
		) {
			setFieldTouched(CM_PROJECT_NAME, true);
			setFieldTouched(EH_PROJECT_NAME, true);
			!values.cmProjectname &&
				setFieldError(CM_PROJECT_NAME, requiredName);
			!values.ehProjectname &&
				setFieldError(EH_PROJECT_NAME, requiredName);
			return;
		}

		const { data: cmData } = await refetchCmProjectNameValidation();
		const isCmProjectNameValid = cmData?.data.isValid;
		const cmMessage = cmData?.data.message as string;

		if (!isCmProjectNameValid) {
			setFieldError(CM_PROJECT_NAME, cmMessage);
			return;
		}

		const { data: ehData } = await refetchEhProjectNameValidation();
		const isEhProjectNameValid = ehData?.data.isValid;
		const ehMessage = ehData?.data.message as string;

		if (!isEhProjectNameValid) {
			setFieldError(EH_PROJECT_NAME, ehMessage);
			return;
		}
		next(goToCreateProjectPaths[values.journey] as ExtractGoTo);
	}

	function handlePrev() {
		handlePush(choosePath);
	}
	const { createEhAndCmProjectExperimentalFeature } =
		useExperimentalFeatures();

	const showPreviousBtn = createEhAndCmProjectExperimentalFeature;

	const isLoading =
		isCmProjectNameLoading ||
		isEhProjectNameLoading ||
		isContinueBtnLoading;

	return (
		<Stack gap="7">
			<HeadingLg text={title2txt} />
			<Stack direction="row" flexWrap="wrap" gap="4">
				<CardItem
					{...{
						colorScheme: 'purple',
						name: CM_PROJECT_NAME,
					}}
				/>
				<CardItem
					{...{
						colorScheme: 'teal',
						name: EH_PROJECT_NAME,
					}}
				/>
			</Stack>
			<FooterBtnGroups
				{...{
					onNextClick: handleNext,
					onPreviousClick: handlePrev,
					isLoading,
					isDisabled: isContinueBtnDisabled,
					tooltiptxt: isLoading ? '' : errMsg2,
					py: 0,
					showPreviousBtn,
				}}
			/>
		</Stack>
	);
};

const CardItem: React.FC<{
	colorScheme: 'teal' | 'purple';
	name: 'cmProjectname' | 'ehProjectname';
}> = ({ colorScheme, name }) => {
	const { values, errors, handleChange, setFieldTouched, touched } =
		useCreateProject();
	return (
		<DualCardItem {...{ colorScheme }}>
			<InputFormControl
				{...{
					isInvalid: Boolean(errors[name] && touched[name]),
					label: nameTitle,
					name,
					value: values[name],
					onChange: handleChange,
					error: errors[name],
					onFocus: () => setFieldTouched(name, true),
					maxW: 'sm',
					isRequired: true,
				}}
			/>
		</DualCardItem>
	);
};
