import { FC, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import {
	app,
	connections,
	systemHealthStatus,
} from '../../../../@xmcloud/core/messages/en';
import {
	useComponentHealthy,
	useProjectDetails,
	useValidateConnection,
} from '../../../../@xmcloud/hooks/';
import { getTabIndex } from '../../../../@xmcloud/utils/helpers';
import { IGetEnvironmentResponse } from '../../../models/environmentModel';
import { useDeploymentCreateAndDeploy } from '../../../services/deploymentCreateDeploy';
import { useGetEnvironment } from '../../../services/environments';
import {
	HeaderStack,
	XMCSBreadCrumb,
	XMCSMenuButton,
	XMCSTabs,
} from '../../../shared-components';
import { environmentItems } from '../../../shared-components/breadcrumb/items';
import {
	commonEnvTabItems,
	EHEnvironementTabItems,
	environementTabItems,
} from '../../../shared-components/tab/items';
import { ProjectTypesEnum } from '../../create-project/helpers';
import DeleteEnvironment from '../components/DeleteEnvironment';
import EnvironmentMenuItems from '../components/EnvironmentMenuItems';
import LinkEnvToOtherBranch from '../components/LinkEnvToOtherBranch';
import EditEnvironment from './EditEnvironment';

const { errorMsg5 } = systemHealthStatus;
const { noSourceControl: noSourceControltxt } = connections;

const { CM, EH } = ProjectTypesEnum;

export const EnvironmentPage: FC = () => {
	const { environmentId, projectId } = useParams<{
		environmentId: string;
		projectId: string;
	}>();
	const { pathname } = useLocation();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [linkToAnotherBranchModal, setLinkToAnotherBranchModal] =
		useState(false);
	const [enableIntegrationValidation, setEnableIntegrationValidation] =
		useState(false);

	const { data: environmentData, isLoading: isEnvironmentLoading } =
		useGetEnvironment({
			id: environmentId!,
		});
	const environmentName = environmentData?.data.name || '';
	const environment = environmentData?.data as IGetEnvironmentResponse;
	const { projectName, project, projectType } = useProjectDetails(projectId!);
	const projectData = project?.data;

	const tabIndex = getTabIndex(pathname, projectType);
	const [deploymentCreateAndDeploy, isDeploymentLoading] =
		useDeploymentCreateAndDeploy();
	const sourceControlIntegrationId =
		projectData?.sourceControlIntegrationId || '';

	const {
		isValidIntegration,
		integrationIdValidationMsg,
		provider,
		isLoading: isValidationIntegrationLoading,
		isNotValidIntegration,
	} = useValidateConnection({
		sourceControlIntegrationId,
		enabled: !!sourceControlIntegrationId && enableIntegrationValidation,
	});
	const {
		isGithubProvider,
		isAdoProvider,
		isCurrentProviderHealthy,
		isLoading: isHealthStatusLoading,
	} = useComponentHealthy({ provider });

	const tabItemsMap = new Map([
		[CM, commonEnvTabItems],
		[EH, EHEnvironementTabItems],
	]);

	const tabItems = tabItemsMap.get(projectType) ?? environementTabItems;

	const isLoading =
		isDeploymentLoading ||
		isEnvironmentLoading ||
		isValidationIntegrationLoading ||
		isHealthStatusLoading;

	const isProviderHealthy = isCurrentProviderHealthy && isValidIntegration;
	const integrationErrorMessage = isLoading
		? ''
		: !sourceControlIntegrationId
			? noSourceControltxt
			: integrationIdValidationMsg || errorMsg5;

	return (
		<>
			<XMCSBreadCrumb items={environmentItems(projectName, projectId!)} />
			<HeaderStack title={environmentName} mb={2}>
				<XMCSMenuButton
					text={app.options}
					{...{
						'data-testid': 'menu-button',
						isLoading,
						onClick: () => setEnableIntegrationValidation(true),
					}}
				>
					<EnvironmentMenuItems
						{...{
							setOpenDeleteModal,
							setOpenEditModal,
							environmentId: environmentId!,
							projectId: projectId!,
							projectType,
							deploymentCreateAndDeploy,
							host: environment?.host,
							setLinkToAnotherBranchModal,
							environment,
							isProviderHealthy,
							isAdoProvider,
							isGithubProvider,
							integrationErrorMessage,
							enableIntegrationValidation,
							isNotValidIntegration,
						}}
					/>
				</XMCSMenuButton>
			</HeaderStack>
			<XMCSTabs
				tabIndex={tabIndex}
				tabItems={tabItems}
				state={{ projectName, environmentName }}
				args={[projectId!, environmentId!]}
			/>
			{openEditModal && Boolean(environment) && (
				<EditEnvironment
					{...{
						openModal: openEditModal,
						setOpenModal: setOpenEditModal,
						environment,
					}}
				/>
			)}
			{openDeleteModal && Boolean(environment) && (
				<DeleteEnvironment
					{...{
						openModal: openDeleteModal,
						setOpenModal: setOpenDeleteModal,
						environmentDetails: environment,
					}}
				/>
			)}

			{linkToAnotherBranchModal && (
				<LinkEnvToOtherBranch
					openModal={linkToAnotherBranchModal}
					setOpenModal={setLinkToAnotherBranchModal}
					environmentId={environmentId}
					project={projectData}
					repositoryBranch={environment?.repositoryBranch}
				/>
			)}
			<Outlet />
		</>
	);
};
