import { secondStep } from '../../../../@xmcloud/core/messages/en';
import { useCreateProject } from '../../../../@xmcloud/hooks';
import { SelectFormControl } from '../../../shared-components';
import { SelectADOBranches, SelectGithubBranches } from '.';
import {
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	REPOSITORY,
	REPOSITORY_ID,
	REPO_BRANCH,
	TADOOrganization,
	TADOProjectName,
	TOptions,
	TRepository,
	TRepositoryBranch,
	TRepositoryId,
} from '../helpers';
import { ScaleFade, Stack } from '@chakra-ui/react';
import { FormikValues } from '../../../../@xmcloud/hooks/context/CreateProjectContext';
import { useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

const { chooseRepository } = secondStep;

export const SelectRepositories: React.FC<{
	isLoading: boolean;
	repositoryOptions: TOptions[] | any;
	accessToken: string;
	adoOrganizationNamespace?: TADOOrganization;
	adoProjectNamespace?: TADOProjectName;
	isFetching?: boolean;
	fetchNextPage?: any;
	hasNextPage?: boolean;
	inputQuery?: React.MutableRefObject<string>;
	repositoryNamespace?: TRepository;
	repositoryIdNamespace?: TRepositoryId;
	repositoryBranchNamespace?: TRepositoryBranch;
}> = ({
	isLoading,
	repositoryOptions,
	accessToken,
	adoOrganizationNamespace = ADO_ORGANIZATION,
	adoProjectNamespace = ADO_PROJECT_NAME,
	isFetching,
	fetchNextPage,
	hasNextPage,
	inputQuery,
	repositoryNamespace = REPOSITORY,
	repositoryIdNamespace = REPOSITORY_ID,
	repositoryBranchNamespace = REPO_BRANCH,
}) => {
	const {
		setValues,
		setFieldTouched,
		values,
		errors,
		touched,
		isADOProvider,
		isGithubProvider,
	} = useCreateProject();

	const showSelectBranches =
		Boolean(accessToken) && Boolean(values[repositoryNamespace]);

	const handleFetchNextPage = useCallback(() => {
		if (hasNextPage && isGithubProvider && !isFetching) {
			fetchNextPage();
		}
	}, [fetchNextPage, hasNextPage, isFetching, isGithubProvider]);

	const onInputChange = useCallback(
		(e: any) => {
			if (inputQuery) {
				inputQuery.current = e;
			}

			if (
				e === '' ||
				!hasNextPage ||
				!isGithubProvider ||
				!repositoryOptions?.length ||
				isFetching
			)
				return;

			const hasRepo = repositoryOptions.some((r: any) =>
				r.label.includes(e),
			);

			if (!hasRepo) {
				handleFetchNextPage();
			}
		},
		[
			handleFetchNextPage,
			hasNextPage,
			inputQuery,
			isFetching,
			isGithubProvider,
			repositoryOptions,
		],
	);

	const debouncedChangeHandler = useMemo(
		() => debounce(onInputChange, 300),
		[onInputChange],
	);

	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		};
	}, [debouncedChangeHandler]);

	return (
		<Stack gap="7">
			<SelectFormControl
				{...{
					error: errors[repositoryNamespace],
					isInvalid: Boolean(
						errors[repositoryNamespace] &&
							touched[repositoryNamespace],
					),
					isLoading: isLoading || isFetching,
					options: repositoryOptions,
					onChange: (e: any) => {
						setValues((prev: FormikValues) => ({
							...prev,
							[repositoryNamespace]: e.label || '',
							[repositoryIdNamespace]: e.id || '',
							[repositoryBranchNamespace]: '',
						}));
					},
					label: chooseRepository,
					name: repositoryNamespace,
					currentValue: values[repositoryNamespace],
					maxW: 'sm',
					onFocus: () => setFieldTouched(repositoryNamespace, true),
					onInputChange: debouncedChangeHandler,
					onMenuScrollToBottom: () => {
						handleFetchNextPage();
					},
					isRequired: true,
					pt: 0,
				}}
			/>
			{showSelectBranches && (
				<ScaleFade initialScale={0.9} in={showSelectBranches}>
					{isGithubProvider && (
						<SelectGithubBranches
							{...{
								token: accessToken,
								repositoryNamespace,
								repositoryBranchNamespace,
							}}
						/>
					)}
					{isADOProvider && (
						<SelectADOBranches
							{...{
								token: accessToken,
								adoOrganizationNamespace,
								adoProjectNamespace,
								repositoryNamespace,
								repositoryBranchNamespace,
							}}
						/>
					)}
				</ScaleFade>
			)}
		</Stack>
	);
};
