import { FC, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { t } from '@transifex/native';
import {
	allDeployments,
	app,
	deployments as enDeployments,
} from '../../../../@xmcloud/core/messages/en';
import { handlePush, handleTo } from '../../../../@xmcloud/utils/helpers';
import { getReadableDate } from '../../../../@xmcloud/utils/dateUtils';
import {
	IconText,
	Link,
	LoadingSkeleton,
	RenderPlaceholder,
	RowActions,
	Table,
	TableRef,
} from '../../../shared-components';
import { goTo } from '../../../../@xmcloud/core/routes/paths';
import {
	DeployProcessStatus,
	IGetDeploymentResponse,
} from '../../../models/deploymentModel';
import CancelDeployment from '../../project/deployment-logs/CancelDeployment';
import DeployBuildOtherEnv from '../../project/components/DeployBuildOtherEnv';
import { statusIconsTextList } from '../../project/columns/deployments';
import { useGetAllDeployments } from '../../../services/deployments';
import { StatusHeader } from '../../../shared-components/popover/DeploymentsStatusHeader';
import {
	ColumnDef,
	PaginationState,
	createColumnHelper,
} from '@tanstack/react-table';
import config from '../../../config/config';
import { useQueryClient } from 'react-query';
import { useAuthenticatedAxios } from '../../../apiUtils/AxiosProvider';
import { DeploymentFilter } from '../../../../@xmcloud/types';

const {
	project,
	projectId,
	environment,
	environmentId,
	date,
	progress,
	queued,
	complete,
	failed,
	skipped,
} = allDeployments;

const headers = [
	'name',
	'Project ID',
	'Envrionment',
	'Environment ID',
	'Date',
	'Status',
	'',
];

interface Props {
	filter: DeploymentFilter;
	tableRef: TableRef;
}

const DeploymentsTable: FC<Props> = ({ filter: queryFilter, tableRef }) => {
	const [showCancelDeploymentModal, setshowCancelDeploymentModal] =
		useState(false);
	const [detail, setDetail] = useState({
		deploymentId: '',
		environmentId: '',
		projectId: '',
	});
	const [deployToOtherEnvModal, setDeployToOtherEnvModal] = useState(false);
	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize],
	);

	const queryClient = useQueryClient();
	const axiosContext = useAuthenticatedAxios();

	const { data, isLoading, isFetching, isPreviousData } =
		useGetAllDeployments(pageIndex + 1, pageSize, queryFilter);

	const deployments = (data?.data.data as IGetDeploymentResponse[]) || [];

	const totalCount = data?.data?.totalCount || 0;

	const columns: ColumnDef<IGetDeploymentResponse, any>[] =
		useDeploymentColumns(
			setDetail,
			setshowCancelDeploymentModal,
			setDeployToOtherEnvModal,
		);

	useEffect(() => {
		const hasMore = totalCount > pageIndex * pageSize;
		const { url, queryKey } = config.deployments.allDeployments_v3;

		if (!isPreviousData && hasMore) {
			queryClient.prefetchQuery({
				queryKey: queryKey(pageIndex + 1, queryFilter),
				queryFn: () =>
					axiosContext.get(url(pageIndex + 1, pageSize, queryFilter)),
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPreviousData, pageIndex]);

	if (isLoading) return <LoadingSkeleton amount={pageSize + 1} mt={12} />;
	if (deployments.length === 0)
		return <RenderPlaceholder item={app.deployments.toLocaleLowerCase()} />;

	return (
		<>
			<Table
				{...{
					columns,
					pageCount: Math.ceil(totalCount / pageSize),
					pagination,
					tableData: deployments,
					dataTestId: 'paginated-deployments-table',
					initialSort: [],
					isFetching,
					manualPagination: true,
					onPaginationChange: setPagination,
					ref: tableRef,
					headers,
				}}
			/>
			{showCancelDeploymentModal && (
				<CancelDeployment
					environmentId={detail.environmentId}
					deploymentId={detail.deploymentId}
					openModal={showCancelDeploymentModal}
					setOpenModal={setshowCancelDeploymentModal}
				/>
			)}
			{deployToOtherEnvModal && (
				<DeployBuildOtherEnv
					openModal={deployToOtherEnvModal}
					setOpenModal={setDeployToOtherEnvModal}
					environmentId={detail.environmentId}
					deploymentId={detail.deploymentId}
					projectId={detail.projectId}
				/>
			)}
		</>
	);
};

export default DeploymentsTable;

const columnHelper = createColumnHelper<IGetDeploymentResponse>();

const useDeploymentColumns = (
	setDetail: (a: any) => void,
	setshowCancelDeploymentModal: (a: boolean) => void,
	setDeployToOtherEnvModal: (a: boolean) => void,
) => {
	return useMemo(
		() => [
			columnHelper.accessor('projectName', {
				header: () => t(project),
				cell: ({
					row: {
						original: { projectName, projectId, environmentId },
					},
				}) => (
					<Link
						text={projectName || ''}
						to={handleTo(
							goTo.deployments(projectId, environmentId),
						)}
					/>
				),
				enableSorting: false,
			}),
			columnHelper.accessor('projectId', {
				header: () => t(projectId),
				cell: (info) => info.getValue(),
				enableSorting: false,
			}),
			columnHelper.accessor('environmentName', {
				header: () => t(environment),
				cell: (info) => (
					<Text
						whiteSpace={['nowrap', 'wrap']}
						maxW={['50%', '11vw']}
						isTruncated
					>
						{info.getValue()}
					</Text>
				),
				enableSorting: false,
			}),
			columnHelper.accessor('environmentId', {
				header: () => t(environmentId),
				cell: (info) => info.getValue(),
				enableSorting: false,
			}),
			columnHelper.accessor('createdAt', {
				header: () => t(date),
				cell: (info) => getReadableDate(info.getValue()),
				enableSorting: false,
			}),
			columnHelper.accessor('calculatedStatus', {
				header: () => <StatusHeader allStatus={statusDescriptions} />,
				cell: (info) => (
					<CurrentIconText calculatedStatus={info.getValue()} />
				),
				enableSorting: false,
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({
					row: {
						original: {
							calculatedStatus,
							projectId,
							environmentId,
							id,
						},
					},
				}) =>
					calculatedStatus === DeployProcessStatus.Skipped ? (
						<Box h="10" />
					) : (
						<RowActions ariaLabel="variables-table-action-menu">
							<MenuList>
								<MenuItem
									onClick={() => {
										handlePush(
											goTo.deploymentLogs(
												projectId,
												environmentId,
												id,
											),
										);
									}}
								>
									{t(enDeployments.viewLogs)}
								</MenuItem>
								{Boolean(
									calculatedStatus ===
										DeployProcessStatus.InProgress ||
										calculatedStatus ===
											DeployProcessStatus.NotStarted,
								) && (
									<MenuItem
										onClick={() => {
											setDetail({
												environmentId,
												deploymentId: id,
												projectId,
											});
											setshowCancelDeploymentModal(true);
										}}
									>
										{t(enDeployments.cancel)}
									</MenuItem>
								)}
								{calculatedStatus ===
									DeployProcessStatus.Complete && (
									<MenuItem
										onClick={() => {
											setDetail({
												environmentId,
												deploymentId: id,
												projectId,
											});
											setDeployToOtherEnvModal(true);
										}}
									>
										{t(enDeployments.deployBuildArtifact)}
									</MenuItem>
								)}
							</MenuList>
						</RowActions>
					),
				maxSize: 0,
			}),
		],
		[setDeployToOtherEnvModal, setDetail, setshowCancelDeploymentModal],
	);
};

export const statusDescriptions = [
	{ s: 1, desc: progress },
	{ s: 2, desc: complete },
	{ s: 0, desc: queued },
	{ s: 3, desc: failed },
	{ s: 4, desc: skipped },
];

export const CurrentIconText = ({
	calculatedStatus,
	textColor,
	xsText,
	lineHeight,
}: {
	calculatedStatus: number;
	textColor?: string;
	xsText?: string;
	lineHeight?: number;
}) => {
	const status = statusIconsTextList[calculatedStatus];
	return (
		<IconText
			{...{
				text: status.text,
				...(status?.isIndeterminate && {
					isIndeterminate: true,
				}),
				...(status?.IconName && {
					IconName: status.IconName,
				}),
				...(status?.iconColor && {
					iconColor: status.iconColor,
				}),
				...(textColor && { textColor }),
				...(xsText && { xsText }),
				...(lineHeight && { lineHeight }),
				noOfLines: 3,
			}}
		/>
	);
};
