import { Stack } from '@chakra-ui/react';
import { useCreateProject } from '../../../../../@xmcloud/hooks';
import {
	REPO_BRANCH,
	REPOSITORY,
	REPOSITORY_ID,
	TOptions,
	TRepository,
	TRepositoryBranch,
	TRepositoryId,
} from '../../helpers';
import { RepositoryNameInputField, SelectRepositories } from '..';
import { useMemo, useRef } from 'react';
import { useGetPaginatedGithubRepositories } from '../../../../services/githubApi';

export const GithubRepository: React.FC<{
	isSourceControlIntegrationLoading: boolean;
	repositoryNamespace: TRepository;
	repositoryIdNamespace?: TRepositoryId;
	repositoryBranchNamespace?: TRepositoryBranch;
	accessToken: string;
}> = ({
	isSourceControlIntegrationLoading,
	repositoryNamespace = REPOSITORY,
	repositoryIdNamespace = REPOSITORY_ID,
	repositoryBranchNamespace = REPO_BRANCH,
	accessToken,
}) => {
	const { values, isOwnCode, isGithubProvider } = useCreateProject();
	const inputQuery = useRef('');

	const {
		data,
		isLoading: isLoadingGithubRepositories,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useGetPaginatedGithubRepositories(
		accessToken,
		values.sourceControlIntegrationId,
		!!accessToken,
		onSuccess,
	);

	const repositoryOptions: TOptions = useMemo(
		() =>
			data?.map((r: any) => ({
				label: r.name,
				value: r.name,
				id: r.id,
			})) || [],
		[data],
	);

	function onSuccess(lastPage: any) {
		const query = inputQuery.current;

		if (
			query === '' ||
			!hasNextPage ||
			!isGithubProvider ||
			!lastPage.length
		)
			return;

		const hasRepo = lastPage.some((r: any) => r.name.includes(query));

		if (!hasRepo) {
			fetchNextPage();
		}
	}

	const isLoading =
		isSourceControlIntegrationLoading || isLoadingGithubRepositories;

	return (
		<Stack>
			{isOwnCode ? (
				<SelectRepositories
					{...{
						repositoryOptions,
						isLoading,
						accessToken,
						isFetching,
						fetchNextPage,
						hasNextPage,
						onSuccess,
						inputQuery,
						repositoryNamespace,
						repositoryIdNamespace,
						repositoryBranchNamespace,
					}}
				/>
			) : (
				<RepositoryNameInputField {...{ repositoryNamespace }} />
			)}
		</Stack>
	);
};
