import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useRef,
} from 'react';
import { createProjectValidationSchema } from '../../yup-validations';
import { FormikErrors, FormikState, FormikTouched, useFormik } from 'formik';
import { handlePush } from '../../utils/helpers';
import {
	ProjectName,
	SetupRepository,
	AuthoringEnvironment,
	ReviewFinishMain,
	SourceControls,
} from '../../../app/pages/create-project/steps';
import { goTo as route } from '../../core/routes/paths';
import {
	EAccountType,
	ESourceControl,
	SourceControlType,
	TenantType,
} from '../../types';
import {
	ExtractGoTo,
	ProjectTypesEnum,
	TCwJourney,
	CwJourneyType,
} from '../../../app/pages/create-project/helpers';
import { useFeature } from '../../../app/feature-flag/features';
import { useSteps } from '@chakra-ui/react';
import { OLDProjectName } from '../../../app/pages/create-project/old-components/OLDProjectName';
import { OLDSetupRepository } from '../../../app/pages/create-project/old-components/OLDSetupRepository';
import { OLDAuthoringEnvironment } from '../../../app/pages/create-project/old-components/OLDAuthoringEnvironment';
import { OLDReviewFinishMain } from '../../../app/pages/create-project/old-components/OLDReviewFinishMain';
import { useExperimentalFeatures } from '../useExperimentalFeatures';

export type FormikValues = {
	name: string;
	sourceControlIntegrationId: string;
	provider: SourceControlType;
	repository: string;
	environmentName: string;
	deployOnCommit: boolean;
	tenantType: TenantType;
	linkEnvironmentToDefaultBranch: boolean;
	connectionName: string;
	repositoryBranch: string;
	accountType: EAccountType | null;
	adoOrganization: string;
	adoProjectName: string;
	repositoryId: string;
	projectType: ProjectTypesEnum;
	repositoryRelativePath: string;
	cmEnvironmentId: string;
	isUseOwnCode: boolean;
	journey: TCwJourney;
	cmProjectname: string;
	ehProjectname: string;
	isMonoRepo: boolean;
	cmRepository: string;
	ehRepository: string;
	cmRepositoryId: string;
	ehRepositoryId: string;
	cmRepositoryBranch: string;
	ehRepositoryBranch: string;
	cmAdoProjectName: string;
	ehAdoProjectName: string;
	cmAdoOrganization: string;
	ehAdoOrganization: string;
	cmEnvironmentName: string;
	ehEnvironmentName: string;
	cmRepositoryRelativePath: string;
	ehRepositoryRelativePath: string;
	cmDeployOnCommit: boolean;
	ehDeployOnCommit: boolean;
	availableCmEnvironmentName: string;
};

type TPrev = (a: FormikValues) => FormikValues;

type CreateProjectContextType = {
	prev: (p: ExtractGoTo) => void;
	next: (p: ExtractGoTo) => void;
	errors: FormikErrors<FormikValues>;
	values: FormikValues;
	handleChange: any;
	formikHandleSubmit: any;
	step: ReactNode;
	isLastStep: boolean;
	setFieldError: (field: string, message: string) => void;
	setFieldValue: (field: string, value: any) => void;
	setFieldTouched: any;
	touched: FormikTouched<FormikValues>;
	resetForm: (s?: Partial<FormikState<FormikValues>> | undefined) => void;
	setValues:
		| ((
				values: React.SetStateAction<FormikValues>,
				shouldValidate?: boolean | undefined,
		  ) => Promise<void> | Promise<FormikErrors<FormikValues>>)
		| ((prev: TPrev) => void);
	setTouched: any;
	setErrors: (errors: FormikErrors<FormikValues>) => void;
	activeStep: number;
	setActiveStep: any;
	isDualJourney: boolean;
	isADOProvider: boolean;
	isMonoRepo: boolean;
	isOwnCode: boolean;
	isGithubProvider: boolean;
	isEhProject: boolean;
};

export const CreateProjectContext = createContext<CreateProjectContextType>(
	{} as CreateProjectContextType,
);

const { ADO, GitHub } = ESourceControl;
const { EH, COMBINED } = ProjectTypesEnum;
const { cm: cmJourney, dual } = CwJourneyType;

const initialValues: FormikValues = {
	name: '',
	sourceControlIntegrationId: '',
	provider: '' as SourceControlType,
	repository: '',
	environmentName: '',
	deployOnCommit: false,
	tenantType: 0,
	linkEnvironmentToDefaultBranch: true,
	connectionName: '',
	repositoryBranch: '',
	accountType: null,
	adoOrganization: '',
	adoProjectName: '',
	repositoryId: '',
	projectType: COMBINED,
	repositoryRelativePath: './src',
	cmEnvironmentId: '',
	isUseOwnCode: false,
	journey: cmJourney,
	cmProjectname: '',
	ehProjectname: '',
	isMonoRepo: true,
	cmRepository: '',
	ehRepository: '',
	cmRepositoryId: '',
	ehRepositoryId: '',
	cmRepositoryBranch: '',
	ehRepositoryBranch: '',
	cmAdoProjectName: '',
	ehAdoProjectName: '',
	cmAdoOrganization: '',
	ehAdoOrganization: '',
	cmEnvironmentName: '',
	ehEnvironmentName: '',
	cmRepositoryRelativePath: './src',
	ehRepositoryRelativePath: './src',
	cmDeployOnCommit: false,
	ehDeployOnCommit: false,
	availableCmEnvironmentName: '',
};

const newSteps = [
	<></>,
	<ProjectName />,
	<SourceControls />,
	<SetupRepository />,
	<AuthoringEnvironment />,
	<ReviewFinishMain />,
];
const oldSteps = [
	<></>,
	<OLDProjectName />,
	<SourceControls />,
	<OLDSetupRepository />,
	<OLDAuthoringEnvironment />,
	<OLDReviewFinishMain />,
];

const CreateProjectProvider = ({ children }: { children: ReactNode }) => {
	const isADOProvider = useRef(false);
	const isEhProject = useRef(false);
	const isDualJourney = useRef(false);
	const isMonoRepo = useRef(false);
	const isOwnCode = useRef(false);

	const { createEhAndCmProjectExperimentalFeature } =
		useExperimentalFeatures();
	const createProjectV2 =
		useFeature('CreateProjectV2') &&
		createEhAndCmProjectExperimentalFeature;

	const validationSchema = createProjectValidationSchema({
		isADOProvider: isADOProvider.current,
		isEHProject: isEhProject.current,
		isDualJourney: isDualJourney.current,
		isMonoRepo: isMonoRepo.current,
		isOwnCode: isOwnCode.current,
	});

	const {
		errors,
		values,
		handleChange,
		setFieldError,
		setFieldValue,
		setFieldTouched,
		setValues,
		touched,
		handleSubmit: formikHandleSubmit,
		resetForm,
		setTouched,
		setErrors,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		validateOnBlur: false,
		onSubmit: (values: FormikValues) => {},
	});

	isEhProject.current = values.journey === EH;
	isADOProvider.current = values.provider === ADO;
	isDualJourney.current = values.journey === dual;
	isMonoRepo.current = values.isMonoRepo;
	isOwnCode.current = values.isUseOwnCode;

	const steps = createProjectV2 ? newSteps : oldSteps;

	const { activeStep, setActiveStep } = useSteps({
		index: 1,
		count: steps.length,
	});

	const isLastStep = activeStep >= steps.length - 1;
	const isFirstStep = activeStep <= 1;

	const next = useCallback(
		(goToPath: ExtractGoTo) => {
			setActiveStep((prev: number) => {
				if (prev >= steps.length - 1) return prev;
				return prev + 1;
			});
			if (isLastStep) return;
			const isFn = typeof route[`${goToPath}`] === 'function';
			if (isFn) {
				handlePush(route[`${goToPath}`](activeStep + 1));
			}
		},
		[activeStep, isLastStep, setActiveStep, steps.length],
	);

	const prev = useCallback(
		(goToPath: ExtractGoTo) => {
			setActiveStep((prev: number) => {
				if (prev <= 1) return prev;
				return prev - 1;
			});
			if (isFirstStep) return;
			const isFn = typeof route[`${goToPath}`] === 'function';
			if (isFn) {
				handlePush(route[`${goToPath}`](activeStep - 1));
			}
		},
		[activeStep, isFirstStep, setActiveStep],
	);

	return (
		<CreateProjectContext.Provider
			value={{
				prev,
				next,
				errors,
				values,
				handleChange,
				formikHandleSubmit,
				step: steps[activeStep],
				isLastStep,
				setFieldError,
				setFieldValue,
				setFieldTouched,
				touched,
				resetForm,
				setValues,
				setTouched,
				setErrors,
				activeStep,
				setActiveStep,
				isDualJourney: isDualJourney.current,
				isADOProvider: isADOProvider.current,
				isGithubProvider: values.provider === GitHub,
				isMonoRepo: isMonoRepo.current,
				isOwnCode: isOwnCode.current,
				isEhProject: isEhProject.current,
			}}
		>
			{children}
		</CreateProjectContext.Provider>
	);
};

function useCreateProject(): CreateProjectContextType {
	const context = useContext(CreateProjectContext);

	if (context === undefined) {
		throw new Error(
			'useCreateProject must be used within a CreateProjectProvider',
		);
	}

	return context;
}

export { useCreateProject, CreateProjectProvider };
