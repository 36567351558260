import { useAuthQuery } from '../apiUtils/queryHelper';
import config from '../config/config';
import { ITransferConfiguration } from '../models/transferConfiguration';

/**
 * Get transfer details
 * @param param0 transfer id
 * @returns ITransferConfiguration
 */
export const useGetTransferConfiguration = ({ id }: { id: string }) => {
	const { scope, url, queryKey } = config.transfers.transferConfiguration;
	return useAuthQuery(
		queryKey(id),
		(axiosInstance) => {
			return axiosInstance?.get<ITransferConfiguration>(url(id));
		},
		{ keepPreviousData: true, staleTime: 5000 },
		scope,
	);
};
