import * as yup from 'yup';
import { createTransfer } from '../core/messages/en';

const {
	requiredTransferName,
	requiredSourceEnvironment,
	requiredDestinationEnvironment,
	transferNameMaxCharacters,
} = createTransfer;
export const createTransferValidationSchema = () =>
	yup.object({
		transferName: yup
			.string()
			.required(requiredTransferName)
			.max(100, transferNameMaxCharacters),
		sourceEnvironmentId: yup.string().required(requiredSourceEnvironment),
		targetEnvironmentId: yup
			.string()
			.required(requiredDestinationEnvironment),
	});
