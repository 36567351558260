import { useCreateProject } from '../../../../@xmcloud/hooks';
import { IRepositoryTemplateDetails } from '../../../models/repositoriesModel';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import { useGetRepositoryTemplates } from '../../../services/repositories';
import {
	ReviewFinishADO,
	ReviewFinishDualADO,
	ReviewFinishDualGithub,
	ReviewFinishGithub,
} from '../components';

const reviewFinishLookup = {
	github: (props: any) => <ReviewFinishGithub {...props} />,
	dualGithub: (props: any) => <ReviewFinishDualGithub {...props} />,
	ado: (props: any) => <ReviewFinishADO {...props} />,
	dualADO: (props: any) => <ReviewFinishDualADO {...props} />,
	noSourceControl: () => <></>,
};
const getKey = (
	isGithubProvider: boolean,
	isADOProvider: boolean,
	isDualJourney: boolean,
) =>
	isGithubProvider && isDualJourney
		? 'dualGithub'
		: isADOProvider && isDualJourney
			? 'dualADO'
			: isGithubProvider
				? 'github'
				: isADOProvider
					? 'ado'
					: 'noSourceControl';

const ReviewFinishMain: React.FC = () => {
	const { isADOProvider, isGithubProvider, values, isDualJourney } =
		useCreateProject();
	const currentKey = getKey(isGithubProvider, isADOProvider, isDualJourney);

	const {
		data: repositoryTemplatesData,
		isLoading: isLoadingRepositoryTemplates,
	} = useGetRepositoryTemplates(values.provider);

	const repositoryTemplates =
		repositoryTemplatesData?.data || ([] as IRepositoryTemplateDetails[]);
	const foundationTemplate = repositoryTemplates[0];

	const { data: accessTokenData, isLoading: isAccessTokenLoading } =
		useGetSourceControlAccessToken(values.sourceControlIntegrationId);
	const token = accessTokenData?.data.accessToken || '';

	const isLoading = isLoadingRepositoryTemplates || isAccessTokenLoading;

	const ReviewFinishComponent = reviewFinishLookup[currentKey];
	return (
		<ReviewFinishComponent
			{...{ token, XMCloudTemplate: foundationTemplate, isLoading }}
		/>
	);
};

export default ReviewFinishMain;
