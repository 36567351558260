import { ApmRoutes } from '@elastic/apm-rum-react';
import { Navigate, Route } from 'react-router-dom';
import {
	ORGANIZATION,
	fetchLastSuccessfulLoginOrganizationId,
} from '../../../app/auth/Auth0Storage';
import {
	connectionsPaths,
	createProjectPaths,
	createTransferPaths,
	credentialsPaths,
	environmentPath,
	mainPaths,
	projectPaths,
	transferDetailsPaths,
} from './paths';
import {
	AccessDeniedPage,
	AdoCallback,
	CMInstanceLogs,
	CMInstancePage,
	ChoosePath,
	CombinedFoundationMain,
	ConnectionsPage,
	ContentTransferPage,
	CreateTransferMain,
	CredentialsPage,
	DeploymentLogs,
	DeploymentsPage,
	DeveloperSettings,
	DocumentationPage,
	EnvironmentDeployments,
	EnvironmentDetails,
	EnvironmentPage,
	Error404Page,
	FrontEndMain,
	GithubCallback,
	HostingIntegrations,
	MainWizardCreateProject,
	NavigateTo,
	ProjectDetailsPage,
	ProjectEnvironments,
	ProjectPage,
	ProjectsPage,
	RedirectToNewUI,
	SettingsPage,
	SitesPage,
	SourceControlIntegrations,
	CmMain,
	StatusPage,
	TransferDetails,
	TransferDetailsPage,
	TransferStatus,
	VariablesPage,
	VercelInstallPage,
	WizardCreateTransfer,
	EditProjectMain,
	DualMain,
	OrganizationCredentials,
	EnvironmentCredentials,
} from '../../../app/pages';
import { ProtectedSitesRoute } from './protected-routes';

const {
	home,
	settings,
	systemStatus,
	projects,
	project,
	environment,
	deployment,
	deployments,
	credentials,
	documentation,
	connections,
	createProject,
	accessDenied,
	vercelInstall,
	gitHubCallback,
	adoCallback,
	oldEnvUrls,
	oldDepUrls,
	oldProjectUrls,
	oldCmLogUrl,
	oldProEnvUrls,
	oldEnvDepUrl,
	noMatch,
	contenttransfer,
	transferDetails,
	createTransfer,
} = mainPaths;
const { environments, projectDetails, editProject } = projectPaths;
const { sites, details, cmLogTypes, cmLogs, variables, devSettings } =
	environmentPath;
const { choosePath, combinedSteps, ehSteps, cmSteps, dualSteps } =
	createProjectPaths;
const { organization, environment: crEnvironment } = credentialsPaths;
const { sourcecontrol, hosting } = connectionsPaths;
const { status: transferStatusPath, details: transferDetailsPath } =
	transferDetailsPaths;

const { createTransferSteps, transferAgain } = createTransferPaths;

export const MainRouter: React.FC = () => {
	const orgId = fetchLastSuccessfulLoginOrganizationId();

	return (
		<ApmRoutes>
			<Route
				path={home}
				element={
					<Navigate to={`${projects}?${ORGANIZATION}=${orgId}`} />
				}
			/>
			<Route path={projects} element={<ProjectsPage />} />
			<Route path={project} element={<ProjectPage />}>
				<Route path={environments} element={<ProjectEnvironments />} />
				<Route path={projectDetails} element={<ProjectDetailsPage />} />
				<Route path={editProject} element={<EditProjectMain />} />
			</Route>
			<Route path={environment} element={<EnvironmentPage />}>
				<Route
					path={environmentPath.deployments}
					element={<EnvironmentDeployments />}
				/>
				<Route
					path={sites}
					element={
						<ProtectedSitesRoute>
							<SitesPage />
						</ProtectedSitesRoute>
					}
				/>
				<Route path={details} element={<EnvironmentDetails />} />
				<Route path={cmLogTypes} element={<CMInstancePage />} />
				<Route path={cmLogs} element={<CMInstanceLogs />} />
				<Route path={variables} element={<VariablesPage />} />
				<Route path={devSettings} element={<DeveloperSettings />} />
			</Route>
			<Route path={deployment} element={<DeploymentLogs />} />
			<Route path={createProject} element={<MainWizardCreateProject />}>
				<Route path={choosePath} element={<ChoosePath />} />
				<Route
					path={combinedSteps}
					element={<CombinedFoundationMain />}
				/>
				<Route path={dualSteps} element={<DualMain />} />
				<Route path={ehSteps} element={<FrontEndMain />} />
				<Route path={cmSteps} element={<CmMain />} />
			</Route>
			<Route path={deployments} element={<DeploymentsPage />} />
			<Route path={credentials} element={<CredentialsPage />}>
				<Route
					path={organization}
					element={<OrganizationCredentials />}
				/>
				<Route
					path={crEnvironment}
					element={<EnvironmentCredentials />}
				/>
			</Route>
			<Route path={connections} element={<ConnectionsPage />}>
				<Route
					path={sourcecontrol}
					element={<SourceControlIntegrations />}
				/>
				<Route path={hosting} element={<HostingIntegrations />} />
			</Route>
			<Route path={documentation} element={<DocumentationPage />} />
			<Route path={systemStatus} element={<StatusPage />} />
			<Route path={settings} element={<SettingsPage />} />
			<Route path={contenttransfer} element={<ContentTransferPage />} />
			<Route path={transferDetails} element={<TransferDetailsPage />}>
				<Route path={transferStatusPath} element={<TransferStatus />} />
				<Route
					path={transferDetailsPath}
					element={<TransferDetails />}
				/>
			</Route>
			<Route path={createTransfer} element={<WizardCreateTransfer />}>
				<Route
					path={createTransferSteps}
					element={<CreateTransferMain />}
				/>
				<Route path={transferAgain} element={<CreateTransferMain />} />
			</Route>
			<Route path={gitHubCallback} element={<GithubCallback />} />
			<Route path={adoCallback} element={<AdoCallback />} />
			<Route path={vercelInstall} element={<VercelInstallPage />} />
			<Route path={oldEnvUrls} element={<RedirectToNewUI />} />
			<Route path={oldDepUrls} element={<RedirectToNewUI />} />
			<Route path={oldProjectUrls} element={<NavigateTo />} />
			<Route path={oldCmLogUrl} element={<NavigateTo />} />
			<Route path={oldProEnvUrls} element={<NavigateTo />} />
			<Route path={oldEnvDepUrl} element={<NavigateTo />} />
			<Route path={accessDenied} element={<AccessDeniedPage />} />
			<Route path={noMatch} element={<Error404Page />} />
			<Route path="*" element={<Error404Page />} />
		</ApmRoutes>
	);
};
