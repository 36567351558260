import { FC } from 'react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Textarea,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { FormPlaceHolder } from '..';
import { Microcopy } from '../../pages/create-project/components';

interface Props {
	error?: string | undefined;
	isInvalid?: boolean;
	isRequired?: boolean;
	isTextArea?: boolean;
	label: string;
	name: string;
	value?: string;
	onChange?: (e: any) => void;
	dataTestId?: string;
	onFocus?: () => void;
	isDisabled?: boolean;
	showAsPlaceHolder?: boolean;
	pt?: number;
	maxW?: string;
	isEditable?: boolean;
	onEditClick?: () => void;
	textContent?: string;
}

export const InputFormControl: FC<Props> = ({
	error,
	isInvalid,
	isRequired = false,
	isTextArea = false,
	label,
	name,
	value = '',
	onChange,
	dataTestId = `input-element-${name}`,
	onFocus,
	isDisabled,
	isEditable = false,
	onEditClick,
	showAsPlaceHolder = false,
	pt = 0,
	maxW = 'md',
	textContent,
}) => {
	if (showAsPlaceHolder)
		return (
			<FormPlaceHolder
				{...{
					title: label,
					description: value || '',
					isEditable,
					onEditClick,
				}}
			/>
		);
	return (
		<FormControl {...{ isInvalid, isRequired, pt, maxW }}>
			<FormLabel>
				<T _str={label} />
			</FormLabel>

			{isTextArea ? (
				<Textarea {...{ name, value, onChange, onFocus, isDisabled }} />
			) : (
				<Input
					{...{ name, value, onChange, onFocus, isDisabled }}
					data-testid={dataTestId}
				/>
			)}
			<FormErrorMessage>{error}</FormErrorMessage>
			{!!textContent && (
				<Microcopy {...{ text: textContent, maxW, pt: 2 }} />
			)}
		</FormControl>
	);
};
