import { FC, useEffect, useState } from 'react';
import { AlertIcon, Button, Link, Stack } from '@chakra-ui/react';
import { Icon } from '../../../shared-components';
import config from '../../../config/config';
import { deploymentLogs } from '../../../../@xmcloud/core/messages/en';
import { t } from '@transifex/native';
import { retry } from '../../../../@xmcloud/utils/promiseUtils';
import { Spinner } from '@chakra-ui/react';
import { useProjectDetails } from '../../../../@xmcloud/hooks';
import { mdiArrowRight } from '@mdi/js';
import { ProjectTypesEnum } from '../../create-project/helpers';
interface Props {
	host: string;
	projectId: string;
}
const TRY_COUNT = 300;
const INTERVAL_IN_MS = 15000;

const { EH } = ProjectTypesEnum;

const DnsResolution: FC<Props> = ({ host, projectId }) => {
	const [isProgress, setIsProgress] = useState(true);
	const { projectType } = useProjectDetails(projectId);
	const dashboardUrl = config.externalLinks.xm_cloud_dashboard(host);

	const isEHProject = EH === projectType;

	useEffect(() => {
		if (isEHProject) return;
		const controller = new AbortController();
		const signal = controller.signal;
		const url = new URL(dashboardUrl);
		const hostname = url.origin;
		const subscription = retry(
			() => fetch(hostname, { mode: 'no-cors', signal }),
			TRY_COUNT,
			INTERVAL_IN_MS,
		);

		subscription
			.then(() => {
				setIsProgress(false);
			})
			.catch(() => {
				setIsProgress(true);
			});
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [host, isEHProject]);

	return isEHProject ? null : isProgress ? (
		<Stack direction="row" my={1}>
			<AlertIcon as={Spinner} boxSize="5" color="primary" />
			<Link isExternal href={dashboardUrl} boxShadow="none !important">
				{t(deploymentLogs.dnsInprogress)}
			</Link>
		</Stack>
	) : (
		<Button
			size="sm"
			variant="outline"
			onClick={() => window.open(dashboardUrl)}
			rightIcon={<Icon path={mdiArrowRight} />}
		>
			{deploymentLogs.xmcloud}
		</Button>
	);
};

export default DnsResolution;
